import React from "react";
import { NavLink,Outlet } from "react-router-dom";


function Settings() {
  
  return (
    <>
    <div className="sub-nav">
        <div className="sub-nav-container">
            <NavLink to="/dashboard/settings/categories" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Categories</NavLink>
            <NavLink to="/dashboard/settings/syllabus" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Syllabus &amp; Subjects</NavLink>
            <NavLink to="/dashboard/settings/cities" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Cities</NavLink>
            <NavLink to="/dashboard/settings/profession" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Profession</NavLink>
            <NavLink to="/dashboard/settings/source" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Source</NavLink>
        </div>
    </div>
    <Outlet/>
    </>
  );
}
export default Settings;
