import React, { useState,useEffect } from 'react'
import { approveEnquiry, assignDemo as createDemo, excludeTutors, getApprovedEnquery, getMappingEnquery, getPendingEnquiry,  terminateEnquiry as closeEnquiry, updateEnquery } from '../../components/services';
import PendingItem from './components/PendingItem';
import MappingList from './components/MappingList';
import AssignDemoModal from './components/AssignDemoModal';
import ExcludeTutorModal from './components/ExcludeTutorModal';
import { DataGrid,GridActionsCellItem } from '@mui/x-data-grid';
import StarFilled from '@mui/icons-material/Star';
import Star from '@mui/icons-material/StarBorder';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../config/constants';
import { authAxios } from '../../helpers/axios';
import { useSelector } from 'react-redux';
import EnquiryInfoPopup from './components/EnquiryInfoPopup'
import InfoIcon from '../../assets/images/info.png'
import AddEnquiryModal from './components/AddEnquiryModal';
import TerminateModal from './components/TerminateModal';
import WhatsappIcon from '../../assets/images/whatsapp.svg'
import AssignedIndicator from '../../components/AssignedIndicator';

export default function Mapping() {

  const authData = useSelector(state=>state.auth)

  const [enquiryData,setenquiryData] = useState([])
  const [loading,setLoading] = useState(false)
  const [assignDemo,setAssignDemo] = useState(false)
  const [excludeTutor,setExcludeTutor] = useState(false)
  const [includeTutor,setIncludeTutor] = useState(false)
  const [selectedItem,setSelectedItem] = useState(false)
  const [searchText,setSearchText] = useState('')
  const [selectedCity,setSelectedCity] = useState('all')
  const [fetching,setFetching] = useState(false)

  const [detailedEnquiry,setDetailedEnquiry] = useState(false)

  const [updateEnquiry,setUpdateEnquiry] = useState(false)
  const [terminateEnquiry,setTerminateEnquiry] = useState(false)

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [count,setCount] = useState(0)

  const [rowCountState, setRowCountState] = useState(count || 0,);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      count !== undefined
        ? count
        : prevRowCountState,
    );
  }, [count, setRowCountState]);

  useEffect(async()=>
  {
    console.log('inite cities data',authData)
    try 
    {
      setFetching(true)
      let res = await getMappingEnquery('',selectedCity,page,pageSize)
      setCount(res.count)
      setenquiryData(res.docs)
      setFetching(false)
    } catch (error) {
      setFetching(false)
      return false;
    }
  },[selectedCity,page,pageSize])

  // useEffect(async()=>
  // {
  //   let filteredIndex = enquiryData.findIndex(item=>item._id === setSelectedItem._id)
  //   console.log('filtering',enquiryData[filteredIndex])
  //   // let index = enquiryData.findIndex(item=>item._id === setSelectedItem._id)
  //   setSelectedItem(enquiryData[filteredIndex])
  // },[enquiryData,enquiryData])

  const _onClick = (id)=>
  {
    try 
    {
      let res = enquiryData.filter(item=>item._id === id) 
      console.log(res)
      setSelectedItem(res[0])
    } catch (error) {
      console.log(error)
      return false
    }
  }


  const _assignDemo = async(e,data)=>
  {
    try 
    {
      e.preventDefault()
      await createDemo(data)
      setenquiryData(prev=>prev.filter(i=>i._id != data.enquiryId))
      setAssignDemo(false)
    } catch (error) {
      toast.error(error.response.data.message ? error.response.data.message  : error,TOAST_CONFIG)
      return false
    }
  }


  const _updateNote = async(data)=>
  {

      let res = await updateEnquery({_id:data.id,note:data.note})
      setDetailedEnquiry(res._id)
      // let unfilteredIndex = enquiryData.findIndex(item=>item._id === data.id)
      //  setenquiryData((prev)=>
      //  {
      //    let newData = [...prev]
      //    newData[unfilteredIndex] = res
      //    return newData
      //  })
  }


  
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if(searchValue.length > 3 || searchValue === '')
    {
      const fetchData = async()=>
      {
        setPage(0)
        try 
        {
          setFetching(true)
          let res = await getMappingEnquery(searchValue,selectedCity,page,pageSize)
          setCount(res.count)
          setenquiryData(res.docs)
          setFetching(false)
        } catch (error) {
          setFetching(false)
          return false;
        }
      }
      fetchData()
    }
    };


  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 30,
      getActions: (params) => [
        <GridActionsCellItem
          icon={params.row.priority ? <StarFilled className='star'/> : <Star />}
          label="View"
          showInMenu={false}
          onClick={async()=>
            {
              let res = await authAxios.post('/enquiry/priority',{value:params.row.priority ? !params.row.priority : true,id:params.row._id})
                console.log('response',res)
              // setEnquiryDetailModal(params.row?._id)
              let itemIndex = enquiryData.findIndex(item=>item._id === params.row._id)
              setenquiryData((prev)=>
              {
                let newData = [...prev]
                newData[itemIndex].priority = res.data.priority
                return newData
              })
          }}
        />
      ],
    },
    {field:'firstName',headerName:'Name/Mobile',flex:1,renderCell:(params)=>
    {
      return <div>
          <p className='xxs-text r-weight f-op caps'>{params.row.firstName}<span className='tooltip-trigger' onClick={()=>setDetailedEnquiry(params.row)}><img src={InfoIcon} className="tooltip-trigger-img"/></span></p>
          <p className='xxxs-text q-op r-weight'>{params.row?.primaryMobile === 'primary' ? params.row?.mobile : params.row?.alternateMobile} <a target={'_blank'} href={`https://api.whatsapp.com/send?phone=91${params.row?.primaryMobile === 'primary' ? params.row?.mobile : params.row?.alternateMobile}&text=Hi%2C%20${encodeURI(params.row.firstName)}`}><img src={WhatsappIcon} className="whatsapp-icon"/></a></p>
      </div>
    }},
    {field:'locality',headerName:'Locality',flex:1,renderCell:(params)=>
    {
      return <div>
          <p className='xxxs-text m-weight f-op'>{params.row?.locality}</p>
          <p className='xxxs-text m-weight h-op uppercase'>{params.row?.enquiryId ? params.row?.enquiryId : '-'}</p>
          {/* <p className='xxxs-text q-op r-weight'>{params.row?.primaryMobile === 'primary' ? params.row?.mobile : params.row?.alternateMobile}</p> */}
      </div>
    }},
    {field:'category',headerName:'Cat./Syl./Sub',flex:2,renderCell:(params)=>
    {
      let subjects = params.row?.studentSubjects.map(function (e) {
        return e;
      }).join(', ');
      return <div>
        <p className='xxxs-text m-weight f-op caps'>{params.row?.standard ? `Class-${params.row?.standard}` : params.row?.studentCategory} ({params.row?.studentSyllabus}) <AssignedIndicator left={true} firstName={params.row.assignedTo?.firstName} lastName={params.row.assignedTo?.lastName}/></p>
        <p className='xxxs-text r-weight h-op caps'>{subjects}</p>
    </div>
  }},
  {
    field: 'action',
    type: 'actions',
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        label="Terminate Enquiry"
        showInMenu={true}
        onClick={()=>setTerminateEnquiry(params.row)}
      />
    ],
  },
  
]

const fetchData = async()=>
{
  try 
  {
    setFetching(true)
    let res = await getMappingEnquery(searchText,selectedCity,page,pageSize)
    setCount(res.count)
    setenquiryData(res.docs)
    setFetching(false)
  } catch (error) {
    setFetching(false)
    return false;
  }
}
const _setUpdateEnquiryData = (data)=>
{
    setSelectedItem(null)
    console.log('receved',data)
      // let unfilteredIndex = enquiryData.findIndex(item=>item._id === data._id)
      //  setenquiryData((prev)=>
      //  {
      //    let newData = [...prev]
      //    newData[unfilteredIndex] = data
      //    return newData
      //  })
      setDetailedEnquiry(null)
      fetchData()
  }


  const _terminateEnquiry = async(e,data,setLoading)=>
  {
    try 
    {
      e.preventDefault()
      console.log('id',data)
      setLoading(true)
      let res = await closeEnquiry(data)
      console.log('id',res._id)
      await fetchData()
      setSelectedItem(false)
      // setenquiryData(prev=>prev.filter(i=>i._id != res._id))
      setTerminateEnquiry(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.response.message,TOAST_CONFIG)
      return false
    }
  }

  return (
      <div className="split-main-container">
        {assignDemo ? <AssignDemoModal onClick={_assignDemo}   enquiry={selectedItem} tutor={assignDemo} setAssignDemo={setAssignDemo}/> : null}

        {detailedEnquiry ? <EnquiryInfoPopup id={detailedEnquiry._id} setModal={setDetailedEnquiry} updateNote={_updateNote} setUpdateEnquiry={setUpdateEnquiry}/> : null}
        
        {updateEnquiry ? <AddEnquiryModal setModal={setUpdateEnquiry} mapping={true} updateId={updateEnquiry._id} setEnquiryData={_setUpdateEnquiryData}/> : null}

        {terminateEnquiry ? <TerminateModal onClick={_terminateEnquiry} item={terminateEnquiry}  setTerminateItem={setTerminateEnquiry}/> : null}

            <div>
              <div className="enquiry-table-parent">
              <div className="enquiry-table-header">
                <div className='role-based-search-container'>
                  <select className='role-city-select' defaultChecked={selectedCity} onChange={(e)=>setSelectedCity(e.target.value)}>
                    <option value={'all'}>All</option>
                    {
                      authData.user.cities.map((item,index)=>
                      {
                        return <option key={index} value={item._id}>{item.cityName}</option>
                      })
                    }
                  </select>
                  <input placeholder="Search Enquiry" value={searchText}  className="search-input role-city-search" onChange={(e)=>requestSearch(e.target.value)}/>
                </div>
              </div>
              <div style={{'height':'100%','width':'100%'}}>
                  <DataGrid sx={{'border':'0'}} loading={fetching} columns={columns} rows={enquiryData} disableSelectionOnClick={false} disableColumnSelector={true} onRowClick={(item)=>_onClick(item.row._id)} getRowId={item=>item._id} rowCount={rowCountState} rowsPerPageOptions={[pageSize]} pagination page={page} pageSize={pageSize} paginationMode="server" onPageChange={(newPage) => setPage(newPage)} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}/>
              </div>
              </div>
            </div>

            {selectedItem ?  <MappingList selectedItem={enquiryData.find(item=>item._id === selectedItem._id)} excludeTutor={excludeTutor} setAssignDemo={setAssignDemo} setExcludeTutor={setExcludeTutor} setIncludeTutor={setIncludeTutor} includeTutor={includeTutor} enquiryData={enquiryData} setenquiryData={setenquiryData}/> : null}

      </div>
  )
  }
