import React, { useState,useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { approveEnquiry, getAllEnquiry, terminateEnquiry } from '../../components/services';
import PendingItem from './components/PendingItem';
import PendingInfo from './components/PendingInfo';
import TerminateModal from './components/TerminateModal';
import ApproveModal from './components/ApproveModal';
import AddEnquiryModal from './components/AddEnquiryModal';
import EnquiryDetailed from './components/EnquiryDetailed';

export default function AllEnquiry() {
  const [addModal,setAddModal] = useState(false)
  const [enquiryData,setEnquiryData] = useState([])
  const [loading,setLoading] = useState(false)
  const [viewItem,setViewItem] = useState(false)
  const [terminateItem,setTerminateItem] = useState(false)
  const [approveItem,setApproveItem] = useState(false)

  useEffect(async()=>
  {
    try 
    {
      let res = await getAllEnquiry()
      setEnquiryData(res)  
    } catch (error) {
      console.log(error)
      return false;
    }
  },[''])

  // useEffect(()=>
  // {
  //   if(viewItem)
  //   {

  //     setViewItem(prev=>
  //       {
  //         let index = enquiryData.findIndex(item=>item._id === prev._id)
  //         return enquiryData[index]
  //       })
  //     }
  // },[viewItem])

  const _onClick = (id)=>
  {
    try 
    {
      let res = enquiryData.filter(item=>item._id === id) 
      console.log(res)
      setViewItem(res[0])
    } catch (error) {
      console.log(error)
      return false
    }
  }


  const _terminateEnquiry = async(e,data)=>
  {
    try 
    {
      e.preventDefault()
      await terminateEnquiry(data)
      setEnquiryData(enquiryData.filter(item=>item._id !== data._id))
      setViewItem(false) 
      setTerminateItem(false)
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const _approveEnquiry = async(data)=>
  {
    try 
    {
      await approveEnquiry(data)
      setEnquiryData(enquiryData.filter(item=>item._id !== data._id))
      setViewItem(false) 
      setApproveItem(false)
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const columns = [
    {field:'firstName',headerName:'Name/Mobile',flex:1,renderCell:(params)=>
    {
      return <div>
          <p className='xs-text m-weight f-op'>{params.row.customer?.firstName}</p>
          <p className='xxs-text r-weight h-op'>{params.row.mobile}</p>
      </div>
    }},
    {field:'locality',headerName:'Locality/Type',flex:1,renderCell:(params)=>
  {
    return <div>
        <p className='xs-text m-weight f-op'>{params.row.city?.cityName}</p>
        <p className='xxs-text r-weight h-op'>{params.row.enquiryType}</p>
    </div>
  }}
  ]

  return (
      <div className="split-main-container">
        {addModal ? <AddEnquiryModal setAddModal={setAddModal} setEnquiryData={setEnquiryData}/> : null}
        {terminateItem ? <TerminateModal onClick={_terminateEnquiry} item={terminateItem} setTerminateItem={setTerminateItem}/> : null}

        {approveItem ? <ApproveModal onClick={_approveEnquiry} item={approveItem} setApproveItem={setApproveItem}/> : null}
            <div>
              <div className="enquiry-table-parent">
              <div className="enquiry-table-header">
                <div>
                  <input placeholder="Search Enquiry" className="search-input"/>
                </div>
                <div>
                  <button type="button" class="xs-compact-inverted-btn" onClick={setAddModal}>Add Enqueries</button>
                </div>
              </div>
                <div style={{'height':'100%','width':'100%'}}>
                  <DataGrid sx={{'border':'0'}} columns={columns} rows={enquiryData} disableSelectionOnClick={false} disableColumnSelector={true} onRowClick={(item)=>_onClick(item.row._id)} getRowId={item=>item._id}/>
              </div>
              </div>
            </div>

            {viewItem ?  <div className='split-right-content'>
              <EnquiryDetailed id={viewItem._id} />
            </div> : null}
      </div>
  )
  }
