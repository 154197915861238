import React, { useState, useEffect } from "react";
import { getCategory, getCustomer } from "../../components/services";
import { DataGrid } from "@mui/x-data-grid";
// import TutorInfo from "./tutors/TutorInfo";
import AddClient from "../clients/AddClient";
import { useSelector } from "react-redux";
import EnquiryDetail from "../clients/EnquiryDetail";

function ClientAnalytics() {
  const authData = useSelector(state=>state.auth)

  const [clients, setClients] = useState([]);
  const [filteredRows,setFilteredRows] = useState([])
  const [inputValue, setValue] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [addClientModal,setAddClientModal] = useState(false)
  const [updateClientModal,setUpdateClientModal] = useState(false)
  const [enquiryDetailModal,setEnquiryDetailModal] = useState(false)

  const [selectedCity,setSelectedCity] = useState('all')


  useEffect(() => {
    const fetchData = async () => {
      const res = await getCustomer('',null,selectedCity);
      setClients(res);
      setFilteredRows(res);
    };
    fetchData();
  }, [selectedCity]);


  useEffect(() => {
    if(selectedClient)
    {
      const fetchOneUserData = async () => {
        const res = await getCustomer(selectedClient._id,null,null);
        setClients(res);
        setFilteredRows(res);
      };
      fetchOneUserData();
    }
  }, [selectedClient]);
  


  useEffect(()=>
  {
    console.log('out runninggg')
    if(selectedClient)
    {
      let dataIndex = clients.findIndex(item=>item._id === selectedClient._id)
      setSelectedClient(clients[dataIndex])
    }
  },[clients])


  const _updateClients = (data)=>
  {
    
    setValue('')
    setClients(prev=>
      {
        let newData = [...prev]
        let i = newData.findIndex(i=>i._id === data._id)
        newData[i] = data
        setClients(newData)
        setSelectedClient(data)
        console.log('new',newData)
        return newData
      })
  }


  const columns = [
    {field:'firstName',headerName:'Name/Mobile',flex:1,renderCell:(params)=>{
      return <div className="tutor-list-item">
          <p className="sm-text m-weight f-op">{params.row.firstName} {params.row.lastName}</p>
          <p className="xxs-text r-weight q-op">{params.row.mobile}</p>
      </div>
    }},
    {field:'locality',headerName:'Locality/City',flex:1,renderCell:(params)=>{
      return <div className="tutor-list-item">
          <p className="xs-text r-weight f-op">{params.row.locality}</p>
          {/* <p className="xxs-text r-weight q-op">{params.row.city.cityName}</p> */}
      </div>
    }},
  ]

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    setValue(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = clients.filter((row) => {
      return Object.keys(row).some((field) => {
        if(field === 'mobile' || field === 'firstName' || field === 'lastName' || field === 'alternateMobile')
        {
          return searchRegex.test(row['mobile']?.toString()) || searchRegex.test(row['firstName']?.toString()) || searchRegex.test(row['lastName']?.toString()) || searchRegex.test(row['alternateMobile']?.toString()) ;
        }
      });
    });
    console.log('filtered',filteredRows)
    setFilteredRows(filteredRows);
  };

  return (
    <>
    {addClientModal ? <AddClient setModal={setAddClientModal} setSelectedClient={setSelectedClient}/> : null}
    {updateClientModal ? <AddClient setModal={setUpdateClientModal} updateData={selectedClient} setSelectedClient={setSelectedClient}/> : null}
    {enquiryDetailModal ? <EnquiryDetail setModal={setEnquiryDetailModal} id={enquiryDetailModal}/> : null}
    <div className="split-main-container"> 
      <div className="tutor-list-container">
        <div className="tutor-header-container">
          {/* <div><input type="text" className="text-input" value={searchValue} onChange={(e)=>requestSearch(e.target.value)} placeholder="Search Tutor"/></div> */}
          <div className='role-based-search-container'>
                  <select className='role-city-select' defaultChecked={selectedCity} onChange={(e)=>setSelectedCity(e.target.value)}>
                    <option value={'all'}>All</option>
                    {
                      authData.user.cities.map((item,index)=>
                      {
                        return <option key={index} value={item._id}>{item.cityName}</option>
                      })
                    }
                  </select>
                  <input placeholder="Search Enquiry" value={inputValue}  className="search-input role-city-search" onChange={(e)=>requestSearch(e.target.value)}/>
                </div>
          <div>
            <button className="xxs-btn" onClick={()=>setAddClientModal(true)}>Add Client</button>
          </div>
        </div>
        <div style={{height:'100%'}}><DataGrid columns={columns} isRowSelectable={false} hideFooterSelectedRowCount={true}  rows={clients} getRowId={row=>row._id} onRowClick={(item)=>setSelectedClient(item.row)}/></div>
      </div>
      {selectedClient ? <div className="client-analytics-container">
        <div className="client-analytics-grid">
          <div className="client-analytics-top-content">
            <div className='client-analytics-header'>
              <p className="r-text b-weight">Personal Information</p>
              <div className='client-info-btn-container'>
              <button className="tertiary-btn" onClick={()=>setUpdateClientModal(true)}>Update</button>
              </div>
            </div>
            <div className='client-analytics-content'>
            <div className="input-group-divided-three">
              <div>
                <label>First Name</label>
                <p className="xs-text r-weight f-op caps">{selectedClient.firstName}</p>
              </div>
              <div>
                <label>Last Name</label>
                <p className="xs-text r-weight f-op caps">{selectedClient.lastName}</p>
              </div>
              <div>
                <label>Mobile</label>
                <p className="xs-text r-weight f-op">{selectedClient.mobile}</p>
              </div>
              <div>
                <label>Alternative Mobile</label>
                <p className="xs-text r-weight f-op">{selectedClient.alternateMobile}</p>
              </div>
              <div>
                <label>Email</label>
                <p className="xs-text r-weight f-op">{selectedClient.email}</p>
              </div>
              <div>
                <label>City</label>
                <p className="xs-text r-weight f-op">{selectedClient.city.cityName}</p>
              </div>
              <div>
                <label>Locality</label>
                <p className="xs-text r-weight f-op">{selectedClient.locality}</p>
              </div>
              <div>
                <label>Address</label>
                <p className="xs-text r-weight f-op">{selectedClient.address}</p>
              </div>
              <div>
                <label>Landmark</label>
                <p className="xs-text r-weight f-op">{selectedClient.landmark}</p>
              </div>
              <div>
                <label>State</label>
                <p className="xs-text r-weight f-op">{selectedClient.state}</p>
              </div>
              <div>
                <label>Source</label>
                <p className="xs-text r-weight f-op">{selectedClient.source.name}</p>
              </div>
            </div>
            </div>
          </div>
          <div className="client-analytics-enquiry-list">
          <div style={{height:'100%',width:'100%'}}>
            <DataGrid sx={{'border':'0'}} getRowId={row=>row._id} disableColumnSelector={true} columns={columns} rows={selectedClient.enquiries ? selectedClient.enquiries : []} disableSelectionOnClick={true}/>
        </div>
          </div>
        </div>

      </div> : null}
    </div>
    </>
  );
}
export default ClientAnalytics;
