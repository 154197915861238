import React from "react";
import { NavLink,Outlet } from "react-router-dom";


function Analytics() {
  
  return (
    <>
    <div className="sub-nav">
        <div className="sub-nav-container">
            <NavLink to="/dashboard/analytics/enquiry" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Enquiry</NavLink>
            <NavLink to="/dashboard/analytics/demo" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Demo</NavLink>
            <NavLink to="/dashboard/analytics/tuition" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Tuition</NavLink>
            <NavLink to="/dashboard/analytics/client" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Client</NavLink>
            <NavLink to="/dashboard/analytics/tutor" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>All Tutor</NavLink>
        </div>
    </div>
    <Outlet/>
    </>
  );
}
export default Analytics;
