import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { updateState } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'

export default function ConfirmRemoveModal({onCancel,onClick}) {
    const [progress,setProgress] = useState(false)
    
    const _onClick = async(e)=>
    {
        setProgress(true)
        e.preventDefault()
        await onClick()
        setProgress(false)
        onCancel()
    }
  return (
    <Modal title="Remove Subject" size="md">
        <form onSubmit={_onClick}>
        <div className="modal-inner-content">
            <div className="input-group">
                <p className='r-text m-weight f-op'>Are you sure you want to remove the subject from the syllabus of the category?</p>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={onCancel}/>
            <input type="submit" className="xs-delete-btn" disabled={progress} value="Remove Subject"/>
        </div>
        </form>
    </Modal>
  )
}
