import React, { useEffect, useState } from 'react'
import AutocompleteInput from '../../components/AutocompleteInput'
import Modal from '../../components/Modal'
import {getCategory, getCity, getProfessions, getTutor, updateTutor} from "../../components/services";
import SelectableTag from '../../components/SelectableTag';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../config/constants';
import { languages } from '../../config/languages';
import DownIcon from '../../assets/images/down.png';
import UpIcon from '../../assets/images/up.png';

export default function UpdateTutorModal({setModal,syllabusData,updateData,_updateTutors,secondaryForm = false}) {

    const [loading,setLoading] = useState(true)
    const [toggle,setToggle] = useState('primaryInfo')
    const experience = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','30+']
    const [data,setData] = useState({})

  const [selectedCity,setSelectedCity] = useState({})

  const [categoryData,setCategoryData] = useState([])
  const [professionData,setProfessionData] = useState([])
  const [cityData,setCityData] = useState([])
  const [openedCategory,setOpenedCategory]  = useState(false)
  
  useEffect(()=>
  {
      async function _getTutorInfo(){
          let res = await getTutor('',updateData._id,false,null,'','');
          let langData =[]
           res.languages.map(i=>
            {
              let index = languages.findIndex(item=>{
                // console.log(item.code === item ,item.code,item)
                return item.code === i ? true : false
              })
              if(index > -1) langData.push(languages[index])
              console.log('landad',langData)
            })
          setData({...res,languages:[...langData]})
      }
    async function _getCategory(){
      let res = await getCategory()
      console.log(res)
      setCategoryData(res)
      setOpenedCategory(res[0]._id)
    
    }
    
    async function _getCity(){
      let res = await getCity()
      console.log(res)
      setCityData(res)
      setLoading(false)
    }

    async function _getProfession(){
      let res = await getProfessions()
      console.log(res)
      setProfessionData(res)
    }
    _getTutorInfo()
    _getCategory()
    _getCity()
    _getProfession()
  },[])
  

  const _onSelect = (subjectId,syllabusId,categoryId)=>
  {
    setData(prev=>
      {
        let newData= {...prev}
        let categoryExist = newData.expertise.find(o => o.category === categoryId);
        if(categoryExist)
        {
          let categoryIndex = newData.expertise.findIndex(o => o.category === categoryId);
          if(newData.expertise[categoryIndex].data.find(o=>o.syllabus === syllabusId))
          {
            let syllabusIndex = newData.expertise[categoryIndex].data.findIndex(o => o.syllabus === syllabusId);
            let subjectExist = newData.expertise[categoryIndex].data[syllabusIndex].subjects.includes(subjectId)
            if(subjectExist) 
            {
              let tempData = [...newData.expertise[categoryIndex].data[syllabusIndex].subjects.filter(i => i !== subjectId)];
              newData.expertise[categoryIndex].data[syllabusIndex].subjects = tempData
            }
            else
            {
              newData.expertise[categoryIndex].data[syllabusIndex].subjects.push(subjectId)
            }
          }
          else 
          {
            if(newData.expertise[categoryIndex].data.length)
            {
              newData.expertise[categoryIndex].data.push({syllabus:syllabusId,subjects:[subjectId]})
            }
            else
            {
              newData.expertise[categoryIndex].data = [{syllabus:syllabusId,subjects:[subjectId]}]   
            }
          }
        }
        else 
        {
          newData.expertise.push({category:categoryId,data:[{syllabus:syllabusId,subjects:[subjectId]}]});
        }
        return newData;
      })
    
  }
  
  const CategoryItem = ({data,_onSelect,submitData})=>
  {
    return (
      <div className='tutor-category-container'>
        <div className='tutor-category-container-header-flex' onClick={()=>
      {
        if(!openedCategory) setOpenedCategory(data._id)
        else if(openedCategory && openedCategory === data._id) setOpenedCategory(false)
        // else setOpenedCategory()
      }}>
          <p className='xs-text b-weight f-op category-badge'>{data.name}</p>
          <div>
            <img src={openedCategory === data._id ? UpIcon : DownIcon} className='accordion-icon'/>
          </div>
        </div>
        <div className={`tutor-category-syllabus-accordion ${openedCategory === data._id ? 'tutor-category-syllabus-accordion-active' : ''}`}>
        {
          data.data.map((el,i)=>{
            return <SyllabusItem key={el.syllabus._id} item={el} categoryId={data._id} _onSelect={_onSelect} submitData={submitData}/>
          })
        }
        </div>
    </div>
  )}



  const SyllabusItem = ({item,categoryId,_onSelect,submitData})=>
  {
    return (
      <div className='tutor-syllabus-container'>
        <p className='xxs-text m-weight q-op'>{item.syllabus.name}</p>
        {
          item.subjects.map((el,i)=>
          {
            return <SelectableTag _onSelect={_onSelect} title={el.name} key={el._id} id={el._id} syllabusId={item.syllabus._id} categoryId={categoryId} data={data} submitData={submitData}/>
          })
        } 
      </div>
    )
  }


  const _updateTutor = async(e)=>
  {
    e.preventDefault()
    try 
    {
      let res;
      if(typeof data.languages[0] === 'object')
      {
        let value = Array.from(data.languages, option => option.code);
        // let value = e.map(({ foo }) => foo)
        console.log('values',value)
        // setData(prev=>({...prev,languages: value}));   
         res = await updateTutor({...data,languages:value})
        }
        else
        {
         res = await updateTutor(data)
      }
      // if(secondaryForm === false)
      // {
        _updateTutors(res)
      // }
      setModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  const _onLanguageChange = (e)=>
  {
      console.log('e',e)
      try 
      {
        let value = Array.from(e, option => option.code);
        // let value = e.map(({ foo }) => foo)
        console.log('values',value)
        setData(prev=>({...prev,languages: [...value]}));   
      } catch (error) {
          console.log(error)
      }
  }

  return (
    <Modal title={updateData ? 'Update Tutor' : 'Add Tutor'} size='xxxl'>
      {!loading ? <form onSubmit={_updateTutor}>
        <div className='modal-inner-content'>
          <div className='tutor-split-content'>
            <div>
            <div className="enquiry-header-toggle-container">
                <div className="enquiry-header-toggle-content">
                    <div className={`enquiry-header-toggle-item ${toggle === 'primaryInfo' ? 'enquiry-header-toggle-item-active' : ''}`} onClick={()=>setToggle('primaryInfo')}><p>Personal Info</p></div>
                    <div className={`enquiry-header-toggle-item ${toggle === 'secondaryInfo' ? 'enquiry-header-toggle-item-active' : ''}`} onClick={()=>setToggle('secondaryInfo')}><p>Payment Info</p></div>
                </div>
            </div>
            {
              toggle === 'primaryInfo' ?
          <div className='input-group-divided-three'>
              <div>
                <label>First Name*</label>
                <input type="text" className='text-input' required value={data.firstName} onChange={(e)=>setData(prev=>({...prev,firstName:e.target.value}))}/>
              </div>
              <div>
                <label>Last Name</label>
                <input type="text"  className='text-input' value={data.lastName} onChange={(e)=>setData(prev=>({...prev,lastName:e.target.value}))}/>
              </div>
              <div>
                <label>Class Mode*</label>
                <select className='text-input' required value={data.classMode}  onChange={(e)=>setData(prev=>({...prev,classMode:e.target.value}))}>
                  <option value={'offline'}>Offline</option>
                  <option value={'online'}>Online</option>
                  <option value={'both'}>Both</option>
                </select>
              </div>
              <div>
              <label>Mobile* <div className="primary-mobile-radio-container"><input type={'radio'} checked={data.primaryMobile === 'primary'} radioGroup="primaryMobile" onClick={()=>setData(prev=>({...prev,primaryMobile:'primary'}))}/></div></label>
                <input type="text"  pattern="[1-9]{1}[0-9]{9}" maxLength={10} required className='text-input' value={data.mobile} onChange={(e)=>setData(prev=>({...prev,mobile:e.target.value}))}/>
              </div>
              <div>
              <label>Alternative Number <div className="primary-mobile-radio-container"><input disabled={!data.alternateMobile || data.alternateMobile.length <10} type={'radio'} checked={data.primaryMobile === 'alternate'} radioGroup="primaryMobile" onClick={()=>setData(prev=>({...prev,primaryMobile:'alternate'}))}/></div></label>
                <input type="text"  pattern="[1-9]{1}[0-9]{9}" maxLength={10} className='text-input' value={data.alternateMobile} onChange={(e)=>setData(prev=>({...prev,alternateMobile:e.target.value}))}/>
              </div>
              <div>
                <label>Email</label>
                <input type="email" className='text-input' value={data.email} onChange={(e)=>setData(prev=>({...prev,email:e.target.value}))}/>
              </div>
              <div>
                <label>City*</label>
                <select required value={data.city} onChange={(e)=>setData(prev=>({...prev,city:e.target.value}))}>
                  <option value={''}></option>
                  {
                    cityData.map((item,index)=>
                    {
                      return <option value={item._id}>{item.cityName}</option>
                    })
                  }
                </select>
              </div>
              <div>
                <label>Locality*</label>
                <AutocompleteInput setData={setData} addressText={data.locality} restriction={selectedCity.geo}/>
              </div>
              <div>
                <label>Address</label>
                <input type="text" className='text-input' value={data.address} onChange={(e)=>setData(prev=>({...prev,address:e.target.value}))}/>
              </div>
              <div>
                <label>Gender*</label>
                <select value={data.gender} required onChange={(e)=>setData(prev=>({...prev,gender:e.target.value}))}>
                  <option disabled defaultChecked value={''}></option>
                  <option value={'male'}>Male</option>
                  <option value={'female'}>Female</option>
                </select>
              </div>
              <div className='tutor-notes-grid-item'>
                <label>Notes</label>
                <input type="text" className='text-input' value={data.note} onChange={(e)=>setData(prev=>({...prev,note:e.target.value}))}/>
              </div>
              <div>
                <label>Qualification*</label>
                <input type="text" className='text-input' required value={data.qualification} onChange={(e)=>setData(prev=>({...prev,qualification:e.target.value}))}/>
              </div>
              <div>
                <label>Profession*</label>
                <select className='text-input' required value={data.profession} onChange={(e)=>setData(prev=>({...prev,profession:e.target.value}))}>
                  <option defaultChecked disabled value={''}></option>
                  {
                    professionData.map((item)=>
                    {
                      return <option value={item._id}>{item.name}</option>
                    })
                  }
                </select>
              </div>
              <div>
                <label>Experience*</label>
                <select className='text-input' required value={data.experience} onChange={(e)=>setData(prev=>({...prev,experience:e.target.value}))}>
                  <option defaultChecked disabled value={''}></option>
                  {
                    experience.map((item)=>
                    {
                      return <option value={item}>{item}</option>
                    })
                  }
                </select>
              </div>
              <div>
                <label>Is Active?*</label>
                <select className='text-input' required value={data.isActive} onChange={(e)=>setData(prev=>({...prev,isActive:e.target.value}))}>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>

              <div>
                <label>Is Verified ?*</label>
                <select className='text-input'required  value={data.isVerified} onChange={(e)=>setData(prev=>({...prev,isVerified:e.target.value}))}>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>

              <div>
                <label>Communication Skill</label>
                <select className='text-input' value={data.communicationSkill} onChange={(e)=>setData(prev=>({...prev,communicationSkill:e.target.value}))}>
                  <option value={'good'}>Good</option>
                  <option value={'average'}>Average</option>
                  <option value={'poor'}>Poor</option>
                </select>
              </div>
              
              <div className='tutor-languages-grid-item'>
                <label>Languages*</label>
                <Select options={languages} defaultValue={data.languages} isMulti={true} getOptionLabel={e=>e?.name} getOptionValue={e=>e?.code} onChange={_onLanguageChange}/>
              </div>
              </div> : <div className='input-group-divided-three'>
            <div>
              <label>Account Number</label>
              <input type="text" className='text-input' value={data.accountNumber ? data.accountNumber : ''} onChange={(e)=>setData(prev=>({...prev,accountNumber:e.target.value}))}/>
            </div>
            <div>
              <label>Account IFSC Code</label>
              <input type="text"  className='text-input' value={data.accountIfsc ? data.accountIfsc : ''} onChange={(e)=>setData(prev=>({...prev,accountIfsc:e.target.value}))}/>
            </div>
            <div>
              <label>Bank Name</label>
              <input type="text"  className='text-input' value={data.bankName ? data.bankName : ''} onChange={(e)=>setData(prev=>({...prev,bankName:e.target.value}))}/>
            </div>
            <div>
              <label>Account Holder Name</label>
              <input type="text"  className='text-input' value={data.accountName ? data.accountName : ''} onChange={(e)=>setData(prev=>({...prev,accountName:e.target.value}))}/>
            </div>
            <div>
              <label>UPI Id</label>
              <input type="text"  className='text-input' value={data.upiId ? data.upiId : ''} onChange={(e)=>setData(prev=>({...prev,upiId:e.target.value}))}/>
            </div>
            </div>
            }
            </div>

            <div className='tutor-right-container'>
              {
                categoryData.map((item,index)=>
                {
                  return <CategoryItem data={item} _onSelect={_onSelect} submitData={data}/>
                })
              }
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <input type="button" className="xs-neutral-btn" value="Cancel" onClick={()=>setModal()}/>
          <input type="submit" className="xs-delete-btn" value="Update Tutor"/>
        </div>
      </form> : 'Loading'}
    </Modal>
  )
}