import React, { useState,useEffect, useMemo } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { approveEnquiry, getPendingEnquiry, terminateEnquiry } from '../../components/services';
import PendingItem from './components/PendingItem';
import PendingInfo from './components/PendingInfo';
import TerminateModal from './components/TerminateModal';
import ApproveModal from './components/ApproveModal';
import AddEnquiryModal from './components/AddEnquiryModal';
import { useSelector } from 'react-redux';
import EnquiryInfoPopup from './components/EnquiryInfoPopup'
import InfoIcon from '../../assets/images/info.png'
import { TOAST_CONFIG } from '../../config/constants';
import { toast } from 'react-toastify';
import { getInitials } from '../../helpers/utils';
import AssignedIndicator from '../../components/AssignedIndicator';

export default function Pending() {
  const authData = useSelector(state=>state.auth)

  const [addModal,setAddModal] = useState(false)
  const [enquiryData,setEnquiryData] = useState([])
  const [loading,setLoading] = useState(false)
  const [viewItem,setViewItem] = useState(false)
  const [terminateItem,setTerminateItem] = useState(false)
  const [approveItem,setApproveItem] = useState(false)
  const [searchText,setSearchText] = useState('')
  const [selectedCity,setSelectedCity] = useState('all')
  const [fetching,setFetching] = useState(false)

  const [detailedEnquiry,setDetailedEnquiry] = useState()

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const [countData,setCountData] = useState(0)

  const [rowCountState, setRowCountState] = useState(countData || 0,);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      countData !== undefined
        ? countData
        : prevRowCountState,
    );
  }, [countData, setRowCountState]);



  useEffect(()=>
  {
    if(viewItem)
    {

      setViewItem(prev=>
        {
          let index = enquiryData.findIndex(item=>item._id === prev._id)
          return enquiryData[index]
        })
      }
  },[enquiryData])
  useEffect(()=>
  {
    const fetchData = async()=>
    {
      try 
    {
      console.log('paginfo',page,pageSize)
      setFetching(true)
      let res = await getPendingEnquiry('',selectedCity,page,pageSize)
      setCountData(res.count ? res.count : 0)
      setEnquiryData(res.docs ? res.docs : [])  
      // setFilteredRows(res.docs)
      setFetching(false)
    } catch (error) {
      console.log(error)
      setFetching(false)
      return false;
    }
    }
    fetchData()
  },[selectedCity,page])


  const fetchData = async()=>
  {
    try 
  {
    console.log('paginfo',page,pageSize)
    setFetching(true)
    let res = await getPendingEnquiry('',selectedCity,page,pageSize)
    setCountData(res.count ? res.count : 0)
    setEnquiryData(res.docs ? res.docs : [])  
    // setFilteredRows(res.docs)
    setFetching(false)
  } catch (error) {
    console.log(error)
    setFetching(false)
    return false;
  }
  }

  const _onClick = (id)=>
  {
    try 
    {
      let res = enquiryData.filter(item=>item._id === id) 
      console.log(res)
      setViewItem(res[0])
    } catch (error) {
      console.log(error)
      return false
    }
  }


  const _terminateEnquiry = async(e,data)=>
  {
    try 
    {
      e.preventDefault()
      await terminateEnquiry(data)
      await fetchData()
      setViewItem(false) 
      setTerminateItem(false)
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const _approveEnquiry = async(data,setSubmitting)=>
  {
    try 
    {
      setSubmitting(true)
      await approveEnquiry(data)
      await fetchData()
      setViewItem(false) 
      setApproveItem(false)
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      toast.error(error.message,TOAST_CONFIG)
      return false
    }
  }

  // <span className='tooltip-trigger' onClick={()=>setDetailedEnquiry(params.row)}><img src={InfoIcon} className="tooltip-trigger-img"/></span>
  const columns = [
    {field:'firstName',headerName:'Name/Mobile',flex:1,renderCell:(params)=>
    {
      return <div className={` ${params.row.isPremium ? 'primary-enquiry-list-item' : ''}`}>
          <p className='xs-text m-weight f-op caps'>{params.row.firstName} <AssignedIndicator firstName={params.row.assignedTo.firstName} lastName={params.row.assignedTo.lastName}/></p>
          <p className='xxs-text r-weight h-op'>{params.row.mobile}<span className='xxxs-text m-weight uppercase'> ({params.row.enquiryId})</span></p>
      </div>
    }},
    {field:'locality',headerName:'Locality/Type',flex:1,sortable:false,renderCell:(params)=>
  {
    return <div>
        <p className='xs-text m-weight f-op'>{params.row.city}</p>
        <p className='xxs-text r-weight h-op'>{params.row.enquiryType}</p>
    </div>
  }}
  ]


  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if(searchValue.length > 3 || searchValue === '')
    {
      const fetchData = async()=>
      {
        try 
        {
          setPage(0)
          console.log('paginfo',page,pageSize)
          setFetching(true)
          let res = await getPendingEnquiry(searchValue,selectedCity,page,pageSize)
          setCountData(res.count)
          setEnquiryData(res.docs)
          setFetching(false)
        } catch (error) {
          console.log(error)
          setFetching(false)
          return false;
        }
      }
      fetchData()
    }
    };
    

  return (
      <div className="split-main-container">
        {addModal ? <AddEnquiryModal setModal={setAddModal} fetchData={fetchData} setViewItem={setViewItem}/> : null}
        {terminateItem ? <TerminateModal onClick={_terminateEnquiry} item={terminateItem} setTerminateItem={setTerminateItem}/> : null}

        {approveItem ? <ApproveModal onClick={_approveEnquiry} item={approveItem} setApproveItem={setApproveItem}/> : null}

        {detailedEnquiry ? <EnquiryInfoPopup id={detailedEnquiry._id} setModal={setDetailedEnquiry}/> : null}
            <div>
              <div className="enquiry-table-parent">
              <div className="enquiry-table-header">
              <div className='role-based-search-container'>
                  <select className='role-city-select' defaultChecked={selectedCity} onChange={(e)=>setSelectedCity(e.target.value)}>
                    <option value={'all'}>All</option>
                    {
                      authData.user.cities.map((item,index)=>
                      {
                        return <option key={index} value={item._id}>{item.cityName}</option>
                      })
                    }
                  </select>
                  <input placeholder="Search Enquiry" value={searchText}  className="search-input role-city-search" onChange={(e)=>requestSearch(e.target.value)}/>
                </div>
                {/* <div>
                  <input placeholder="Search Enquiry" className="search-input" onChange={(e)=>requestSearch(e.target.value)}/>
                </div> */}
                <div>
                  <button type="button" className="xs-compact-inverted-btn" onClick={setAddModal}>Add Enquiry</button>
                </div>
              </div>
                <div style={{'height':'100%','width':'100%'}}>
                  <DataGrid disableColumnFilter sx={{'border':'0','padding':'0'}} loading={fetching} columns={columns} rows={enquiryData} disableSelectionOnClick={true} disableColumnSelector={true} onRowClick={(item)=>_onClick(item.row._id)} getRowId={item=>item._id} rowCount={rowCountState} rowsPerPageOptions={[pageSize]} pagination page={page} pageSize={pageSize} paginationMode="server" onPageChange={(newPage) => setPage(newPage)} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}/>
              </div>
              </div>
            </div>

            {viewItem ?  <PendingInfo viewItem={viewItem} setEnquiryData={setEnquiryData} setTerminateItem={setTerminateItem} setApproveItem={setApproveItem} setViewItem={setViewItem}/> : null}
      </div>
  )
  }
