import React, { useState,useEffect } from 'react'
import { terminateEnquiry as closeEnquiry, getTutions,updateTuition as update,completeTuition, updateTutor as updateTutorService, updateEnquery } from '../../components/services';
import { DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CompleteTuition from './components/CompleteTuition';
import TerminateModal from './components/TerminateModal';
import CancelDemoModal from './components/CancelDemoModal';
import { toast } from 'react-toastify';
import { TOAST_CONFIG, TUITION_STATUS } from '../../config/constants';
import UpdateTuition from './components/UpdateTuition';
import { getDate } from '../../helpers/dateHelper';
import PaymentModal from './components/PaymentModal';
import { useSelector } from 'react-redux';
import InfoIcon from '../../assets/images/info.png'
import TutorInfoPopup from './components/TutorInfoPopup';
import CustomerInfoPopup from './components/CustomerInfoPopup';
import SecondEnquiryInfoPopup from './components/SecondEnquiryInfoPopup';
import SecondTutorInfoPopup from './components/SecondTutorInfoPopup';
import UpdateTutorModal from '../tutors/UpdateTutorModal';
import AssignedIndicator from '../../components/AssignedIndicator';

export default function Demo() {
  const authData = useSelector(state=>state.auth)

  const [tuitionData,setTuitionData] = useState([])
  const [updateTuition,setUpdateTuition] = useState(false)
  const [updatePayment,setUpdatePayment] = useState(false)
  const [completedTuition,setCompletedTuition] = useState(false)
  // const [cancelledTuition,setCancelledTuition] = useState(false)
  const [terminateEnquiry,setTerminateEnquiry] = useState(false)

  const [filteredRows,setFilteredRows] = useState([])
  const [searchText,setSearchText] = useState('')

  const [selectedCity,setSelectedCity] = useState('all')
  const [fetching,setFetching] = useState(false)
  const [detailedTutor,setDetailedTutor] = useState(false)
  const [detailedEnquiry,setDetailedEnquiry] = useState(false)

  const [updateTutor,setUpdateTutor] = useState(null)

  useEffect(async()=>
  {
    try 
    {
      setFetching(true)
      let res = await getTutions(selectedCity)
      console.log(res)
      setTuitionData(res)
      setFilteredRows(res)
      setFetching(false)
    } catch (error) {
      console.log(error)
      setFetching(false)
      return false;
    }
  },[selectedCity])



  const _updateTuition = async(e,data,setLoading)=>
  {
    try 
    {
      setLoading(true)
      e.preventDefault()
      let res = await update(data)
      setTuitionData(prev=>
        {
          let newData = [...prev]
          let index = newData.findIndex(item=>item._id === data._id)
          newData[index] = res
          return newData
        })
        setFilteredRows(prev=>
          {
            let newData = [...prev]
            let index = newData.findIndex(item=>item._id === data._id)
            newData[index] = res
            return newData
          })
      setUpdateTuition(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.response.message,TOAST_CONFIG)
      return false
    }
  }

  const _completeTuition = async(e,data,setLoading)=>
  {
    try 
    {
      e.preventDefault()
      setLoading(true)
      await completeTuition(data)
      setTuitionData(prev=>prev.filter(i=>i._id != data._id))
      setFilteredRows(prev=>prev.filter(i=>i._id != data._id))
      setCompletedTuition(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.response.message,TOAST_CONFIG)
      return false
    }
  }

  const _terminateEnquiry = async(e,data,setLoading)=>
  {
    try 
    {
      console.log('id',data._id)
      setLoading(true)
      e.preventDefault()
      let res = await closeEnquiry(data)
      console.log('id',res._id)
      setTuitionData(prev=>prev.filter(i=>i.enquiry._id != res._id))
      setFilteredRows(prev=>prev.filter(i=>i.enquiry._id != res._id))
      setTerminateEnquiry(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.response.message,TOAST_CONFIG)
      return false
    }
  }

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = tuitionData.filter((row) => {
      return Object.keys(row).some((field) => {
        console.log('fields',field)
        if(field === 'enquiry' || field === 'customer' || field === 'customer' || field === 'alternateMobile')
        {
          console.log('running',field)
          return searchRegex.test(row['enquiry']['mobile']?.toString()) || searchRegex.test(row['customer']['firstName']?.toString()) || searchRegex.test(row['customer']['lastName']?.toString()) || searchRegex.test(row['enquiry']['alternateMobile']?.toString()) ;
        }
      });
    });
    console.log(filteredRows)
    setFilteredRows(filteredRows);
  };


  const _getStatus = (status)=>
  {
    let item = TUITION_STATUS.find(item=>item.status === status)
    return item.statusName
  }


  const _getActions = (params)=>
  {
    let arr = [
      <GridActionsCellItem
        label="Update Tuition"
        showInMenu={true}
        onClick={()=>
          {
            let item = tuitionData.filter((i,index)=>{
              console.log(i)
              return i._id === params.id
            })
            console.log('item',item)
            setUpdateTuition(item[0])
        }}
      />,
      <GridActionsCellItem
        label="View Payments"
        showInMenu={true}
        onClick={()=>
          {
            let item = tuitionData.filter((i,index)=>{
              console.log(i)
              return i._id === params.id
            })
            console.log('item',item)
            setUpdatePayment(item[0])
        }}
      />
    ]

    if(authData.user.role === 'manager')
    {
      arr.push( <GridActionsCellItem
        label="Completed"
        showInMenu={true}
        onClick={()=>
          {
            let item = tuitionData.filter((i,index)=>{
              console.log('params',params)
              return i._id === params.id
            })
            console.log('item',item)
            setCompletedTuition(item[0])
        }}
      />,    <GridActionsCellItem
        label="Terminate Enquiry"
        showInMenu={true}
        onClick={()=>
          {
            let item = tuitionData.filter((i,index)=>{
              console.log(i)
              return i._id === params.id
            })
            console.log('item',item)
            setTerminateEnquiry(item[0])
        }}
      />)
    }
    return arr
  }
  const columns =[
    {field:'firstName',headerName:'Customer',flex:1,renderCell:(params)=>
        {
          return <div className='tooltip-parent'>
            <p className='xxs-text f-op m-weight caps'>{params.row?.customer.firstName} {params.row?.customer.lastName}<span className='tooltip-trigger' onClick={()=>setDetailedEnquiry(params.row.enquiry)}><img src={InfoIcon} className="tooltip-trigger-img"/></span></p>
            <p className='xxxs-text q-op r-weight'>{params.row?.enquiry.primaryMobile === 'primary' ? params.row?.enquiry.mobile : params.row?.enquiry.alternateMobile}</p>
          </div>
        }},
        {field:'studentCategory',headerName:'Category/Syllabus',flex:2,renderCell:(params)=>
        {
          let subjects = params.row?.enquiry.studentSubjects.map(function (e) {
            return e.name;
          }).join(', ');
          return <div>
            <p className='xxs-text f-op m-weight caps'>{params.row?.enquiry.standard ? `Class-${params.row?.enquiry.standard}`  : params.row?.enquiry.studentCategory.name} ({params.row?.enquiry.studentSyllabus.name}) <AssignedIndicator left={true} firstName={params.row.enquiry.assignedTo?.firstName} lastName={params.row.enquiry.assignedTo?.lastName}/></p>
            <p className='xxxs-text q-op r-weight'>{subjects}</p>
          </div>
        }},
    {field:'tutor',headerName:'Tutor',flex:1,renderCell:(params)=>
        {
          return <div className='tooltip-parent'>
            <p className='xxs-text f-op m-weight caps'>{params.row?.tutor.firstName} {params.row?.tutor.lastName}<span className='tooltip-trigger' onClick={()=>setDetailedTutor(params.row.tutor)}><img src={InfoIcon} className="tooltip-trigger-img"/></span></p>
            <p className='xxxs-text q-op r-weight'>{params.row?.tutor.primaryMobile === 'primary' ? params.row?.tutor.mobile : params.row?.tutor.alternateMobile}</p>
          </div>
        }},
    {field:'startDate',headerName:'Tuition',flex:1,renderCell:(params)=>
        {
          return <div className='tooltip-parent'>
            <p className='xxs-text f-op m-weight caps'>{getDate(params.row?.startDate)}</p>
            <p className='xxxs-text q-op r-weight caps'>{params.row?.enquiry.enquiryType}</p>
          </div>
        }},
        // {field:'enquiry',headerName:'Enquiry Type',flex:1,valueGetter: (params) => params.row?.enquiry.enquiryType},
        {field:'hours',headerName:'Hours/Days',flex:1,renderCell:(params)=>
        {
          return <div>
      <p className='xs-text f-op'>{params.row.fee ? params.row.fee : '-'}</p>
      <p className='xxs-text h-op'>{params.row.duration} / {params.row.days}</p>
    </div>
  }},
  {field:'status',headerName:'Status',flex:1,renderCell:(params)=>
  {
    return  <div>
        {params.row.status === '0' || params.row.status === 0 ? '-' : <p className={`xxxs-text m-weight status-badge ${params.row.status === 10 ? 'status-dispute-badge' : 'status-badge-active'}`}>{_getStatus(params.row.status)}</p>}
    </div> 
  }},
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params)=>_getActions(params)
    },
  ]

  const _updateNote = async(data)=>
  {
      let res = await updateEnquery({_id:data.id,note:data.note})
      let filteredIndex = filteredRows.findIndex(item=>item.enquiry._id === data.id)
      let unfilteredIndex = tuitionData.findIndex(item=>item.enquiry._id === data.id)
       setFilteredRows((prev)=>
       {
         let newData = [...prev]
         newData[filteredIndex].enquiry = res
         return newData
       })
       setTuitionData((prev)=>
       {
         let newData = [...prev]
         newData[unfilteredIndex].enquiry = res
         return newData
       })
  }

  const _updateTutorNote = async(data)=>
  {
      let res = await updateTutorService({_id:data.id,note:data.note})
      let filteredIndex = filteredRows.findIndex(item=>item.tutor._id === data.id)
      let unfilteredIndex = tuitionData.findIndex(item=>item.tutor._id === data.id)
       setFilteredRows((prev)=>
       {
         let newData = [...prev]
         newData[filteredIndex].tutor = res
         return newData
       })
       setTuitionData((prev)=>
       {
         let newData = [...prev]
         newData[unfilteredIndex].tutor = res
         return newData
       })
       setDetailedTutor(res)
  }

  const _setUpdateTutorData = (data)=>
  {
      // setSelectedItem(null)
      console.log('receved',data)
      let filteredIndex = tuitionData.findIndex(item=>item.tutor._id === data._id)
        let unfilteredIndex = filteredRows.findIndex(item=>item.tutor._id === data._id)
         setTuitionData((prev)=>
         {
           let newData = [...prev]
           newData[filteredIndex].tutor = data
           return newData
         })
         setFilteredRows((prev)=>
         {
           let newData = [...prev]
           newData[unfilteredIndex].tutor = data
           return newData
         })
    }

  return (
      <div className="demo-main-container">
        {completedTuition ? <CompleteTuition onClick={_completeTuition} item={completedTuition} setModal={setCompletedTuition}/> : null}

        {updateTuition ? <UpdateTuition onClick={_updateTuition} item={updateTuition} setModal={setUpdateTuition}/> : null}

        {updatePayment ? <PaymentModal onClick={_updateTuition} item={updatePayment} setModal={setUpdatePayment}/> : null}

        {detailedTutor ? <TutorInfoPopup updateNote={_updateTutorNote} info={detailedTutor} setModal={setDetailedTutor} setUpdateTutor={setUpdateTutor}/> : null}
        {detailedEnquiry ? <SecondEnquiryInfoPopup updateNote={_updateNote} data={tuitionData.find(item=>item.enquiry._id === detailedEnquiry._id)} setModal={setDetailedEnquiry}/> : null}

        {terminateEnquiry ? <TerminateModal onClick={_terminateEnquiry} item={terminateEnquiry.enquiry}  setTerminateItem={setTerminateEnquiry}/> : null}

        {updateTutor ? <UpdateTutorModal setModal={setUpdateTutor} mapping={false} updateData={updateTutor} _updateTutors={_setUpdateTutorData}/> : null}


            <div>
              <div className="demo-list-content">
              <div className="demo-list-header">
              <div className='role-based-search-container'>
                  <select className='role-city-select' defaultChecked={selectedCity} onChange={(e)=>setSelectedCity(e.target.value)}>
                    <option value={'all'}>All</option>
                    {
                      authData.user.cities.map((item,index)=>
                      {
                        return <option key={index} value={item._id}>{item.cityName}</option>
                      })
                    }
                  </select>
                  <input placeholder="Search Enquiry" value={searchText}  className="search-input role-city-search" onChange={(e)=>requestSearch(e.target.value)}/>
                </div>
              </div>
              <div className="demo-table-container" style={{height:'500px',width:'100%'}}>
              <DataGrid disableColumnSelector={true} loading={fetching} columns={columns} rows={filteredRows} getRowId={item=>item._id} disableSelectionOnClick={true} style={{border:0}}/>
              </div>
              </div>
            </div>
      </div>
  )
  }
