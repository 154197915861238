import { useState,useEffect } from "react";
import InfoModal from "../../../components/InfoModal"
import { getDate } from "../../../helpers/dateHelper";
import EditIcon from "../../../assets/images/edit.png"
import UpdateNote from "./UpdateNote";
import { getEnquiryDetail } from "../../../components/services";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../../config/constants";
import Loader from "../../../components/Loader";

const EnquiryInfoPopup = ({id,setModal,updateNote,setUpdateEnquiry})=>
{

    const [data,setData] = useState({})
    const [loading,setLoading] = useState(true)
    const [editNote,setEditNote] = useState(null)
    

    const getSubjects = (subject)=>
    {
        return subject.map(function (e) {
            return e.name;
          }).join(', ');
    }

      useEffect(() => {
        const getEnquiryInfo = async()=>
        {
            try {
                let res = await getEnquiryDetail(id)
                setData(res)
                setLoading(false)
                return true;
            } catch (error) {
                console.log(error)
                toast.error('error',TOAST_CONFIG)
                setLoading(false)
                return false;
            }
        }
        getEnquiryInfo()
      }, [id])

  return  <InfoModal setModal={setModal}>
  {editNote ? <UpdateNote setModal={setEditNote} item={editNote} onClick={updateNote}/> : null}
    {!loading ? <>
  <div className='info-popup-header'>
      <div>
          <p className='r-text m-weight f-op caps'>{data.customer.firstName} {data.customer.lastName}</p>
          <p className='xxs-text m-weight q-op'>{data.enquiryId ? data.enquiryId : 'Not Available'} {data.isPremium === true ? <span className='premium-badge'>Premium</span> : null}</p>
      </div>
      <div>
        <button className="compact-xs-btn" onClick={()=>setUpdateEnquiry(data)}>Edit</button>
      </div>
  </div>
  <div className='info-popup-grid'>
    <div>
        <label>Mobile {data.primaryMobile === 'primary' ? <span className='primary-badge'>Primary</span> : null}</label>
        <p className="xxs-text f-op m-weight">{data.mobile}</p>
    </div>

    <div>
        <label>Alternative Mobile {data.primaryMobile === 'alternate' ? <span className='primary-badge'>Primary</span> : null}</label>
        <p className="xxs-text f-op m-weight">{data.alternateMobile}</p>
    </div>

    <div>
        <label>Email</label>
        <p className="xxs-text f-op m-weight">{data.customer.email}</p>
    </div>

    <div>
        <label>City</label>
        <p className="xxs-text f-op m-weight">{data.city.cityName}</p>
    </div>

    <div>
        <label>Locality</label>
        <p className="xxs-text f-op m-weight">{data.locality}</p>
        <a className='xxxs-text' target="_blank" href={`https://maps.google.com/?q=${data.geo.lat},${data.geo.lng}`}>View In Map</a>
    </div>

    
    <div>
        <label>Landmark</label>
        <p className="xxs-text f-op m-weight caps">{data.landmark}</p>
    </div>

    <div>
        <label>Address</label>
        <p className="xxs-text f-op m-weight caps">{data.address}</p>
    </div>

    <div>
        <label>Enquiry Type</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiryType}</p>
    </div>

    <div>
        <label>Category/Standard</label>
        <p className="xxs-text f-op m-weight caps">{data.studentCategory.name} {data.standard ? `/ ${data.standard}` : ` / -`}</p>
    </div>

    <div>
        <label>Syllabus</label>
        <p className="xxs-text f-op m-weight caps">{data.studentSyllabus.name}</p>
    </div>
    <div>
        <label>Subjects</label>
        <p className="xxs-text f-op m-weight caps">{getSubjects(data.studentSubjects)}</p>
    </div>
    <div>
        <label>No. of Days(Per Week)</label>
        <p className="xxs-text f-op m-weight caps">{data.days}</p>
    </div>

    <div>
        <label>Class Duration / Timing Preference</label>
        <p className="xxs-text f-op m-weight">{data.classDuration} Hour {data.timingPreference ? ` / ${data.timingPreference}` : ' / -'}</p>
    </div>

    <div>
        <label>Tutor Gender</label>
        <p className="xxs-text f-op m-weight">{data.tutorGender}</p>
    </div>

    <div>
        <label>Fee Type/Amount</label>
        <p className="xxs-text f-op m-weight caps">{data.feeType} / {data.feeAmount}</p>
    </div>

    <div>
        <label>Note<span className='tooltip-trigger' onClick={()=>setEditNote(data)}><img src={EditIcon} className="tooltip-trigger-img"/></span></label>
        <p className="xxs-text f-op m-weight caps">{data.note}</p>
    </div>
    
    <div>
        <label>Source</label>
        <p className="xxs-text f-op m-weight caps">{data.source?.name}</p>
    </div>

    <div>
        <label>Created At</label>
        <p className="xxs-text f-op m-weight caps">{getDate(data.createdAt,false)}</p>
    </div>

    <div>
        <label>Created By</label>
        <p className="xxs-text f-op m-weight caps">{data.addedBy ? `${data.addedBy.firstName} ${data.addedBy.lastName} (${data.addedBy.role})` : 'Not Available'}</p>
    </div>

    <div>
        <label>Assigned To</label>
        <p className="xxs-text f-op m-weight caps">{data.assignedTo ? `${data.assignedTo.firstName} ${data.assignedTo.lastName} (${data.assignedTo.role})` : 'Not Available'}</p> 
    </div>

    {data.isPremium ? <div>
        <label>Premium Payment Link</label>
        <p className="xxs-text f-op m-weight">{data.premiumPayment} <button className='copy-btn' onClick={() => {navigator.clipboard.writeText(data.premiumPayment)}}>Copy</button></p> 
    </div> : null}

       </div></> : <Loader/>}
</InfoModal>
}

export default EnquiryInfoPopup