import React, { useState, useEffect } from "react";
import { getEnquiryDetail } from "../../../components/services";
function EnquiryDetailed({id}) {
  const [data, setData] = useState();
  const [loading,setLoading] = useState(true)
  const [toggle,setToggle] = useState('demo')

  useEffect(async () => {
      if(id)
      {
        setLoading(true)
          let res = await getEnquiryDetail(id)
          console.log(res)
          setData(res);
          setLoading(false)
        }
  }, [id]);

  const _getSubjects = ()=>
  {
    let subjects = data.studentSubjects.map(function (e) {
        return e.name;
      }).join(', ');
      return subjects
  }



  return  !loading ? <>
    <div className="enquiry-detailed-container">
      <div className="enquiry-detailed-content">
          <div className="input-group-divided">
        <div>
            <label>First name</label>
            <p className="xxs-text m-weight f-op caps">{data.customer?.firstName}</p>
        </div>
        <div>
            <label>Last name</label>
            <p className="xxs-text m-weight f-op caps">{data.customer?.lastName}</p>
        </div>
        <div>
            <label>Mobile {data.primaryMobile === 'primary' ? <span className='primary-badge'>Primary</span> : null}</label>
            <p className="xxs-text m-weight f-op">{data.mobile}</p>
        </div>
        <div>
            <label>Alternate Mobile {data.primaryMobile === 'alternate' ? <span className='primary-badge'>Primary</span> : null}</label>
            <p className="xxs-text m-weight f-op">{data.alternateMobile}</p>
        </div>
        <div>
            <label>Email</label>
            <p className="xxs-text m-weight f-op">{data.customer.email}</p>
        </div>
        <div>
            <label>City</label>
            <p className="xxs-text m-weight f-op">{data.city.cityName}</p>
        </div>
        <div>
            <label>Locality</label>
            <p className="xxs-text m-weight f-op">{data.locality}</p>
        </div>
        <div>
            <label>Landmark</label>
            <p className="xxs-text m-weight f-op">{data.landmark}</p>
        </div>
        <div>
            <label>Address</label>
            <p className="xxs-text m-weight f-op">{data.address}</p>
        </div>
        <div>
            <label>Enquiry Type</label>
            <p className="xxs-text m-weight f-op caps">{data.enquiryType}</p>
        </div>
        <div>
            <label>Category</label>
            <p className="xxs-text m-weight f-op">{data.studentCategory.name}</p>
        </div>
        <div>
            <label>Syllabus</label>
            <p className="xxs-text m-weight f-op">{data.studentSyllabus.name}</p>
        </div>
        <div>
            <label>Subjects</label>
            <p className="xxs-text m-weight f-op">{_getSubjects()}</p>
        </div>
        <div>
            <label>Class Duration/Days</label>
            <p className="xxs-text m-weight f-op">{data.classDuration} / {data.days}</p>
        </div>
        <div>
            <label>Fee</label>
            <p className="xxs-text m-weight f-op caps">{data.feeAmount} - {data.feeType}</p>
        </div>
        <div>
            <label>Follow up Date</label>
            <p className="xxs-text m-weight f-op caps">{data.followupdate ? data.followupdate : '-'}</p>
        </div>
        <div>
            <label>Tutor Gender</label>
            <p className="xxs-text m-weight f-op caps">{data.tutorGender ? data.tutorGender : '-'}</p>
        </div>
        <div>
            <label>Current Status</label>
            <p className="xxs-text m-weight f-op">{data.isCompleted ? 'Completed' : data.isTerminated ? 'Terminated' : data.currentProcess === 0 ? 'Pending' : data.currentProcess === 1 ? 'Approved' : data.currentProcess === 2 ? 'Demo' : 'Tuition'}</p>
        </div>
        </div>
        <div className="enquiry-detail-right-content">
            <div className="enquiry-header-toggle-container">
                <div className="enquiry-header-toggle-content">
                    <div className={`enquiry-header-toggle-item ${toggle === 'demo' ? 'enquiry-header-toggle-item-active' : ''}`} onClick={()=>setToggle('demo')}><p>Demo</p></div>
                    <div className={`enquiry-header-toggle-item ${toggle === 'tuition' ? 'enquiry-header-toggle-item-active' : ''}`} onClick={()=>setToggle('tuition')}><p>Tuition</p></div>
                </div>
            </div>
            { toggle === 'demo' ? <div>
                {
                    data.demos.map((item,index)=>
                        {
                            return <div>
                                <div className="demo-detail-list-item">
                                        <div className="flex header-flex mb-xs">
                                            <p className="xxxs-text m-weight h-op">#{index+1}</p>
                                            <p className="xxs-text m-weight f-op caps">Tutor: {item.tutor.firstName} {item.tutor.lastName}</p>
                                        </div>
                                {item.demoInfo.map((i,d)=>
                                    {
                                        return <div className="demo-info-sub-item">
                                            <div className="flex mb-xs">
                                                <div>
                                                <p className="xxxs-text m-weight f-op">{i.demoDate} {i.demoTime}</p>
                                                <p className="xxxs-text m-weight h-op">{i.rescheduledReason}</p>
                                                </div>
                                                {i.rescheduledReason ? <p className="xxxs-text m-weight h-op rescheduled-badge">Rescheduled</p> : ''}
                                                
                                            </div>
                                            <div className="flex">
                                                {/* <p className="xxs-text m-weight f-op caps">{item.tutor.firstName} {item.tutor.lastName}</p> */}
                                                
                                            </div>
                                        </div>
                                    })}
                                    {item.isCancelled ? <div className="flex bottom-flex mb-xs">
                                            <p className="xxxs-text m-weight f-op caps">Cancelled</p>
                                            <p className="xxxs-text m-weight f-op caps">Reason: {item.cancellationReason}</p>
                                        </div> : null}
                                </div>
                            </div>
                        })
                }
            </div>
            : <div>
                <div className="input-group-divided">
                     <div>
                         <label>Fee Amount</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.fee} / Hourly</p>
                     </div>
                     <div>
                         <label>Duration/Days</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.duration} / {data.tuition?.days}</p>
                     </div>
                     <div>
                         <label>Courier Sent</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.courier ? 'Sent' : 'Not Sent'}</p>
                     </div>
                     <div>
                         <label>Address Proof</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.addressProof ? 'Received' : 'Not Received'}</p>
                     </div>
                     <div>
                         <label>Photo</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.photo ? 'Received' : 'Not Received'}</p>
                     </div>
                     <div>
                         <label>Client Payment Confirmed</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.clientPaymentConfirmed ? 'Confirmed' : 'Not Confirmed'}</p>
                     </div>
                     <div>
                         <label>Tutor Payment Confirmed</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.tutorPaymentConfirmed ? 'Confirmed' : 'Not Confirmed'}</p>
                     </div>
                     <div>
                         <label>Start Date</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.startDate}</p>
                     </div>
                     <div>
                         <label>Duration</label>
                         <p className="xs-text m-weight f-op">{data.tuition?.duration}</p>
                     </div>
                </div>
            </div>}
        </div>
        </div>
      </div></> : 'Loading'
}

export default EnquiryDetailed;
