import { getAuth, onAuthStateChanged,onIdTokenChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { LOGIN, LOGOUT, UPDATETOKEN } from "./redux/constants/constants";
import Employees from "./pages/Employees";
import Clients from "./pages/Clients";
import Protected from './components/Protected';
import Enqueries from "./pages/Enqueries";
import Tutors from './pages/Tutors'
import Settings from "./pages/Settings";
import Nav from "./pages/Nav";
import Pending from "./pages/Enquiries/Pending";
import Mapping from "./pages/Enquiries/Mapping";
import Demo from "./pages/Enquiries/Demo";
import Tuition from "./pages/Enquiries/Tuition";
import Categories from "./pages/settings/Categories";
import Syllabus from "./pages/settings/Syllabus";
import Cities from "./pages/settings/Cities";
import AllEnquiry from "./pages/Enquiries/AllEnquiry";
import Profession from "./pages/settings/Profession";
import Source from "./pages/settings/Source";
import TutorSecondary from "./pages/TutorSecondary";
import axios from "axios";
import { authAxios } from "./helpers/axios";
import Analytics  from "./pages/Analytics";
import TuitionAnalytics from "./pages/analytics/TuitionAnalytics";
import DemoAnalytics from "./pages/analytics/DemoAnalytics";
import ClientAnalytics from "./pages/analytics/ClientAnalytics";
import EnquiryAnalytics from "./pages/analytics/EnquiryAnalytics";
import Loader from './components/Loader';
import Leads from './pages/Leads';
import TutorRegistration from './pages/TutorRegistrations';

export default function HomeRoute() {

    const FirebaseAuth = getAuth()
  const dispatch = useDispatch()
  const [loading,setLoading] = useState(true)
  const authInfo = useSelector(state=>state.auth)
  const navigate = useNavigate()

    useEffect(()=>{
    
        const stateChange = async()=>
        {
         await onAuthStateChanged(FirebaseAuth, async(user) => {
           if (user) {
             let token = await FirebaseAuth.currentUser.getIdToken(true)
             let userInfo = await axios.get(`${process.env.REACT_APP_BASE_URL}/employee/info`,{headers: {
               'Authorization': token
             }})
             dispatch({type:LOGIN ,payload:{user:userInfo.data,token:user.accessToken,isAuthenticated:true}})
             await authAxios.interceptors.request.use(
               async(config) => {
                 let token = await FirebaseAuth.currentUser.getIdToken(true)
                   config.headers.Authorization = token;
                   console.log('axis running inside')
                 return config;
               }
             ,(error) => {
               console.log('Token error',error.message)
               return Promise.reject(error)
              });
             //  console.log('login userinfo',user.accessToken)
              setLoading(false)
           } else {
               dispatch({type:LOGOUT})
           }
           });
           }
           console.log(authInfo)

          //  onIdTokenChanged(function(user) {
          //   if (user) {
          //     dispatch({type:UPDATETOKEN ,payload:{token:user.accessToken}})
          //   }
          // });
           stateChange()
       },[''])



  return (
    !loading ? <>
        <Routes> 
        <Route path='' element={<Nav/>}>
    { authInfo?.user?.role === 'support' ? null : <Route index path="/client" element={<Clients />}/>}
    { authInfo?.user?.role === 'manager' || authInfo?.user?.role === 'support' ?<Route path="/leads" element={<Leads/>}/> : null}
    { authInfo?.user?.role === 'manager' || authInfo?.user?.role === 'support' ?<Route path="/tutor-registration" element={<TutorRegistration/>}/> : null}
    { authInfo?.user?.role === 'manager' ?<Route path="/employees" element={<Employees/>}/> : null}
    { authInfo?.user?.role === 'support' ? null :<Route path="enqueries" element={<Enqueries/>}>
      <Route path='pending' element={<Pending/>}/>
      <Route path="mapping" element={<Mapping/>}/>
      <Route path="demo" element={<Demo/>}/>
      <Route path="tuition" element={<Tuition/>}/>
      <Route path="all" element={<AllEnquiry/>}/>
    </Route>}
    { authInfo?.user?.role === 'manager' ? <Route path="settings" element={<Settings/>}>
      <Route index path="categories" element={<Categories/>}/>
      <Route path="syllabus" element={<Syllabus/>}/>
      <Route path="cities" element={<Cities/>}/>
      <Route path="profession" element={<Profession/>}/>
      <Route path="source" element={<Source/>}/>
    </Route> : null}

    { authInfo?.user?.role === 'manager' ? <Route path="analytics" element={<Analytics/>}>
      <Route index path="enquiry" element={<EnquiryAnalytics/>}/>
      <Route path="tuition" element={<TuitionAnalytics/>}/>
      <Route path="demo" element={<DemoAnalytics/>}/>
      <Route path="client" element={<ClientAnalytics/>}/>
      <Route path="tutor" element={<Source/>}/>
    </Route> : null}

    <Route path="tutors" element={authInfo && authInfo?.user && authInfo?.user?.role === 'manager' ? <Tutors /> : <TutorSecondary/>}/>
    </Route>
  </Routes></> : <Loader/>
  )
}
