import React, { useEffect, useState } from 'react'
import { excludeTutors, getMappedTutors,getExcludedTutors, updateTutor as updateTutorService, includeTutors, sendBroadcast, resetBroadcast } from '../../../components/services'
import { DataGrid,GridActionsCellItem } from '@mui/x-data-grid';
import StarFilled from '@mui/icons-material/Star';
import Star from '@mui/icons-material/StarBorder';
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";
import { TOAST_CONFIG } from '../../../config/constants';
import ExcludeTutorModal from './ExcludeTutorModal';
import { authAxios } from '../../../helpers/axios';
import InfoIcon from '../../../assets/images/info.png'
import WhatsappIcon from '../../../assets/images/whatsapp.svg'
import TutorInfoPopup from './TutorInfoPopup';
import UpdateTutorModal from '../../tutors/UpdateTutorModal';
import IncludeTutorModal from './IncludeTutorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope,faBan } from '@fortawesome/free-solid-svg-icons'
import BroadcastModal from './BroadcastModal';
import ResetModal from './ResetModal';

export default function MappingList({selectedItem,setAssignDemo,setExcludeTutor,excludeTutor,setIncludeTutor,includeTutor,setFilteredRows,setenquiryData,enquiryData,filteredRows}) {

    const [tutors,setTutors] = useState([])
    const [loading,setLoading] = useState(false)
    const [updating,setUpdating] = useState(false)
    const [searchText,setSearchText] = useState('')
    const [listType,setListType] = useState('mapped')
    const [range,setRange] = useState(3)
    const [classType,setClassType] = useState([{value:'offline',label:'Offline'},{value:'both',label:'Both'}])
    const [filteredTutors,setFilteredTutors] = useState([])
    const [updateTutor,setUpdateTutor] = useState(false)
    const [broadcasting,setBroadcasting] = useState(false)
    const [broadcastModal,setBroadcastModal] = useState(false)
    const [resetModal,setResetModal] = useState(false)
    const [resetting,setResetting] = useState(false)

    const [detailedTutor,setDetailedTutor] = useState(null)

    const classTypeOptions = [{value:'offline',label:'Offline'},{value:'online',label:'Online'},{value:'both',label:'Both'}]
    useEffect(()=>
    {
      (async function(){
        try 
        {
          console.log('sel',selectedItem)
          setFilteredTutors([])
          setTutors([])
          setUpdating(true)
          let localClassType = []
        classType.map(item=>localClassType.push(item.value))
          let res = await getMappedTutors(selectedItem._id,range,localClassType.toString())
          setUpdating(false)
          setFilteredTutors(res)
          setTutors(res)
        } catch (error) {
          console.log(error)
          setUpdating(false)
            return false
        }
      })()
    },[selectedItem])


    const getSubjects = (subject)=>
    {
        return subject.map(function (e) {
            return e;
          }).join(', ');
    }
    
    const getMessage = (gender,firstName,lastName,standard,syllabus,subjects,locality)=>
    {
      console.log(gender,firstName,lastName,standard,syllabus,subjects,locality)
      const genderText = encodeURI(gender === 'male' ? 'Mr.' : 'Ms.')
      return `${genderText}${encodeURI(`${firstName} ${lastName}`)}%2C%0A%0AAre%20you%20available%20for%20*Home-Tuition*%20for%20${encodeURI(standard)}%20-%20${encodeURI(syllabus)}%20-%20${encodeURI(getSubjects(subjects))}%20at%20*${encodeURI(locality)}*%3F%0A%0AWe%20tried%20to%20reach%20you%2C%20Please%20call%20back%20soon.%0A%0A*ProTutor.in*`
    }



    function escapeRegExp(value) {
      return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
  
    const requestSearch = (searchValue) => {
      setSearchText(searchValue);
      console.log(tutors)
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = tutors.filter((row) => {
        return Object.keys(row).some((field) => {
          if(field === 'mobile' || field === 'firstName')
          {
            return searchRegex.test(row['alternateMobile'].toString()) || searchRegex.test(row['mobile'].toString()) || searchRegex.test(row['firstName'].toString()) ;
          }
        });
      });
      setFilteredTutors(filteredRows);
    };


    const _getExcludedTutors = async()=>
    {
      try 
      {
        setTutors([])
        setFilteredTutors([])
        setUpdating(true)
        let data = await getExcludedTutors(selectedItem._id)
        setTutors(data)
        setFilteredTutors(data)
        setUpdating(false)
        
      } catch (error) {
        setUpdating(false)
        
      }
    }

    const _getMappedTutors = async()=>
    {
      try 
      {
        setTutors([])
          setFilteredTutors([])
        setUpdating(true)
        let localClassType = []
        classType.map(item=>localClassType.push(item.value))
          let res = await getMappedTutors(selectedItem._id,range,localClassType.toString())
          setTutors(res)
          setFilteredTutors(res)
          setUpdating(false)
      } catch (error) {
        setUpdating(false)
          console.log(error)
          return false
      }
    }

    const _excludeTutor = async(data)=>
    {
      try 
      {
        setUpdating(true)
        await excludeTutors({tutor:excludeTutor._id,enquiry:selectedItem._id})
        setTutors(prev=>prev.filter(i=>i._id !== excludeTutor._id))
        setFilteredTutors(prev=>prev.filter(i=>i._id !== excludeTutor._id))
        setExcludeTutor(false)
        setUpdating(false)
      } catch (error) {
        console.log(error)
        setUpdating(false)
        toast.error(error.response.data.message ? error.response.data.message  : error,TOAST_CONFIG)
        return false
      }
    }

    const _includeTutor = async(data)=>
    {
      try 
      {
        setUpdating(true)
        await includeTutors({tutor:includeTutor._id,enquiry:selectedItem._id})
        setTutors(prev=>prev.filter(i=>i._id !== includeTutor._id))
        setFilteredTutors(prev=>prev.filter(i=>i._id !== includeTutor._id))
        setIncludeTutor(false)
        setUpdating(false)
      } catch (error) {
        console.log(error)
        setUpdating(false)
        toast.error(error.response.data.message ? error.response.data.message  : error,TOAST_CONFIG)
        return false
      }
    }


    const _sendBroadcast = async()=>
    {
      try 
      {
        // setTutors([])
        setBroadcastModal(false)
        // setFilteredTutors([])
        setUpdating(true)
        setBroadcasting(true)
        let data = await sendBroadcast(selectedItem._id)
        let itemIndex = enquiryData.findIndex(item=>item._id === selectedItem._id)
        setenquiryData((prev)=>
              {
                let newData = [...prev]
                newData[itemIndex].broadcastCount = data.broadcastCount
                return newData
              })
        await _getMappedTutors()
        setUpdating(false)
        setBroadcasting(false)
        
      } catch (error) {
        toast.error(error.response.data.message ? error.response.data.message  : error,TOAST_CONFIG)
        setUpdating(false)
        
      }
    }


    const _resetBroadcast = async()=>
    {
      try 
      {
        // setTutors([])
        setResetModal(false)
        // setFilteredTutors([])
        setUpdating(true)
        setResetting(true)
        let data = await resetBroadcast(selectedItem._id)
        let itemIndex = enquiryData.findIndex(item=>item._id === selectedItem._id)
        setenquiryData((prev)=>
              {
                let newData = [...prev]
                newData[itemIndex].broadcastCount = data.broadcastCount
                return newData
              })
        await _getMappedTutors()
        setUpdating(false)
        setResetting(false)
        
      } catch (error) {
        toast.error(error.response.data.message ? error.response.data.message  : error,TOAST_CONFIG)
        setUpdating(false)
        
      }
    }

    const columns =[
      {
        field: 'priority',
        type: 'actions',
        width: 30,
        getActions: (params) => [
          <GridActionsCellItem
            icon={ selectedItem.priorityTutors?.includes(params.row._id) ? <StarFilled className='star'/> : <Star />}
            label="View"
            showInMenu={false}
            onClick={async()=>
              {
                // if(params.row.priorityTutors)
                let res = await authAxios.post('/enquiry/tutor/priority',{id:selectedItem._id,tutorId:params.row._id})
                  console.log('response',res.data)
                // setEnquiryDetailModal(params.row?._id)
                let itemIndex = enquiryData.findIndex(item=>item._id === selectedItem._id)
              setenquiryData((prev)=>
              {
                let newData = [...prev]
                newData[itemIndex].priorityTutors = res.data.priorityTutors
                return newData
              })
            }}
          />
        ],
      },
      {field:'msg',headerName:'Resp.',flex:.5,renderCell:(params)=>
        {
          return <div>
            <p className='xl-text f-op m-weight caps c-align'>{params.row?.broadcast ? <FontAwesomeIcon icon={params.row?.broadcast?.msgType === 'wap'? faWhatsappSquare : faEnvelope} color={params.row?.broadcast?.response === 0 ? '#959595' : params.row?.broadcast?.response === 1 ? "#446aff" : '#ff0000'} size={40}/> : <FontAwesomeIcon icon={faBan} color={'#959595'} size={40}/>}</p>
          </div>
        }},
        {field:'firstName',headerName:'Name/Mobile',flex:1,renderCell:(params)=>
        {
          return <div className='tooltip-parent'>
            <p className='xxs-text f-op m-weight caps'>{params.row?.firstName} {params.row?.lastName}<span className='tooltip-trigger' onClick={()=>setDetailedTutor(params.row)}><img src={InfoIcon} className="tooltip-trigger-img"/></span></p>
            <p className='xxxs-text q-op r-weight'>{params.row?.primaryMobile === 'primary' ? params.row?.mobile : params.row?.alternateMobile} <a target={'_blank'} href={`https://api.whatsapp.com/send?phone=91${params.row?.primaryMobile === 'primary' ? params.row?.mobile : params.row?.alternateMobile}&text=${getMessage(params.row.gender,params.row.firstName,params.row.lastName,selectedItem.standard ? `Class ${selectedItem.standard}` : selectedItem.studentCategory,selectedItem.studentSyllabus,selectedItem.studentSubjects,selectedItem.locality)}`}><img src={WhatsappIcon} className="whatsapp-icon"/></a></p>
          </div>
        }},
        {field:'distance',headerName:'Locality/Distance',flex:1,renderCell:(params)=>
        {
          return <div>
            <p className='xxs-text f-op m-weight caps'>{params.row?.locality}</p>
            <p className='xxxs-text q-op r-weight'>{params.row?.distance} Km</p>
          </div>
        }},
        {field:'qualification',headerName:'Prof./Qual.',flex:1,renderCell:(params)=>
        {
          return <div>
            <p className='xxs-text f-op m-weight caps'>{params.row?.qualification}</p>
            <p className='xxxs-text q-op r-weight caps'>{params.row?.profession[0]?.name} ({params.row?.experience} Yrs)</p>
          </div>
        }},
        {field:'demoCompleted',headerName:'Demo/Class',flex:1,renderCell:(params)=>
        {
          return <div>
            {/* <p className='xxs-text f-op m-weight caps'>{params.row?.qualification}</p> */}
            <p className='xxxs-text q-op r-weight caps'>{params.row?.demoInitiated ? params.row?.demoInitiated : 0} / {params.row?.demoCompleted ? params.row?.demoCompleted : 0}</p>
          </div>
        }},
        // {field:'distance',headerName:'Distance',flex:1,renderCell:(params)=> `${params.row.distance} Km`},
        {field:'matches',headerName:'Matched'},
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
            <GridActionsCellItem
              label="Assign Demo"
              showInMenu={true}
              onClick={()=>
                {
                  let item = tutors.filter((i,index)=>{
                    console.log(params)
                    return i._id === params.id
                  })
                  console.log('item',item)
                  setAssignDemo(item[0])
              }}
            />,
            <GridActionsCellItem
              label="Exclude Tutor"
              showInMenu={true}
              onClick={()=>
                {
                  let item = tutors.filter((i,index)=>{
                    console.log(i)
                    return i._id === params.id
                  })
                  console.log('item',item)
                  setExcludeTutor(item[0])
              }}
            />
          ],
        },
      ]



      const excludedColumns =[
          {field:'firstName',headerName:'Name/Mobile',flex:1,renderCell:(params)=>
          {
            return <div className='tooltip-parent'>
              <p className='xxs-text f-op m-weight caps'>{params.row?.firstName} {params.row?.lastName}<span className='tooltip-trigger' onClick={()=>setDetailedTutor(params.row)}><img src={InfoIcon} className="tooltip-trigger-img"/></span></p>
              <p className='xxxs-text q-op r-weight'>{params.row?.primaryMobile === 'primary' ? params.row?.mobile : params.row?.alternateMobile} <a target={'_blank'} href={`https://api.whatsapp.com/send?phone=91${params.row?.primaryMobile === 'primary' ? params.row?.mobile : params.row?.alternateMobile}&text=''`}><img src={WhatsappIcon} className="whatsapp-icon"/></a></p>
            </div>
          }},
          {field:'distance',headerName:'Locality/Distance',flex:1,renderCell:(params)=>
          {
            return <div>
              <p className='xxs-text f-op m-weight caps'>{params.row?.locality}</p>
              <p className='xxxs-text q-op r-weight'>{params.row?.distance} Km</p>
            </div>
          }},
          {field:'qualification',headerName:'Prof./Qual.',flex:1,renderCell:(params)=>
          {
            return <div>
              <p className='xxs-text f-op m-weight caps'>{params.row?.qualification}</p>
              <p className='xxxs-text q-op r-weight caps'>{params.row?.profession[0]?.name} ({params.row?.experience} Yrs)</p>
            </div>
          }},
          {field:'demoCompleted',headerName:'Demo/Class',flex:1,renderCell:(params)=>
          {
            return <div>
              {/* <p className='xxs-text f-op m-weight caps'>{params.row?.qualification}</p> */}
              <p className='xxxs-text q-op r-weight caps'>{params.row?.demoInitiated ? params.row?.demoInitiated : 0} / {params.row?.demoCompleted ? params.row?.demoCompleted : 0}</p>
            </div>
          }},
          // {field:'distance',headerName:'Distance',flex:1,renderCell:(params)=> `${params.row.distance} Km`},
          {field:'matches',headerName:'Matched'},
          {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                label="Include Tutor"
                showInMenu={true}
                onClick={()=>
                  {
                    let item = tutors.filter((i,index)=>{
                      console.log(i)
                      return i._id === params.id
                    })
                    console.log('item',item)
                    setIncludeTutor(item[0])
                }}
              />
            ],
          },
        ]



  useEffect(()=>
  {

    async function _getData()
    {
      if(listType === 'excluded')
      {
        setListType('excluded')
        await _getExcludedTutors()
      }
      else if(listType === 'mapped')
      {
        setListType('mapped')
        await _getMappedTutors()
      }
      else 
      {
        setListType('all')
        setFilteredTutors([]);
      }
    }

    _getData()

  },[listType,range,classType])


  const fetchData = async(e) => {
    setUpdating(true)
    setSearchText(e.target.value)
    if(searchText.length >= 3)
    {
      let res = await authAxios.get(`/tutor/search?tutor=${searchText}&status=active`)
      setTutors(res.data)
      setFilteredTutors(res.data)
      setUpdating(false)
      return res.data
    }
  }

  const _onClassTypeChange = (e)=>
  {
      console.log('e',e)
      try 
      {
        // let value = Array.from(e, option => option._id);
        // // let value = e.map(({ foo }) => foo)
        // console.log('values',value)
        setClassType(e);   
      } catch (error) {
          console.log(error)
      }
  }

  const _updateTutorNote = async(data)=>
  {
      let res = await updateTutorService({_id:data.id,note:data.note})
      let index = tutors.findIndex(item=>item._id === data.id)
      console.log('ressss',res)
       setTutors((prev)=>
       {
         let newData = [...prev]
         newData[index] = res
         return newData
       })
  }

  const _setUpdateTutorData = (data)=>
{
    // setSelectedItem(null)
    console.log('receved',data)
    let filteredIndex = filteredTutors.findIndex(item=>item._id === data._id)
      let unfilteredIndex = tutors.findIndex(item=>item._id === data._id)
       setFilteredTutors((prev)=>
       {
         let newData = [...prev]
         newData[filteredIndex] = data
         return newData
       })
       setTutors((prev)=>
       {
         let newData = [...prev]
         newData[unfilteredIndex] = data
         return newData
       })
  }

  return (
    !loading ? <> 
    {excludeTutor ? <ExcludeTutorModal onClick={_excludeTutor} tutorId={excludeTutor._id} enquiryId={selectedItem._id} setExcludeTutor={setExcludeTutor}/> : null}

    {includeTutor ? <IncludeTutorModal onClick={_includeTutor} tutorId={includeTutor._id} enquiryId={selectedItem._id} setIncludeTutor={setIncludeTutor}/> : null}

    {detailedTutor ? <TutorInfoPopup updateNote={_updateTutorNote} info={filteredTutors.find(item=>item._id === detailedTutor._id)} setModal={setDetailedTutor} setUpdateTutor={setUpdateTutor}/> : null}

    {updateTutor ? <UpdateTutorModal setModal={setUpdateTutor} mapping={false} updateData={updateTutor} _updateTutors={_setUpdateTutorData}/> : null}

    {broadcastModal ? <BroadcastModal setModal={setBroadcastModal} onClick={_sendBroadcast}/> : null}

    {resetModal ? <ResetModal setModal={setResetModal} onClick={_resetBroadcast}/> : null}

    <div className="mapping-list-container">
        <div className="mapping-list-content">
            <div className="mapping-list-header">
                <div>
                    { listType !== 'all' ? <input className="search-input" type="text" disabled={updating} onChange={e=>requestSearch(e.target.value)} placeholder={listType === 'mapped' ? 'Search Mapped Tutor' : 'Search Excluded Tutor'}/> :
                    <input type={'text'} className="search-input" placeholder='Search Tutor' onChange={fetchData} value={searchText}/>
                  }
                </div>
                {selectedItem.broadcastCount < 3  ? <div>
                  <button className='xs-btn' onClick={()=>setBroadcastModal(true)}>Broadcast-{selectedItem.broadcastCount+1}</button>
                </div> : null}
                {selectedItem.broadcastCount > 0 && selectedItem.broadcastCount <= 3  ? <div>
                  <button className='xs-btn' onClick={()=>setResetModal(true)}>Reset</button>
                </div> : null}
                <div className='mapping-filter-flex'>
                  <MultiSelect className='mapping-multi-filter' disabled={updating} hasSelectAll={false} disableSearch={true} options={classTypeOptions} value={classType} onChange={_onClassTypeChange} labelledBy="Select"/>
                  
                  {listType === 'mapped' ? <div>
                  <select className="search-input" value={range} disabled={updating} onChange={(e)=>setRange(e.target.value)}>
                          <option value={3}>3 Km</option>
                          <option value={5}>5 Km</option>
                          <option value={7}>7 Km</option>
                          <option value={10}>10 Km</option>
                      </select>
                  </div> : null}
                  <div className="pending-info-btn-container">
                      <select className="search-input" disabled={updating} onChange={(e)=>setListType(e.target.value)}>
                          <option value={'mapped'}>Mapped Tutors</option>
                          <option value={'all'}>All Tutors</option>
                          <option value={'excluded'}>Excluded Tutors</option>
                      </select>
                  </div>
                </div>
            </div>
            <div className="pending-inner-content">
            <div style={{height:'100%',width:'100%'}}>
                <DataGrid checkboxSelection={false} loading={updating} getRowId={row=>row._id}  disableColumnSelector={true} columns={listType != 'excluded' ? columns : excludedColumns} rows={filteredTutors} disableSelectionOnClick={true} style={{border:0}}/>
            </div>
            </div>
        </div>
    </div>
    </> : 'Loading'
  )
}
