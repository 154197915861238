import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createSource, updateSource } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'

export default function SourceModal({setModal,setData,updateData}) {
    console.log(updateData)
    const [name,setName] = useState(updateData ? updateData.name : '')
    const _onSubmit = async(e)=>
    {
        e.preventDefault();
        try 
        {
            if(updateData)
            {
                let res = await updateSource(name,updateData._id)
                setData(prev=>{
                    let newData = [...prev]
                    let index = newData.findIndex(item=>item._id === updateData._id)
                    newData[index] = {...res}
                    console.log('newdara',newData)
                    return newData;  
                })
                setModal(false)
            }
            else
            {   
                let res = await createSource(name)
                setData(prev=>([...prev,res]))
                setModal(false)
            }

        } catch (error) {
            toast.error(error,TOAST_CONFIG)
        }
    }
  return (
    <Modal title={updateData ? 'Update Source' : 'Add Source'} size="md">
        <form onSubmit={_onSubmit}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Enter Source Name</label>
                <input type="text" className="text-input" required value={name} onChange={(e)=>setName(e.target.value)}/>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setModal(false)}/>
            <input type="submit" className="xs-delete-btn" value={updateData ? 'Update Source' : 'Add Source'}/>
        </div>
        </form>
    </Modal>
  )
}
