import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import React, { useEffect,useState,useCallback } from 'react'
import { getCity,getState } from '../../components/services';
import {useNavigate} from 'react-router-dom'
import ShowCategoryInfo from './components/ShowCategoryInfo';
import AddCategoryModal from './components/AddCategoryModal';
import AddSyllabusModal from './components/AddSyllabusModal';
import AddSubjectModal from './components/AddSubjectModal';
import AddCityModal from './components/AddCityModal';
import AddStateModal from './components/AddStateModal';
import EditCityModal from './components/EditCityModal';
import EditStateModal from './components/EditStateModal';

export default function Cities() {

    const [cities,setCities] = useState([])
    const [addCitiesModal,setAddCitiesModal] = useState(false)
    const [states,setStates] = useState([])
    const [addStateModal,setAddStateModal] = useState(false)
    const [editCity,setEditCity] = useState(false)
    const [editStateModal,setEditStateModal] = useState(false)
    const getData = useCallback(async ()=>{
        const cityData = await getCity();
        const stateData = await getState();
        setCities(cityData);
        setStates(stateData)
    },[])
    useEffect(()=>
    {
        getData()
    },[getData])



    const citycolumns = [
        {field:"cityName",headerName:"City Name",flex:1,renderCell: (params)=>{
          return <div>
            <p className='xs-text f-op'>{params.row.cityName} <span className='xxs-text q-op b-weight uppercase'>({params.row.cityCode})</span> <a className='xxxs-text' target="_blank" href={`https://maps.google.com/?q=${params.row.geo?.lat},${params.row.geo?.lng}`}>Map</a></p>
            </div>
        }},
        {field:"stateName",headerName:"State Name",flex:1,valueGetter: (params) => params.row?.cityState?.name},
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
        <GridActionsCellItem
              label="Edit"
              showInMenu={true}
              onClick={()=>
                {
                  let item = cities.filter((i,index)=>{
                    console.log(i._id === params.id,i,params.id)
                    return i._id === params.id
                  })
                  console.log('selected',item)
                  setEditCity(item[0])
              }}
            />
    ]}]

    const columns = [
        {field:"name",headerName:"Name",flex:1},
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
        <GridActionsCellItem
              label="Edit"
              showInMenu={true}
              onClick={()=>
                {
                  let item = states.filter((i,index)=>{
                    console.log(i._id === params.id,i,params.id)
                    return i._id === params.id
                  })
                  console.log('selected',item)
                  setEditStateModal(item[0])
              }}
            />
    ]}
    ]
  return (
    <>
    { addCitiesModal ? <AddCityModal setModal={setAddCitiesModal} setCities={setCities} states={states}/> : null}
    { editCity ? <EditCityModal setModal={setEditCity} setCities={setCities} states={states} data={editCity}/> : null}
    { addStateModal ? <AddStateModal setModal={setAddStateModal} setStates={setStates}/> : null}
    { editStateModal ? <EditStateModal setModal={setEditStateModal} setStates={setStates} data={editStateModal}/> : null}
    <div className="split-contained-container">
        <div className="split-left-content">
        <div class="list-content-header">
            <div>
            <h2 className="lg-text b-weight">Cities</h2>
            </div>
            <div class="d-flex">
            <button type="button" class="xs-compact-inverted-btn" onClick={()=>setAddCitiesModal(true)}>Add City</button>
            </div>
        </div>
        <div style={{height:'500px',width:'100%'}}>
        <DataGrid sx={{border:0}} disableColumnSelector={true} columns={citycolumns} rows={cities} getRowId={item=>item._id} disableSelectionOnClick={true}/>
        </div>
      </div>
      <div className="split-left-content">
        <div class="list-content-header">
            <div>
            <h2 className="lg-text b-weight">States</h2>
            </div>
            <div class="d-flex">
            <button type="button" class="xs-compact-inverted-btn" onClick={()=>setAddStateModal(true)}>Add State</button>
            </div>
        </div>
        <div style={{height:'500px',width:'100%'}}>
        <DataGrid sx={{border:0}} disableColumnSelector={true} columns={columns} rows={states} getRowId={item=>item._id} disableSelectionOnClick={true}/>
        </div>
      </div>
    </div>
    </>
  )
}
