import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createCity } from '../../../components/services'
import AutocompleteInput from '../../../components/AutocompleteInput'
import { TOAST_CONFIG } from '../../../config/constants'
import CityAutocomplete from '../../../components/CityAutocomplete'
import { authAxios } from '../../../helpers/axios'

export default function EditCityModal({setModal,setCities,states,data:externalData}) {
    const [data,setData] = useState({_id:externalData._id,cityName:externalData.cityName,cityState:externalData.cityState._id,geo:externalData.geo,cityCode:externalData.cityCode ? externalData.cityCode : ''})
    const [save,setSave] = useState(false)

    useEffect(()=>
    {
        console.log('running',data.cityState,externalData.cityState)
        if(externalData.cityName !== data.cityName ) 
        {
            setSave(true)
            setData(prev=>({...prev,geo:{lat:null,lng:null}}))
        }
    },[data.cityName])

    useEffect(()=>
    {
        console.log('running',data.cityState,externalData.cityState)
        if(externalData.cityState._id !== data.cityState || externalData.cityCode !== data.cityCode) 
        {
            setSave(true)
        }
    },[data.cityState,data.cityCode])
    const _editCity = async(e)=>
    {
        e.preventDefault();
        try 
        {
            if(data.geo.lat && data.geo.lng)
            {   
                let res = await authAxios.put('/settings/city',{_id:data._id,cityName:data.cityName,cityState:data.cityState,geo:data.geo,cityCode:data.cityCode})
                setCities(prev=>{
                    let newItem = [...prev];
                    return newItem.filter(i=>i._id === data._id ? res.data : i )
                }
                )
                toast.info('City Added',TOAST_CONFIG)
                setModal(false)
            }
            else 
            {
                toast.error('Select a valid city',TOAST_CONFIG)
            }

        } catch (error) {
            console.log(error)
            if(error.response)
            {
                toast.error(error.response.data.msg,TOAST_CONFIG)
            }
            else toast.error('Please verify if already exist ',TOAST_CONFIG)
        }
    }
  return (
    <Modal title="Edit City" size="md">
        <form onSubmit={_editCity}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Select State</label>
                <select className="text-input" defaultChecked={data.cityState} value={data.cityState} onChange={(e)=>setData(prev=>({...prev,cityState:e.target.value}))}>
                    {
                        states.map((item,index)=>
                        {
                            return <option key={index} value={item._id}>{item.name}</option>
                        })
                    }
                </select>
            </div>

            <div className="input-group">
                <label>Select City</label>
                <CityAutocomplete setData={setData} cityName={data.cityName}/>
            </div>

            <div className="input-group">
                <label>Enter City Code (Must be 3 Chars)</label>
                <input type={'text'} value={data.cityCode} className="text-input" onChange={(e)=>setData(prev=>({...prev,cityCode:e.target.value}))}/>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setModal(false)}/>
            <input type="submit" className="xs-delete-btn" disabled={!save} value="Save City"/>
        </div>
        </form>
    </Modal>
  )
}
