import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { createEmployees, getCity } from "../../components/services";

function Addemployee(props) {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    status: true,
    role: 'manager',
    cities: [],
  });
  const [citiesData, setcitiesData] = useState([]);
  useEffect(async () => {
    const newCityData = await getCity();
    console.log('city data',newCityData)
    let optionData = [];
    newCityData.map((item,index)=>{
        optionData.push({value:item.cityName,label:item.cityName})
        return true;
      })
      console.log('option data',optionData)
    setcitiesData(optionData);
  }, []);


  const onFinish = (values) => {
    createEmployees(values)
      .then((res) => {
        alert(res?.msg[0]);
        props?.refetch();
        props?.closeModal();
      })
      .catch((err) => {
        alert("Some error occured , please try again");
      });
    console.log("values", values);
  };

  return (
    <div>
      <div>
        <form onSubmit={onFinish}>
          <div>
                <div className="input-group-divided">
                    <div>
                        <label>First Name</label>
                        <input name="firstName" className="text-input" required value={data.firstName} onChange={(e)=>setData(prev=>({...prev,firstName:e.target.value}))}/>
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input name="lastName"className="text-input" value={data.lastName} onChange={(e)=>setData(prev=>({...prev,lastName:e.target.value}))}/>
                    </div>
                </div>

                <div className="input-group-divided">
                    <div>
                        <label>Email</label>
                        <input name="email" className="text-input" value={data.email} onChange={(e)=>setData(prev=>({...prev,email:e.target.value}))}/>
                    </div>
                    <div>
                        <label>Mobile</label>
                        <input name="mobile" className="text-input" required value={data.mobile} onChange={(e)=>setData(prev=>({...prev,mobile:e.target.value}))}/>
                    </div>
                </div>

                <div className="input-group-divided">
                    <div>
                        <label>Status</label>
                        <select name="status" className="text-input"value={data.status} onClick={(e)=>setData(prev=>({...prev,email:e.target.value}))}>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                        </select>
                    </div>
                    <div>
                        <label>Role</label>
                        <select name="status" className="text-input" value={data.role} onClick={(e)=>setData(prev=>({...prev,email:e.target.value}))}>
                        <option value="manager">Manager</option>
                        <option value="coordinator">Coordinator</option>
                        <option value="support">Support</option>
                        </select>
                    </div>
                </div>

                <div className="input-group">
                    <label>Cities Allotted</label>
                    <Select isMulti={true} options={citiesData} className="multi-select"/>
                </div>
          </div>
          <div className="submit-input-group">
              <input type="submit" className="xs-delete-btn" value="Add Employee"/>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Addemployee;
