import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createCategory } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'
import { authAxios } from '../../../helpers/axios'

export default function AddCategorySyllabusModal({setModal,onSubmit,item}) {
    const [name,setName] = useState('')
    const [syllabus,setSyllabus] = useState([])
    const [syllabusId,setSyllabusId] = useState('')
    const [loading,setLoading] = useState(true)

    useEffect(()=>
    {
        async function getSyllabus()
        {
            let res = await authAxios.get('/settings/syllabus')
            let existingSyllabus = []
            let allowedSyllabus = []
            item.data.map(i=>
                {
                    existingSyllabus.push(i.syllabus._id)  
                })
                console.log('existing',existingSyllabus)
                console.log('data',res.data)
            res.data.map(i=>
            {
                if(existingSyllabus.indexOf(i._id) === -1) allowedSyllabus.push(i)
            })
            console.log('allowed',allowedSyllabus)
            setSyllabus(allowedSyllabus)
            setLoading(false)
        }
        getSyllabus()
    },[])
  return (
    <Modal title="Add Syllabus" size="md">
        <form onSubmit={(e)=>onSubmit(e,syllabusId,name)}>
        <div className="modal-inner-content">
        <div className="input-group">
                <label>Select Category</label>
                <p className='r-text r-weight f-op'>{item.name}</p>
            </div>

            <div className="input-group">
                <label>Select Syllabus</label>
                <select required defaultChecked="" onChange={(e)=>setSyllabusId(e.target.value)}>
                    <option value={''} defaultChecked></option>
                    {
                        syllabus.map((item,index)=>{
                            return <option value={item._id}>{item.name}</option>
                        })
                    }
                </select>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setModal(false)}/>
            <input type="submit" className="xs-delete-btn" value="Add Syllabus"/>
        </div>
        </form>
    </Modal>
  )
}
