import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createCategory, editCategory } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'

export default function EditCategoryModal({setModal,setCategories,updateData}) {
    const [name,setName] = useState(updateData && updateData.name ? updateData.name : '')
    const _editCategory = async(e)=>
    {
        e.preventDefault();
        try
        {
            let res = await editCategory({name,_id:updateData._id})
            console.log('received data',res)
            let categoryData = res
            // categoryData.data.map((i) => {
                let subdata = categoryData.data;
                let subjectCount = 0;
                subdata.map((j) => {
                subjectCount += j.subjects.length;
                });
                categoryData["subjectCount"] = subjectCount;
                categoryData["syllabusCount"] = categoryData.data.length ? categoryData.data.length : 0;
            // });
            setCategories(prev=>
                {
                    let newData = [...prev]
                    let index = newData.findIndex(i=>i._id === updateData._id)
                    newData[index] = categoryData
                    return newData
                })
            setModal(false)

        } catch (error) {
            console.log(error)
            toast.error(error,TOAST_CONFIG)
        }
    }
  return (
    <Modal title="Edit Category" size="md">
        <form onSubmit={_editCategory}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Enter Category Name</label>
                <input type="text" className="text-input" value={name} onChange={(e)=>setName(e.target.value)}/>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setModal(false)}/>
            <input type="submit" className="xs-delete-btn" value="Create Category"/>
        </div>
        </form>
    </Modal>
  )
}
