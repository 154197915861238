export const FACEBOOK = 'Facebook';
export const GOOGLE = 'Google';
// // export const BASE_URL = 'http://3.108.220.170/api/v1'
// // export const STATIC_URL = 'http://3.108.220.170/'
// export const BASE_URL = 'http://localhost:3030/v1'
// export const STATIC_URL = 'http://localhost:3030/'
// // export const BASE_URL = 'http://insider.protutor.in/api/v1'
// // export const STATIC_URL = 'http://insider.protutor.in/api/'
export const TOAST_CONFIG = {
    position: "bottom-right",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    }

export const TUITION_STATUS =
[
    {status:0,statusName:'Select Status',disabled:true},
    {status:1,statusName:'Ongoing',disabled:false},
    {status:2,statusName:'Requesting Payment Link',disabled:false},
    {status:3,statusName:'Created Payment Link',disabled:false},
    {status:4,statusName:'Payment Link Sent',disabled:false},
    {status:5,statusName:'Paid',disabled:false},
    {status:10,statusName:'Dispute',disabled:false},
]