import React, { useState, useEffect } from "react";
import { createCustomer, getState, getCity, updateCustomer, getSources } from "../../components/services";
import Modal from "../../components/Modal";
import AutocompleteInput from "../../components/AutocompleteInput";
import { toast } from "react-toastify";
import { TOAST_CONFIG } from "../../config/constants"
function Addclient({setModal,updateData,setSelectedClient}) {
  const [data, setData] = useState({
    firstName: updateData && updateData.firstName ? updateData.firstName : '',
    lastName: updateData && updateData.lastName ? updateData.lastName : '',
    email: updateData && updateData.email ? updateData.email : '',
    mobile: updateData && updateData.mobile ? updateData.mobile : '',
    alternateMobile: updateData && updateData.alternateMobile ? updateData.alternateMobile : '',
    city: updateData && updateData.city._id ? updateData.city._id : '',
    address: updateData && updateData.address ? updateData.address : '',
    locality: updateData && updateData.locality ? updateData.locality : '',
    landmark: updateData && updateData.landmark ? updateData.landmark : '',
    source: updateData && updateData.source._id ? updateData.source._id : '',
    sendMsg:updateData ? false : true
  });
  const [cityData, setcityData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [progress, setProgress] = useState(true);

  useEffect(async () => {
      const newcityData = await getCity();
      setcityData(newcityData);
      const newSourceData = await getSources();
      setSourceData(newSourceData);
      setProgress(false)
  }, []);

  const onFinish = async(e) => {
      setProgress(true)
      try 
      {
          e.preventDefault()
        if(updateData)
        {
            let res = await updateCustomer(data,updateData._id)
            setSelectedClient(prev=>({...prev,...res}))
            toast.info('Customer Updated',TOAST_CONFIG)
            setModal(false)
            setProgress(false)
        }
        else
        {
            let res = await createCustomer(data)
            setSelectedClient(prev=>({...prev,...res}))
            toast.info('Customer Created',TOAST_CONFIG)
            setModal(false)
            setProgress(false)
        }
      } catch (error) {
          toast.error(error.response.data.message,TOAST_CONFIG)
          setProgress(false)
      }
  };

  return (
    <Modal title={updateData ? 'Update Client' : 'Add Client'}>
        <form onSubmit={onFinish}>
            <div className="modal-inner-content">
                <div className="input-group-divided">
                    <div>
                        <label>Customer First Name*</label>
                        <input type="text" required
                        className="text-input"
                        value={data.firstName}
                        onChange={(e)=>setData(prev=>({...prev,firstName:e.target.value}))}
                        />
                    </div>

                    <div>
                        <label>Customer Last Name</label>
                        <input type="text"
                        className="text-input"
                        value={data.lastName}
                        onChange={(e)=>setData(prev=>({...prev,lastName:e.target.value}))}
                        />
                    </div>

                    <div>
                    <label className="inline-label">Customer Mobile*</label>
                    <input type="text"  pattern="[1-9]{1}[0-9]{9}" maxLength={10} required
                    className="text-input"
                    value={data.mobile}
                    onChange={(e)=>setData(prev=>({...prev,mobile:e.target.value}))}
                    />
                    </div>
                    
                    <div>
                    <label>Alternate Mobile</label>
                    <input type="text" pattern="[1-9]{1}[0-9]{9}" maxLength={10} className="text-input" value={data.alternateMobile} onChange={(e)=>setData(prev=>({...prev,alternateMobile:e.target.value}))}/>
                    </div>

                    <div>
                    <label>Customer Email</label>
                    <input type="email"
                    className="text-input"
                    value={data.email}
                    onChange={(e)=>setData(prev=>({...prev,email:e.target.value}))}
                    />
                    </div>

                    <div>
                        <label>City*</label>
                        <select classsName="text-input" required defaultChecked={data.city} value={data.city} onChange={(e)=>setData(prev=>({...prev,city:e.target.value}))}>
                            <option defaultChecked value=''>Select City</option>
                            {
                                cityData.map((item,index)=>
                                {
                                    return <option value={item._id}>{item.cityName}</option>
                                })
                            }
                        </select>
                    </div>

                    <div>
                        <label>Locality*</label>
                        <AutocompleteInput setData={setData} addressText={data.locality}/>
                    </div>

                    <div>
                    <label>Address</label>
                        <input type="text" className="text-input" value={data.address} onChange={(e)=>setData(prev=>({...prev,address:e.target.value}))}/>
                    </div>
                   
                    
                    <div>
                        <label>Landmark</label>
                        <input type="text" className="text-input" value={data.landmark} onChange={(e)=>setData(prev=>({...prev,landmark:e.target.value}))}/>
                    </div>

                    <div>
                    <label>Select Source*</label>
                    <select className="text-input" defaultChecked={data.source} required value={data.source}  onChange={(e)=>setData(prev=>({...prev,source:e.target.value}))}>
                        <option value=""></option>
                        {sourceData.map((item)=>
                        {
                            return <option key={item._id} value={item._id}>{item.name}</option>
                        })}
                    </select>
                </div>
                </div>
                </div>
            <div className="modal-footer modal-split-footer">
                <div>
                    <input type={'checkbox'} className="inline-radio" id="sendMsg" defaultChecked={data.sendMsg} onChange={()=>setData(prev=>({...prev,sendMsg:!data.sendMsg}))}/>
                    <label className="inline-label" htmlFor="sendMsg">Send Msg</label>
                </div>

                <div className="modal-right-footer">
                    <input type="button" className="xs-neutral-btn" onClick={()=>setModal(false)} value="Cancel"/>
                    <input type="submit" className="xs-delete-btn" disabled={progress} value={updateData ? 'Update' : 'Add Client'}/>
                </div>
            </div>
      </form>
    </Modal>
  );
}

export default Addclient;
