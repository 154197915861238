import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createState } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'

export default function AddStateModal({setModal,setStates}) {
    const [name,setName] = useState('')
    const _createState = async(e)=>
    {
        e.preventDefault();
        try 
        {
            let res = await createState(name)
            setStates(prev=>([...prev,res]))
            setModal(false)

        } catch (error) {
            toast.error(error,TOAST_CONFIG)
        }
    }
  return (
    <Modal title="Add State" size="md">
        <form onSubmit={_createState}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Enter State Name</label>
                <input type="text" className="text-input" required value={name} onChange={(e)=>setName(e.target.value)}/>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setModal(false)}/>
            <input type="submit" className="xs-delete-btn" value="Create State"/>
        </div>
        </form>
    </Modal>
  )
}
