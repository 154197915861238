import "./App.css";
import Login from "./pages/Login";
import { Routes, Route, Navigate } from "react-router-dom";
import Unprotected from "./components/Unprotected";
import Protected from "./components/Protected";
import HomeRoute from "./HomeRoute";

function App() {



  return <div className="body-container">
          <Routes>
            <Route path="/login" element={<Unprotected children={<Login/>}/>} />
            <Route path="dashboard/*" element={<Protected><HomeRoute /></Protected>} />
            {/* { authInfo?.user && !loading ? */}
            {/* <Route path="*" element={<Navigate to={!authInfo?.isAuthenticated ? '/login' : authInfo?.user?.role !== 'support' ? '/enqueries/pending' : '/tutors' } replace />}/> */}
            <Route path="*" element={<Navigate to={'/login'} replace />}/>
        </Routes>  
    </div>
}

export default App;


{/* <Route element={<Nav/>}>
{ authInfo?.user?.role === 'support' ? null : <Route index path="/client" element={<Clients />}/>}
{ authInfo?.user?.role === 'manager' ?<Route path="/employees" element={<Protected children={<Employees/>} user={authInfo.user}/>}/> : null}
{ authInfo?.user?.role === 'support' ? null :<Route path="/enqueries" element={<Protected user={authInfo.user} children={<Enqueries />}/>}>
  <Route element={<Pending/>}/>
  <Route path="/enqueries/pending" element={<Protected user={authInfo.user} children={<Pending/>}/>}/>
  <Route path="/enqueries/mapping" element={<Protected user={authInfo.user} children={<Mapping/>}/>}/>
  <Route path="/enqueries/demo" element={<Protected user={authInfo.user} children={<Demo/>}/>}/>
  <Route path="/enqueries/tuition" element={<Protected user={authInfo.user} children={<Tuition/>}/>}/>
  <Route path="/enqueries/all" element={<Protected user={authInfo.user} children={<AllEnquiry/>}/>}/>
</Route>}
{ authInfo?.user?.role === 'manager' ? <Route path="/settings" element={<Protected user={authInfo.user} children={<Settings/>}/>}>
  <Route index path="/settings/categories" element={<Protected user={authInfo.user} children={<Categories/>}/>}/>
  <Route path="/settings/syllabus" element={<Protected user={authInfo.user} children={<Syllabus/>}/>}/>
  <Route path="/settings/cities" element={<Protected user={authInfo.user} children={<Cities/>}/>}/>
  <Route path="/settings/profession" element={<Protected user={authInfo.user} children={<Profession/>}/>}/>
  <Route path="/settings/source" element={<Protected user={authInfo.user} children={<Source/>}/>}/>
</Route> : null}

{ authInfo?.user?.role === 'manager' ? <Route path="/analytics" element={<Protected user={authInfo.user} children={<Analytics/>}/>}>
  <Route index path="/analytics/enquiry" element={<Protected user={authInfo.user} children={<EnquiryAnalytics/>}/>}/>
  <Route path="/analytics/tuition" element={<Protected user={authInfo.user} children={<TuitionAnalytics/>}/>}/>
  <Route path="/analytics/demo" element={<Protected user={authInfo.user} children={<DemoAnalytics/>}/>}/>
  <Route path="/analytics/client" element={<Protected user={authInfo.user} children={<ClientAnalytics/>}/>}/>
  <Route path="/analytics/tutor" element={<Protected user={authInfo.user} children={<Source/>}/>}/>
</Route> : null}

<Route path="/tutors" element={authInfo && authInfo?.user && authInfo?.user?.role === 'manager' ? <Tutors /> : <TutorSecondary/>}/>
</Route>

<Route path="*" element={<Navigate to={!authInfo?.isAuthenticated ? '/login' : authInfo?.user?.role !== 'support' ? '/enqueries/pending' : '/tutors' } replace />}/> */}