import React,{useEffect, useState} from 'react'
import Async from 'react-select/async'
import AddClient from './clients/AddClient'
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../config/constants';
import { authAxios } from '../helpers/axios';
import { DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { getCustomer, getCustomerEnquiry } from '../components/services';
import EnquiryDetail from './clients/EnquiryDetail';
export default function Clients() {

  const [clients, setClients] = useState([]);
  const [inputValue, setValue] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [addClientModal,setAddClientModal] = useState(false)
  const [updateClientModal,setUpdateClientModal] = useState(false)
  const [enquiryDetailModal,setEnquiryDetailModal] = useState(false)
 
  const handleInputChange = value => {
    setValue(value);
  };
 
  const handleChange = async(value) => {
    let res = await getCustomer(value._id)
    setSelectedClient(res);
  }
 
  const fetchData = async() => {
    if(inputValue.length >= 4)
    {
      let res = await authAxios.get(`/customer/search?customer=${inputValue}`)
      setClients(res.data)
      return res.data
    }
  }

  const columns =[
    {field:'createdAt',headerName:'Date',flex:1,valueGetter:(params)=>
    {
      let d = new Date(params.row?.createdAt)
      return `${d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`}-${d.getMonth()+1}-${d.getFullYear('').toString().substr(-2)}`
    }},
    {field:'studentCategory',headerName:'Category',flex:1,valueGetter: (params) => params.row?.studentCategory?.name},
    {field:'studentCategory',headerName:'Category',flex:1,renderCell:(params)=>
    {
      return <div>
          <p className='xs-text r-weight f-op caps'>{params.row?.standard ? `class-${params.row?.standard}` : params.row?.studentCategory?.name}</p>
      </div>
    }},
    {field:'studentSyllabus',headerName:'Syllabus',flex:1,valueGetter: (params) => params.row?.studentSyllabus?.name},
    {field:'currentProcess',headerName:'Status',flex:1,renderCell:(params)=>
    {
      return <div><div><p className="xs-text r-weight">{params.row.currentProcess === 0 ? 'Pending' : params.row.currentProcess === 1 ? 'Approved' : params.row.currentProcess === 2 ? 'Demo' : 'Tuition'}</p></div></div>
    }},
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="View"
          onClick={()=>
            {
              setEnquiryDetailModal(params.row?._id)
              // let item = selectedClient.enquiries?.filter((i,index)=>{
              //   console.log(i)
              //   return i._id === params.id
              // })
              // console.log('item',item)
          }}
        />
      ],
    },
  ]

  return (
    <>
    {addClientModal ? <AddClient setModal={setAddClientModal} setSelectedClient={setSelectedClient}/> : null}
    {updateClientModal ? <AddClient setModal={setUpdateClientModal} updateData={selectedClient} setSelectedClient={setSelectedClient}/> : null}
    {enquiryDetailModal ? <EnquiryDetail setModal={setEnquiryDetailModal} id={enquiryDetailModal}/> : null}
    <div className="client-container">
      <div className="client-search-container">
        {/* <label>Search Client</label> */}
        <Async
            name="storageTypeId" placeholder="Search Name/Mobile"
            value={selectedClient?.firstName}
            getOptionLabel={e => e.firstName + ' ' + e.lastName}
            getOptionValue={e => e._id}
            loadOptions={fetchData}
            onInputChange={handleInputChange}
            onChange={handleChange} className="client-search-box"
        />
        <button className="xxs-btn" onClick={()=>setAddClientModal(true)}>Add</button>
      </div>
      {selectedClient ? <div className="client-info-container">
        <div className="client-info-grid">
          <div className="client-info-left-content">
            <div className='client-info-header'>
              <p className="r-text b-weight">Personal Information</p>
              <div className='client-info-btn-container'>
              <button className="tertiary-btn" onClick={()=>setUpdateClientModal(true)}>Update</button>
              </div>
            </div>
            <div className='client-info-content'>
            <div className="input-group-divided">
              <div>
                <label>First Name</label>
                <p className="xs-text r-weight f-op caps">{selectedClient.firstName}</p>
              </div>
              <div>
                <label>Last Name</label>
                <p className="xs-text r-weight f-op caps">{selectedClient.lastName}</p>
              </div>
              <div>
                <label>Mobile</label>
                <p className="xs-text r-weight f-op">{selectedClient.mobile}</p>
              </div>
              <div>
                <label>Alternative Mobile</label>
                <p className="xs-text r-weight f-op">{selectedClient.alternateMobile}</p>
              </div>
              <div>
                <label>Email</label>
                <p className="xs-text r-weight f-op">{selectedClient.email}</p>
              </div>
              <div>
                <label>City</label>
                <p className="xs-text r-weight f-op">{selectedClient.city.cityName}</p>
              </div>
              <div>
                <label>Locality</label>
                <p className="xs-text r-weight f-op">{selectedClient.locality}</p>
              </div>
              <div>
                <label>Address</label>
                <p className="xs-text r-weight f-op">{selectedClient.address}</p>
              </div>
              <div>
                <label>Landmark</label>
                <p className="xs-text r-weight f-op">{selectedClient.landmark}</p>
              </div>
              <div>
                <label>State</label>
                <p className="xs-text r-weight f-op">{selectedClient.state}</p>
              </div>
              <div>
                <label>Source</label>
                <p className="xs-text r-weight f-op">{selectedClient.source.name}</p>
              </div>
            </div>
            </div>
          </div>
          <div className="client-info-right-content">
          <div style={{height:'100%',width:'100%'}}>
            <DataGrid sx={{'border':'0'}} getRowId={row=>row._id} disableColumnSelector={true} columns={columns} rows={selectedClient.enquiries ? selectedClient.enquiries : []} disableSelectionOnClick={true}/>
        </div>
          </div>
        </div>

      </div> : null}
    </div>
    </>
  )
}
