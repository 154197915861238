import {LOGIN,LOGOUT,UPDATETOKEN} from '../constants/constants'
const initalState = 
{
    isAuthenticated:false,
    user:false,
    token:false,
}

function authReducer(state = initalState, action) {
    switch(action.type) {
        case LOGIN:
          {
            return{
              ...state,
              isAuthenticated:true,
              user:action.payload.user,
              token:action.payload.token
            }
          }

      case LOGOUT:
        {
          return{
            ...state,
            isAuthenticated:false,
            user:null,
            token:null
          }
        }

      case UPDATETOKEN:
        {
          return{
            ...state,
            token:action.payload.token
          }
        }

      default : return state;
    }
  }

  export default authReducer;