import React, { useState, useEffect } from "react";
import {
  getCategory,
  getCity,
  createEnquery,
  updateEnquery,
  getSources,
  getEnquiryDetail,
} from "../../../components/services";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {toast} from 'react-toastify'
import {TOAST_CONFIG} from '../../../config/constants'
import Modal from "../../../components/Modal";
import AutocompleteInput from "../../../components/AutocompleteInput";
import { authAxios } from "../../../helpers/axios";
import { isPlainObject } from "@mui/utils";
import { useSelector } from "react-redux";
function AddEnquiryModal({setModal,setEnquiryData,updateId,setViewItem,mapping=false,fetchData}) {


    const authData = useSelector(state=>state.auth)

  const [data, setData] = useState({
    firstName:  '',
    lastName:  '',
    email:  '',
    customer:'',
    mobile: '',
    alternateMobile: '',
    primaryMobile:  updateId ? '' : 'primary',
    status:  null,
    city:'',
    geo:'',
    locality:'',
    landmark:'',
    address:'',
    studentCategory:'',
    studentSyllabus:'',
    studentSubjects:'',
    standard:'',
    tutorGender:'',
    days:'',
    enquiryType: updateId ? '' : 'offline',
    feeType: updateId ? '' : 'hourly',
    timingPreference:'',
    followupdate:'',
    feeAmount:'',
    classDuration: updateId ? '' : '1',
    source:'',
    note:'',
    sendMsg:true,
    assignedTo:'',
    isPremium:false,
    premiumPayment:''
    });
  const [clientData, setclientData] = useState([]);

  const [syllabusData, setSyllabusData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);

  const [categoryData, setcategoryData] = useState([]);
  const [citiesData, setcitiesData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [loading,setLoading] = useState(true)
  
  const [employeeData, setEmployeeData] = useState([]);

  const [submit,setSubmit] = useState(false)

  useEffect(() => {
    const fetchInfo = async () => {
    if(updateId)
    {
        const enquiryRes = await getEnquiryDetail(updateId);
        const categoryData = await getCategory();
        const newCityData = await getCity();
        let newSourceData = await getSources()
        setData({...enquiryRes,firstName:enquiryRes.customer.firstName,lastName:enquiryRes.customer.lastName,studentSyllabus:enquiryRes.studentSyllabus._id,assignedTo:enquiryRes.assignedTo ? enquiryRes.assignedTo._id : ''})
        setSourceData(newSourceData)
        setcategoryData(categoryData);
        setcitiesData(newCityData);
          let res = categoryData.find(el=>el._id === enquiryRes.studentCategory._id)
          console.log('check',enquiryRes.studentCategory._id)
          setSyllabusData(res.data)   
        let syllabusRes = res.data.find(el=>el.syllabus._id === enquiryRes.studentSyllabus._id)
        setSubjectData(syllabusRes?.subjects)
        setLoading(false)
    }
    else
    {
        // setLoading(true)
        const categoryData = await getCategory();
        console.log('category data',categoryData)
        const newCityData = await getCity();
        let newSourceData = await getSources()
        setSourceData(newSourceData)
        setcategoryData(categoryData);
        setcitiesData(newCityData);
        setLoading(false)
    }
    
};
fetchInfo();
  }, []);


  useEffect(() => {
    const fetchEmployeeInfo = async (city) => {
        try 
        {
            let res = await authAxios.get(`/employee?city=${city}&active=true`)
            console.log('ccc',authData.user,authData.user.cities.some(el => el._id === city))
            const found = authData.user.cities.some(el => el._id === city);
            if (found) setData(prev=>({...prev,assignedTo:authData.user._id}));
            // if(authData.user.cities.includes(city)) setData(prev=>({...prev,assignedTo:authData.user._id})) ;
            setEmployeeData(res.data)
            console.log('assigned to',data.assignedTo,authData.user._id)
            return res.data
          
        } catch (error) {
            console.log(error)
            toast.error(error,TOAST_CONFIG)
        }
    };
    if(data.city && data.city != null) fetchEmployeeInfo(data.city);
    else setEmployeeData([])
  }, [data.city]);

  const _getCustomer = async(value)=>{
      try 
      {
        let res = await authAxios.get(`/customer/search?customer=${value}`)
        setclientData(res.data)
        return res.data
        
      } catch (error) {
          toast.error(error,TOAST_CONFIG)
      }
  }

//   const _onCityChange = async(city)=>{
//       try 
//       {
//         let res = await authAxios.get(`/employee?city=${city}&active=true`)
//         setclientData(res.data)
//         return res.data
        
//       } catch (error) {
//           toast.error(error,TOAST_CONFIG)
//       }
//   }


  const _onCategoryChange = (e)=>
  {
      try 
      {
          setData(prev=>({...prev,studentCategory:e.target.value,standard:'',studentSyllabus:'',studentSubjects:[]}))
          let res = categoryData.find(el=>el._id === e.target.value)
          setSyllabusData(res.data)   
          
        } catch (error) {
            console.log(error)
            return false;
        }
    }
    
    const _onSyllabusChange = (e)=>
    {
        try 
        {
          setData(prev=>({...prev,studentSyllabus:e.target.value,studentSubjects:[]}))
        let res = syllabusData.find(el=>el.syllabus._id === e.target.value)
        console.log('syllabus response',res)
        setSubjectData(res.subjects)
    
      } catch (error) {
          console.log(error)
          return false;
      }
  }

  const _onSubmit =async(e)=>
  {
    try {
        setSubmit(true)
        e.preventDefault()
        if(updateId)
        {
            let res = await updateEnquery({...data,_id:updateId})
            if(mapping)
            {
                setEnquiryData({...res,_id:updateId})
            }
            else
            {

                setEnquiryData(prev=>{
                    let newData = [...prev]
                    let index = newData.findIndex(item=>item._id === res._id)
                    newData[index] = {}
                    newData[index]._id = res._id
                    newData[index].mobile = res.mobile
                    newData[index].enquiryId = res.enquiryId
                    newData[index].firstName = res.customer.firstName
                    newData[index].lastName = res.customer.lastName
                    newData[index].city = res.city.cityName
                    newData[index].enquiryType = res.enquiryType
                    return newData
                })
                setViewItem(res)
                // fetchData()
            }
                setModal(false)
            toast.info('Enquiry Updated',TOAST_CONFIG)
        }
        else
        {
            let res = await createEnquery(data)
            // setEnquiryData(prev=>([...prev,res]))
            fetchData()
            setViewItem(false)
            setModal(false)
            toast.info('Enquiry Created',TOAST_CONFIG)
        }
        setSubmit(false)
        return true
    } catch (error) {
        setSubmit(false)
        toast.error('Enquiry Creation/Updation Failed',TOAST_CONFIG)
        return false
        
    }
  }

  const _onChange = (value)=>
  {
      console.log('val',value)
      try 
      {
        setData(prev=>({...prev,customer:value._id,firstName:value.firstName,lastName:value.lastName,email:value.email,mobile:value.mobile,addvalues:value.addvalues,landmark:value.landmark,city:value.city,locality:value.locality,geo:value.geo,address:value.address,alternateMobile:value.alternateMobile}))   
      } catch (error) {
          console.log(error)
          toast.error(error,TOAST_CONFIG)
      }
  }

  const _onSubjectChange = (value)=>
  {
    //   console.log('e',e)
      try 
      {
        // let value = Array.from(e, option => option._id);
        // let value = e.map(({ foo }) => foo)
        console.log('values',value)
        setData(prev=>({...prev,studentSubjects: value}));   
      } catch (error) {
          console.log(error)
      }
  }

          
  return (
    <Modal title={updateId ? 'Update Enquiry' : 'Add Enquiry'} size="xxl">
        {!loading ? <form onSubmit={_onSubmit}>
          <div className="modal-inner-content">
            <div className="input-group-divided-four">
                <div>
                    <label>Select Customer</label>
                    <AsyncSelect
                        name="customer" placeholder="Search Name"
                        value={`${data?.firstName} ${data?.lastName} `}
                        getOptionLabel={e => e.firstName + ' ' + e.lastName}
                        getOptionValue={e => e._id}
                        loadOptions={_getCustomer}
                        // onInputChange={(value)=>setData(prev=>({...prev,customer:value
                        // }))}
                        onChange={_onChange} className="client-search-box"
                    />
                </div>

                <div>
                    <label>Customer Name* </label>
                    <input type="text" maxLength={10} required className="text-input" value={`${data.firstName} ${data.lastName}`} disabled onChange={(e)=>setData(prev=>({...prev,mobile:e.target.value}))}/>
                </div>
                <div>
                    <label>Enter Mobile Number* <div className="primary-mobile-radio-container"><input type={'radio'} checked={data.primaryMobile === 'primary'} radioGroup="primaryMobile" onChange={()=>setData(prev=>({...prev,primaryMobile:'primary'}))}/></div></label>
                    <input type="text" pattern="[1-9]{1}[0-9]{9}" maxLength={10} required disabled className="text-input" value={data.mobile} onChange={(e)=>setData(prev=>({...prev,mobile:e.target.value}))}/>
                </div>

                <div>
                    <label>Alternative Number <div className="primary-mobile-radio-container"><input disabled={!data.alternateMobile || data.alternateMobile.length <10} type={'radio'} checked={data.primaryMobile === 'alternate'} radioGroup="primaryMobile" onClick={()=>setData(prev=>({...prev,primaryMobile:'alternate'}))}/></div></label>
                    <input type="text" pattern="[1-9]{1}[0-9]{9}" maxLength={10} className="text-input" value={data.alternateMobile} onChange={(e)=>setData(prev=>({...prev,alternateMobile:e.target.value}))}/>
                </div>

                <div>
                    <label>Enter Email</label>
                    <input type="text" className="text-input" disabled value={data.email} onChange={(e)=>setData(prev=>({...prev,email:e.target.value}))}/>
                </div>     
                
                

                <div>
                    <label>Select City*</label>
                    <select className="text-input" disabled defaultChecked={data.city } required value={data.city}  onChange={(e)=>setData(prev=>({...prev,city:e.target.value}))}>
                        <option value=""></option>
                        {citiesData.map((item)=>
                        {
                            return <option key={item._id} value={item._id}>{item.cityName}</option>
                        })}
                    </select>
                </div>

                <div>
                    <label>Locality*</label>
                    <AutocompleteInput setData={setData} addressText={data.locality}/>
                    {/* <input type="text" required className="text-input" disabled value={data.locality}/> */}
                </div>

                <div>
                    <label>Landmark*</label>
                    <input type="text" required className="text-input" value={data.landmark} onChange={(e)=>setData(prev=>({...prev,landmark:e.target.value}))}/>
                </div>

                <div>
                    <label>Address</label>
                    <input type="text" className="text-input" value={data.address} onChange={(e)=>setData(prev=>({...prev,address:e.target.value}))}/>
                </div>
                <div>
                    <label>Is Premium</label>
                    <select className="text-input" required defaultChecked={data.isPremium}  value={data.isPremium} onChange={(e)=>setData(prev=>({...prev,isPremium:e.target.value}))}>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                </div>
                <div>
                    <label>Premium Payment Link</label>
                    <input type="text" className="text-input" disabled={data.isPremium == false || data.isPremium == 'false' } value={data.premiumPayment} onChange={(e)=>setData(prev=>({...prev,premiumPayment:e.target.value}))}/>
                </div>
            
            </div>
            <div className="input-group-divided-four enquiry-part-two">

                <div>
                    <label>Class Type*</label>
                    <select className="text-input" required value={data.enquiryType} onChange={(e)=>setData(prev=>({...prev,enquiryType:e.target.value}))}>
                        <option value="offline">Offline</option>
                        <option value="online">Online</option>
                        <option value="both">Both</option>
                    </select>
                </div>

                <div>
                    <label>Student Category*</label>
                    <select className="text-input" required value={data.studentCategory} onChange={_onCategoryChange}>
                        <option defaultChecked value={''} disabled></option>
                        {categoryData.map((item)=>
                        {
                            return <option key={item._id} value={item._id}>{item.name}</option>
                        })}
                    </select>
                </div>

                <div>
                    <label>Standard</label>
                    <select className="text-input" value={data.standard} onChange={(e)=>setData(prev=>({...prev,standard:e.target.value}))}>
                        <option value=''></option>
                        <option value="kg">KG</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                </div>

                <div>
                    <label>Student Syllabus*</label>
                    <select className="text-input" defaultChecked={data ? data.studentSyllabus : ''} value={data.studentSyllabus} required onChange={_onSyllabusChange}>
                        <option value={''}></option>
                        {syllabusData.map((item)=>
                        {
                            return <option key={item?.syllabus?._id} value={item?.syllabus?._id}>{item?.syllabus?.name}</option>
                        })}
                    </select>
                </div>

                <div className="subject-select-grid-item">
                    <label>Student Subject*</label>
                    <Select options={subjectData} value={data.studentSubjects} isMulti={true} getOptionLabel={e=>e?.name} getOptionValue={e=>e?._id} onChange={_onSubjectChange}/>
                </div>

                <div>
                    <label>No. of days (per week)*</label>
                    <input type="number" min={1} max={7} required className="text-input" value={data.days} onChange={(e)=>setData(prev=>({...prev,days:e.target.value}))}/>
                </div>

                <div>
                    <label>Class Duration (in Hours)*</label>
                    <select value={data.classDuration} required onChange={(e)=>setData(prev=>({...prev,classDuration:e.target.value}))}>
                        <option value='1'>1</option>
                        <option value='1.5'>1.5</option>
                        <option value='2'>2</option>
                        <option value='2.5'>2.5</option>
                        <option value='3'>3</option>
                        <option value='3+'>3+</option>
                    </select>
                </div>

                <div>
                    <label>Timing Preference*</label>
                    <input type="text" maxLength={12} required className="text-input" value={data.timingPreference} onChange={(e)=>setData(prev=>({...prev,timingPreference:e.target.value}))}/>
                </div>

                <div>
                    <label>Tutor Gender*</label>
                    <select className="text-input" required value={data.tutorGender} onChange={(e)=>setData(prev=>({...prev,tutorGender:e.target.value}))}>
                        <option disabled></option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="any">Any</option>
                    </select>
                </div>

                <div>
                    <label>Next Folloup Date</label>
                    <input type="date" className="text-input" value={data.followupdate} onChange={(e)=>setData(prev=>({...prev,followupdate:e.target.value}))}/>
                </div>

                <div>
                    <label>Fee Range (in INR)*</label>
                    <div className="fee-type-flex">
                    <select value={data.feeType} required className="fee-type-select" onChange={(e)=>setData(prev=>({...prev,feeType:e.target.value}))}>
                        <option value='hourly'>Hourly</option>
                        <option value='monthly'>Monthly</option>
                        <option value='onetime'>One Time</option>
                    </select>
                    <input type="number" className="text-input fee-input" required value={data.feeAmount} onChange={(e)=>setData(prev=>({...prev,feeAmount:e.target.value}))}/>
                    </div>
                </div>

                <div className="enquiry-note-item">
                    <label>Note</label>
                    <input type="text" className="text-input" value={data.note} onChange={(e)=>setData(prev=>({...prev,note:e.target.value}))}/>
                </div>

                <div>
                    <label>Select Source*</label>
                    <select className="text-input" value={data && data.source && (typeof data.source === 'object' && data.source !== null) ? data.source?._id : data.source} required   onChange={(e)=>setData(prev=>({...prev,source:e.target.value}))}>
                        <option value=""></option>
                        {sourceData.map((item)=>
                        {
                            return <option key={item._id} value={item._id}>{item.name}</option>
                        })}
                    </select>
                </div>

                <div>
                    <label>Assign to*</label>
                    <select className="text-input" defaultChecked={data.assignedTo } required value={data.assignedTo}  onChange={(e)=>setData(prev=>({...prev,assignedTo:e.target.value}))}>
                        <option value=""></option>
                        {employeeData.map((item)=>
                        {
                            return <option key={item._id} value={item._id}>{item.firstName} {item.lastName}</option>
                        })}
                    </select>
                </div>

                
            </div>
          </div>
          <div className="modal-footer modal-split-footer">
          <div>{ !updateId ? 
                    <><input type={'checkbox'} className="inline-radio" id="sendMsg" defaultChecked={data.sendMsg} onChange={(e)=>setData(prev=>({...prev,sendMsg:!data.sendMsg}))}/>
                    <label className="inline-label" htmlFor="sendMsg">Send Msg</label></>
                 : null}
                 </div>
                <div className="modal-right-footer">
                    <input type="button" className="xs-neutral-btn" onClick={()=>setModal(false)} value="Cancel"/>
                    <input type="submit" className="xs-delete-btn" disabled={submit} value={updateId ? 'Update Enquiry' : 'Add Enquiry'}/>
                </div>
            </div>
        </form> : 'Loading'}
    </Modal>
  );
}

export default AddEnquiryModal;
