import React from 'react'

export default function Modal({children,title,size="r"}) {
    return (
        <div className="modal">
            <div className={`modal-parent ${size === 'md' ? 'modal-md' : size === 'xxl' ? 'modal-xxl' : size === 'xxxl' ? 'modal-xxxl' : 'modal-xl'}`}>
                <div className="modal-header">
                    <h3 className="r-text b-weight">{title}</h3>
                </div>
                <div> 
                {children}
                </div>
            </div>
        </div>
    )
}
