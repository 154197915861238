import React from 'react'

export default function InfoModal({children,title,size="r",setModal}) {
    return (
        <div className="side-modal">
            <div className={`side-modal-overlay`} onClick={()=>setModal(false)}></div>
            <div className='side-modal-parent'>
                <div> 
                {children}
                </div>
            </div>
        </div>
    )
}
