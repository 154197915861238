import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createSyllabus, updateSyllabus } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'

export default function AddSyllabusModal({setModal,setSyllabus,updateData,syllabus,setUpdateData}) {
    const [name,setName] = useState(updateData && updateData.name ? updateData.name : '')
    const [progress,setProgress] = useState(false)
    const _submit = async(e)=>
    {
        e.preventDefault();
        try 
        {
            setProgress(true)
            let res;
            if(updateData)
            {
                res = await updateSyllabus(updateData._id,name)
                let index = syllabus.findIndex(i=>i._id === updateData._id)
                setSyllabus(prev=>
                    {
                        let newData= [...prev]
                        newData[index] = res
                        return newData
                    })
                    setUpdateData(false)
            }
            else
            {
                res = await createSyllabus(name)
                console.log('received data',res)
                setSyllabus(prev=>([...prev,res]))
            }
            setProgress(false)
            setModal(false)

        } catch (error) {
            setProgress(false)
            toast.error(error,TOAST_CONFIG)
        }
    }

    const _cancel = ()=>
    {
        setUpdateData(false)
        setModal(false)
    }

  return (
    <Modal title={updateData ? 'Update Syllabus' : 'Add Syllabus'} size="md">
        <form onSubmit={_submit}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Enter Syllabus Name</label>
                <input type="text" className="text-input" required value={name} onChange={(e)=>setName(e.target.value)}/>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={_cancel}/>
            <input type="submit" className="xs-delete-btn" disabled={progress} value={updateData ? 'Update Syllabus' : 'Add Syllabus'}/>
        </div>
        </form>
    </Modal>
  )
}
