import React, { useEffect,useState,useCallback } from 'react'
import { getSyllabus,getSubjects } from '../../components/services';
import AddSyllabusModal from './components/AddSyllabusModal';
import AddSubjectModal from './components/AddSubjectModal';
import { DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

export default function Syllabus() {

    const [syllabus,setSyllabus] = useState([])
    const [addSyllabusModal,setAddSyllabusModal] = useState(false)
    const [subjects,setSubjects] = useState([])
    const [addSubjectsModal,setAddSubjectModal] = useState(false)
    const [updateSyllabusModal,setUpdateSyllabusModal] = useState(false)
    const [updateSubjectsModal,setUpdateSubjectModal] = useState(false)
    const getData = useCallback(async ()=>{
        const syllabusData = await getSyllabus();
        const subjectsData = await getSubjects();
        setSyllabus(syllabusData);
        setSubjects(subjectsData)
    },[])
    useEffect(()=>
    {
        getData()
    },[getData])



    const columns = [
        {field:"name",headerName:"Name",flex:1},
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={()=>
                {
                  let item = subjects.filter((i,index)=>{
                    console.log(i)
                    return i._id === params.id
                  })
                  console.log('item',item)
                  setUpdateSubjectModal(item[0])
                  setAddSubjectModal(true)
              }}
            />
          ],
        },
    ]

    const syllabusColumns = [
      {field:"name",headerName:"Name",flex:1},
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={()=>
              {
                let item = syllabus.filter((i,index)=>{
                  console.log(i)
                  return i._id === params.id
                })
                console.log('item',item)
                setUpdateSyllabusModal(item[0])
                setAddSyllabusModal(true)
            }}
          />
        ],
      },
  ]

  return (
    <>
    { addSyllabusModal ? <AddSyllabusModal syllabus={syllabus} setUpdateData={setUpdateSyllabusModal} setModal={setAddSyllabusModal} updateData={updateSyllabusModal} setSyllabus={setSyllabus}/> : null}
    { addSubjectsModal ? <AddSubjectModal subjects={subjects} setUpdateData={setUpdateSubjectModal} setModal={setAddSubjectModal} updateData={updateSubjectsModal} setSubjects={setSubjects}/> : null}
    <div className="split-contained-container">
        <div className="split-left-content">
        <div class="list-content-header">
            <div>
            <h2 className="lg-text b-weight">Syllabus</h2>
            </div>
            <div class="d-flex">
            <button type="button" class="xs-compact-inverted-btn" onClick={()=>setAddSyllabusModal(true)}>Add Syllabus</button>
            </div>
        </div>
        <div style={{height:'100%',width:'100%'}}>
        <DataGrid sx={{border:0}} disableColumnSelector={true} columns={syllabusColumns} rows={syllabus} getRowId={item=>item._id} disableSelectionOnClick={true}/>
        </div>
      </div>
      <div className="split-left-content">
        <div class="list-content-header">
            <div>
            <h2 className="lg-text b-weight">Subjects</h2>
            </div>
            <div class="d-flex">
            <button type="button" class="xs-compact-inverted-btn" onClick={()=>setAddSubjectModal(true)}>Add Subject</button>
            </div>
        </div>
        <div style={{height:'100%',width:'100%'}}>
        <DataGrid sx={{border:0}} disableColumnSelector={true} columns={columns} rows={subjects} getRowId={item=>item._id} disableSelectionOnClick={true}/>
        </div>
      </div>
    </div>
    </>
  )
}
