import React,{useState} from 'react'
import PlacesAutocomplete, {geocodeByAddress,getLatLng,geocodeByPlaceId} from "react-places-autocomplete";

export default function CityAutocomplete({setData,cityName}) {

      const handleChangeAddress = (address) => {
        setData(prev=>({...prev,cityName:address}));
    geocodeByAddress(address)
      .then((results) => {
        setData(prev=>({...prev,cityName:address}));
      });
  };


      const handleAddressSelect = (address,placeId,suggestion) => {
        geocodeByPlaceId(placeId)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          console.log({cityName:suggestion.formattedSuggestion.mainText,latLng})
            setData(prev=>({...prev,cityName:suggestion.formattedSuggestion.mainText,geo:latLng}));
        })
        .catch((error) => console.error("Error", error));
        };
  return <>
        <PlacesAutocomplete
        value={cityName}
        searchOptions={{types:['(cities)'],componentRestrictions:{country:'in'}}}
        onChange={handleChangeAddress}
        onSelect={handleAddressSelect}
        highlightFirstSuggestion={true}
        >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }) => (
          <div className="autocomplete-input">
            <input type="text" value={cityName} required autoComplete="off"
              {...getInputProps({
                placeholder: "",
                className: "location-search-input",
              })}
            />
            <div
              className="autocomplete-dropdown-container"
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item-active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.formattedSuggestion.mainText}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        </PlacesAutocomplete>
    </>
}
