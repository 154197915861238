import React from 'react'
import LoaderIcon from '../assets/images/loader.svg'

export default function Loader() {
  return (
    <div className='loader-container'>
        <div className='loader-content'>
            <div class="loader"></div>
            {/* <img src={LoaderIcon} className="loader-icon"/>
            <p className="xs-text loader-text">Loading</p>     */}
        </div>
    </div>
  )
}
