import React,{useState,useEffect} from 'react'
import Modal from '../../../components/Modal'

export default function TerminateModal({setModal,onClick,item}) {

  const [loading,setLoading] = useState(false)
  
  return (
    <Modal title="Terminate Enquiry" size="md">
      <form>
        <div className="modal-inner-content">
            <p className='r-text m-weight f-op'>Are you sure you want to mark the tuition and enquiry as completed?</p>
            <p className='r-text f-op'>{item.customer?.firstName}</p>
        </div>
        <div className="modal-footer">
        <input type={'button'} className="xs-neutral-btn" onClick={()=>setModal(false)} value="Cancel"/>
            <input type={'submit'} className="xs-delete-btn" disabled={loading} onClick={(e)=>onClick(e,{_id:item._id},setLoading)}/>
        </div>
      </form>
    </Modal>
  )
}
