import React, { useState } from 'react'
import EditCategoryModal from './EditCategoryModal'
import SubCategory from './SubCategory'

export default function ShowCategoryInfo({data,setShowSyllabus,setShowAddSubject,setCategories}) {
    
    const [editModal,setEditModal] = useState(false)
  return (
    <>
    { editModal ? <EditCategoryModal setModal={setEditModal} setCategories={setCategories} updateData={editModal}/> : null}
    <div className="info-container">
        <div className="info-header">
            <div>
                <p className="xxs-text q-op">Category Name</p>
                <h4 className="r-text m-weight f-op">{data.name}</h4>
            </div>
            <div>
            <button type='button' className='tertiary-btn' onClick={()=>setEditModal(data)}>Edit</button>
                <button className="compact-xs-btn" onClick={()=>setShowSyllabus(true)}>Add Syllabus</button>
            </div>
        </div>
        <div className="info-content">
            {
                data.data.map((item,index)=>
                {
                    return <SubCategory setCategories={setCategories} category={item} key={index} categoryId={data._id} setShowAddSubject={setShowAddSubject}/>
                })
            }
        </div>
    </div>
    </>
  )
}
