import React, { useState ,useEffect} from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { getEnquiryDetail } from '../../../components/services';
import { TOAST_CONFIG } from '../../../config/constants';
import { getDate } from '../../../helpers/dateHelper';
import AddEnquiryModal from './AddEnquiryModal';

export default function PendingInfo({viewItem,setTerminateItem,setApproveItem,setEnquiryData,setViewItem}) {

    const [data,setData] = useState({})
    const [loading,setLoading] = useState(true)
    const [updateData,setUpdateData] = useState(false)
    const authInfo = useSelector(state=>state.auth)
    
    const getSubjects = (subject)=>
    {
        return subject.map(function (e) {
            return e.name;
          }).join(', ');
    }

      useEffect(() => {
        const getEnquiryInfo = async()=>
        {
            try {
                setLoading(true)
                let res = await getEnquiryDetail(viewItem._id)
                setData(res)
                setLoading(false)
                return true;
            } catch (error) {
                console.log(error)
                toast.error('error',TOAST_CONFIG)
                setLoading(false)
                return false;
            }
        }
        getEnquiryInfo()
      }, [viewItem])
      
  return (<div className='loader-parent'>
      { !loading ? <>
      {updateData ? <AddEnquiryModal setModal={setUpdateData} setViewItem={setViewItem} updateId={updateData._id} setEnquiryData={setEnquiryData}/> : null}
    <div className="pending-info-container">
        <div className="pending-info-content">
            <div className="pending-info-header">
                <div>
                    <p className="r-text m-weight f-op caps">{data.customer.firstName} {data.customer.lastName}</p>
                    <p className="xxs-text m-weight h-op uppercase">{data.enquiryId ? data.enquiryId : '-'} {data.isPremium === true ? <span className='premium-badge'>Premium</span> : null}</p>
                </div>
                <div className="pending-info-btn-container">
                    <button className="tertiary-btn" onClick={()=>setUpdateData(data)}>Update</button>
                    {
                        authInfo.user.role === 'manager' ? <><button className="xs-delete-btn" onClick={()=>setTerminateItem(data)}>Terminate</button>
                        <button className="xs-approve-btn" onClick={()=>setApproveItem(data)}>Approve</button></> : null   
                    }
                </div>
            </div>
            <div className="pending-inner-content">
                <div className="pending-info-grid">

                    <div>
                        <p className="xxs-text h-op m-weight">Mobile {data.primaryMobile === 'primary' ? <span className='primary-badge'>Primary</span> : null}</p>
                        <p className="xs-text f-op m-weight">{data.mobile}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Alternative Mobile {data.primaryMobile === 'alternate' ? <span className='primary-badge'>Primary</span> : null}</p>
                        <p className="xs-text f-op m-weight">{data.alternateMobile}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Email</p>
                        <p className="xs-text f-op m-weight">{data.customer.email}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">City</p>
                        <p className="xs-text f-op m-weight">{data.city.cityName}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Locality</p>
                        <p className="xs-text f-op m-weight">{data.locality}</p>
                        <a className='xxs-text' target="_blank" href={`https://maps.google.com/?q=${data.geo.lat},${data.geo.lng}`}>View In Map</a>
                    </div>

                    
                    <div>
                        <p className="xxs-text h-op m-weight">Landmark</p>
                        <p className="xs-text f-op m-weight caps">{data.landmark}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Address</p>
                        <p className="xs-text f-op m-weight caps">{data.address}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Enquiry Type</p>
                        <p className="xs-text f-op m-weight caps">{data.enquiryType}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Category/Standard</p>
                        <p className="xs-text f-op m-weight caps">{data.studentCategory.name} {data.standard ? `/ ${data.standard}` : ` / -`}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Syllabus</p>
                        <p className="xs-text f-op m-weight caps">{data.studentSyllabus.name}</p>
                    </div>
                    <div>
                        <p className="xxs-text h-op m-weight">Subjects</p>
                        <p className="xs-text f-op m-weight caps">{getSubjects(data.studentSubjects)}</p>
                    </div>
                    <div>
                        <p className="xxs-text h-op m-weight">No. of Days(Per Week)</p>
                        <p className="xs-text f-op m-weight caps">{data.days}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Class Duration / Timing Preference</p>
                        <p className="xs-text f-op m-weight">{data.classDuration} Hour {data.timingPreference ? ` / ${data.timingPreference}` : ' / -'}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Tutor Gender</p>
                        <p className="xs-text f-op m-weight">{data.tutorGender}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Fee Amount/Type</p>
                        <p className="xs-text f-op m-weight caps">{data.feeAmount} / {data.feeType}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Note</p>
                        <p className="xs-text f-op m-weight caps">{data.note}</p>
                    </div>
                    
                    <div>
                        <p className="xxs-text h-op m-weight">Source</p>
                        <p className="xs-text f-op m-weight caps">{data.source?.name}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Created At</p>
                        <p className="xs-text f-op m-weight caps">{getDate(data.createdAt,false)}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Created By</p>
                        <p className="xs-text f-op m-weight caps">{data.addedBy ? `${data.addedBy.firstName} ${data.addedBy.lastName} (${data.addedBy.role})` : 'Not Available'}</p>
                    </div>

                </div>
            </div>
        </div>
    </div></> : <Loader/> } </div>
  )
}
