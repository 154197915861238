import React, { useState ,useEffect} from "react";
import { Link,NavLink,Outlet, useNavigate } from "react-router-dom";
import {getAuth,signOut} from 'firebase/auth'
import Logo from '../assets/images/logo.png'
import { useSelector } from "react-redux";

function Nav() {

  const navigate = useNavigate()
  const authInfo = useSelector(state=>state.auth)
  const [loading,setLoading] = useState(true)
  const auth = getAuth()
  const links = [
    { name: "Enqueries", active: true, link: "/dashboard/enqueries/pending" },
    { name: "Leads", active: false, link: "/dashboard/leads" },
    { name: "Tutor Reg.", active: false, link: "/dashboard/tutor-registration" },
    { name: "Clients", active: false, link: "/dashboard/client" },
    { name: "Tutors", active: false, link: "/dashboard/tutors" },
  ];
  const supportLinks = [
    { name: "Tutors", active: false, link: "/dashboard/tutors" },
    { name: "Leads", active: false, link: "/dashboard/leads" },
    { name: "Tutor Reg.", active: false, link: "/dashboard/tutor-registration" },
  ];
  const managerlinks = [
    { name: "Enqueries", active: true, link: "/dashboard/enqueries/pending" },
    { name: "Leads", active: false, link: "/dashboard/leads" },
    { name: "Tutor Reg.", active: false, link: "/dashboard/tutor-registration" },
    { name: "Employees", active: false, link: "/dashboard/employees" },
    { name: "Clients", active: false, link: "/dashboard/client" },
    { name: "Tutors", active: false, link: "/dashboard/tutors" },
    { name: "Settings", active: false, link: "/dashboard/settings" },
    { name: "Analytics", active: false, link: "/dashboard/analytics" },
  ];

  useEffect(() => {
    if(authInfo.user && authInfo.token) setLoading(false)
    // else navigate('/login')
  }, [''])
  

  return !loading ? <>
    <div className="nav-bar">
      <nav className="nav-bar-content">
          <div>
            <Link className="navbar-brand" to="/">
              <img src={Logo} className="navbar-logo"/>
              Protutor
            </Link>
          </div>
          <div className="nav-menu-flex">
            { authInfo.user.role === 'manager' ?  managerlinks.map((item,index) => {
              return (
                <NavLink key={index}
                  className={({isActive})=>`nav-item ${isActive ? 'nav-item-active' : ''}`}
                  to={`${item.link}`}
                >
                  {item.name}
                </NavLink>
              );
            }) : authInfo.user.role === 'support' ? supportLinks.map((item,index) => {
              return (
                <NavLink key={index}
                  className={({isActive})=>`nav-item ${isActive ? 'nav-item-active' : ''}`}
                  to={`${item.link}`}
                >
                  {item.name}
                </NavLink>
              );
            })  : links.map((item,index) => {
              return (
                <NavLink key={index}
                  className={({isActive})=>`nav-item ${isActive ? 'nav-item-active' : ''}`}
                  to={`${item.link}`}
                >
                  {item.name}
                </NavLink>
              );
            })}
          </div>
          <div className="d-flex" style={{ paddingRight: "30px" }}>
            <button className="compact-btn" onClick={async()=>await signOut(auth)}>Logout</button>
          </div>

      </nav>
    </div>
        <Outlet/>
    </> : 'Loading'
}
export default Nav;
