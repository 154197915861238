import axios from 'axios';
// import { BASE_URL } from '../config/constants';
import { getAuth } from "firebase/auth";
import FirebaseApp from './firebase'
// const authState = useSelector(state=>state.auth) 
const FirebaseAuth = getAuth()
  const authAxios = axios.create({
    baseURL:process.env.REACT_APP_BASE_URL
  })
  // authAxios.interceptors.request.use(
  //   async(config) => {
  //     console.log('ccurent user',FirebaseAuth.currentUser)
  //     let token = await FirebaseAuth.currentUser.getIdToken(true)
  //       config.headers.Authorization = token;
  //     return config;
  //   }
  // ,(error) => {
  //   console.log('error occured',error)
  //   return Promise.reject(error)
  //  }
  // );

  const publicAxios = axios.create({
    baseURL:process.env.REACT_APP_BASE_URL
  })

  export {authAxios,publicAxios}