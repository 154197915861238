import { createStore,combineReducers } from 'redux';
import {persistStore,persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from './reducers/authReducer.js';
// import profileReducer from './reducers/profileReducer.js'


const rootReducer = combineReducers({
  auth:authReducer,
//   profile:profileReducer
})
const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig,rootReducer);

    export const store = createStore(persistedReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    export const persistedStore = persistStore(store);
    // persistedStore,store}  

