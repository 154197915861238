import axios from "axios";
import {authAxios} from "../helpers/axios";

export const getSubjects = async () => {
  return authAxios
    .get(`/settings/subject`)
    .then((res) => res?.data)
};

export const createSubjects = async (name) => {
  return authAxios
    .post(`/settings/subject`, { name: name })
    .then((res) => res?.data)
};

export const updateSubject = async (id,name) => {
  return authAxios
    .put(`/settings/subject`, {id,name })
    .then((res) => res?.data)
};

export const removeSubject = async (name) => {
  return authAxios
    .delete(`/settings/subject`, { name: name })
    .then((res) => res?.data)
};
export const getTutor = async (search,id='',populate,city,page,pageSize) => {
  return authAxios
    .get(`/tutor?id=${id}&populate=${populate}&city=${city}&skip=${page <= 0 ? 0 :(page)*pageSize}&limit=${pageSize}&search=${search}`)
    .then((res) => res?.data)
};

export const createTutor = async (data) => {
  return authAxios
    .post(`/tutor`, data)
    .then((res) => res?.data)
};

export const removeTutor = async (name) => {
  return authAxios
    .put(`/removetutor`, { name: name })
    .then((res) => res?.data)
};

export const getState = async () => {
  return authAxios
    .get(`/settings/state`)
    .then((res) => res?.data)
};

export const createState = async (name) => {
  return authAxios
    .post(`/settings/state`, { name: name })
    .then((res) => res?.data)
};

export const updateState = async (name,_id) => {
  return authAxios
    .put(`/settings/state`, { name: name,_id:_id })
    .then((res) => res?.data)
};

export const removeState = async (name) => {
  return authAxios
    .delete(`/settings/state`, { name: name })
    .then((res) => res?.data)
};
export const getCity = async () => {
  return authAxios
    .get(`/settings/city`)
    .then((res) => res?.data)
};

export const createCity = async (name, stateId,geo) => {
  return authAxios
    .post(`/settings/city`, {
      cityName: name,
      cityState: stateId,
      geo:geo
    })
    .then((res) => res?.data)
};

export const removeCity = async (name) => {
  return authAxios
    .delete(`/settings/city`, { cityName: name })
    .then((res) => res?.data)
};
export const getSyllabus = async (category=null) => {
  let query = '/settings/syllabus';
  if(category) query+=`?category=${category}`
  return authAxios
    .get(query)
    .then((res) => res?.data)
};

export const createSyllabus = async (name) => {
  return authAxios
    .post(`/settings/syllabus`, { name: name })
    .then((res) => res?.data)
};

export const updateSyllabus = async (id,name) => {
  return authAxios
    .put(`/settings/syllabus`, { id, name })
    .then((res) => res?.data)
};

export const removeSyllabus = async (name) => {
  return authAxios
    .post(`/settings/syllabus`, { name: name })
    .then((res) => res?.data)
};

export const getCategory = async () => {
  return authAxios
    .get(`/settings/category`)
    .then((res) => res?.data)
};

export const createCategory = async (name) => {
  return authAxios
    .post(`/settings/category`, { name: name })
    .then((res) => res?.data)
};

export const editCategory = async (data) => {
  return authAxios
    .put(`/settings/category`,data)
    .then((res) => res?.data)
};

export const removeCategory = async (name) => {
  return authAxios
    .delete(`/settings/category`, { name: name })
    .then((res) => res?.data)
};
export const getEmployees = async () => {
  return authAxios
    .get(`/employee`)
    .then((res) => res?.data)
};
export const createEmployees = async (values) => {
  return authAxios
    .post(`/employee`, values)
    .then((res) => res?.data)
};

export const updateEmployees = async (values) => {
  return authAxios
    .put(`/employee`,values)
    .then((res) => res?.data)
};
export const updateTutor = async (values) => {
  return authAxios
    .put(`/tutor`, values )
    .then((res) => res?.data)
};



export const getCustomer = async (id='',populate,city) => {
  return authAxios
    .get(`/customer?id=${id}&populate=${populate}&city=${city}`)
    .then((res) => res?.data)
};
export const getCustomerEnquiry = async (id) => {
  return authAxios
  .get(`/enquiry/customer/${id}`)
    .then((res) => res?.data)
};
export const createCustomer = async (values) => {
  return authAxios
    .post(`/customer`, values)
    .then((res) => res?.data)
};

export const updateCustomer = async (data,id) => {
  return authAxios
    .put(`/customer`, {data:data,id:id})
    .then((res) => res?.data)
};

export const getEnquery = async () => {
  return authAxios
    .get(`/enquiry`)
    .then((res) => res?.data)
};

export const getEnquiryDetail = async (id) => {
  return authAxios
    .get(`/enquiry/${id}`)
    .then((res) => res?.data)
};

export const getPendingEnquiry = async (searchText,city,page,pageSize) => {
  return authAxios
  .get(`/enquiry?status=pending&populate=true&city=${city}&skip=${page <= 0 ? 0 :(page)*pageSize}&limit=${pageSize}&search=${searchText}`)
  .then((res) => res?.data)
};

export const getAllEnquiry = async (city) => {
  return authAxios
  .get(`/enquiry?status=all&populate=true&city=${city}`)
  .then((res) => res?.data)
};

export const getApprovedEnquery = async (searchText,city,page,pageSize) => {
  return authAxios
  .get(`/enquiry?status=approved&populate=true&city=${city}&skip=${page <= 0 ? 0 :(page)*pageSize}&limit=${pageSize}&search=${searchText}`)
  .then((res) => res?.data)
};
export const getMappingEnquery = async (searchText,city,page,pageSize) => {
  return authAxios
  .get(`/enquiry/mapping?status=approved&populate=true&city=${city}&skip=${page <= 0 ? 0 :(page)*pageSize}&limit=${pageSize}&search=${searchText}`)
  .then((res) => res?.data)
};
// getapprovedenqueries
export const getMappedTutors = async (id,range,classType) => {
  return authAxios
  .get(`/enquiry/mappedtutors?id=${id}&range=${range}&classType=${classType}`)
  .then((res) => res?.data)
};

export const getExcludedTutors = async (id) => {
  return authAxios
  .get(`/enquiry/tutors/excluded?id=${id}`)
  .then((res) => res?.data)
};

export const createEnquery = async (values) => {
  return authAxios
  .post(`/enquiry`, values)
  .then((res) => res?.data)
};

export const updateEnquery = async (values) => {
  return authAxios
  .put(`/enquiry`, values )
  .then((res) => res?.data)
};

export const assignDemo = async (values) => {
  return authAxios
  .post(`/enquiry/demo`, values)
  .then((res) => res?.data)
};

export const getDemos = async (city,isCancelled=false,isFinished=false) => {
  return authAxios
    .get(`/enquiry/demo?city=${city}&isCancelled=${isCancelled}&isCancelled=${isFinished}`)
    .then((res) => res?.data)
};

export const cancelDemo = async (values) => {
  return authAxios
    .post(`/enquiry/demo/cancel`, values)
    .then((res) => res?.data)
};

export const completeDemo = async (values) => {
  return authAxios
    .post(`/enquiry/demo/complete`, values)
    .then((res) => res?.data)
};

export const initiateTution = async (values) => {
  return authAxios
    .post(`/enquiry/tuition`,values)
    .then((res) => res?.data)
};
export const terminateEnquiry = async (values) => {
  return authAxios
    .post(`/enquiry/terminate`,values)
    .then((res) => res?.data)
};
export const approveEnquiry = async (values) => {
  console.log(`values`, values);
  return authAxios
    .post(`/enquiry/approve`, values)
    .then((res) => res?.data)
};

export const excludeTutors = async (values) => {
  console.log(`values`, values);
  return authAxios
    .post(`/enquiry/exclude-tutor`, values )
    .then((res) => res?.data)
};

export const includeTutors = async (values) => {
  console.log(`values`, values);
  return authAxios
    .post(`/enquiry/include-tutor`, values )
    .then((res) => res?.data)
};

export const getTutions = async (city) => {
  return authAxios
    .get(`/enquiry/tuition?city=${city}`)
    .then((res) => res?.data)
};
export const rescheduleDemos = async (values) => {
  return authAxios
    .post(`/enquiry/demo/reschedule`, values)
    .then((res) => res?.data)
};
export const updateTuition = async (values) => {
  return authAxios
    .put(`/enquiry/tuition`,values)
    .then((res) => res?.data)
};

export const completeTuition = async (values) => {
  return authAxios
    .post(`/enquiry/tuition-complete`,values)
    .then((res) => res?.data)
};



export const getProfessions = async () => {
  return authAxios
    .get(`/settings/profession`)
    .then((res) => res?.data)
};

export const createProfession = async (name) => {
  return authAxios
    .post(`/settings/profession`, { name: name })
    .then((res) => res?.data)
};

export const updateProfession = async (id,name) => {
  return authAxios
    .put(`/settings/profession`, {id,name })
    .then((res) => res?.data)
};

export const removeProfession = async (name) => {
  return authAxios
    .delete(`/settings/profession`, { name: name })
    .then((res) => res?.data)
};


export const getSources = async () => {
  return authAxios
    .get(`/settings/source`)
    .then((res) => res?.data)
};

export const createSource = async (name) => {
  return authAxios
    .post(`/settings/source`, { name: name })
    .then((res) => res?.data)
};

export const updateSource = async (name,id) => {
  return authAxios
    .put(`/settings/source`, {id,name })
    .then((res) => res?.data)
};

export const removeSource = async (name) => {
  return authAxios
    .delete(`/settings/source`, { name: name })
    .then((res) => res?.data)
};



// Leads

export const getLeads = async (searchText,city=null,page,pageSize) => {
  console.log('sfsaf',searchText,city,page,pageSize)
  return authAxios
    .get(`/lead?status=open&populate=true&city=${city}&skip=${page <= 0 ? 0 :(page)*pageSize}&limit=${pageSize}&search=${searchText}`)
    .then((res) => res?.data)
};

export const getLeadDetail = async (id) => {
  return authAxios
    .get(`/lead?id=${id}`)
    .then((res) => res?.data)
};

// Tutor Registrations

export const getTutorRegistrations = async (searchText,city=null,page,pageSize) => {
  console.log('sfsaf',searchText,city,page,pageSize)
  return authAxios
    .get(`/tutor-registration?status=open&populate=true&city=${city}&skip=${page <= 0 ? 0 :(page)*pageSize}&limit=${pageSize}&search=${searchText}`)
    .then((res) => res?.data)
};

export const getTutorRegistrationDetail = async (id) => {
  return authAxios
    .get(`/tutor-registration?id=${id}`)
    .then((res) => res?.data)
};

export const sendBroadcast = async (id) => {
  return authAxios
  .post(`/enquiry/broadcast`, { enquiryId: id })
  .then((res) => res?.data)
};

export const resetBroadcast = async (id) => {
  return authAxios
  .post(`/enquiry/broadcast/reset`, { _id: id })
  .then((res) => res?.data)
};
