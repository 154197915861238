import React,{useEffect, useState} from 'react'
import Async from 'react-select/async'
import AddClient from './clients/AddClient'
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../config/constants';
import { authAxios } from '../helpers/axios';
import { DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { getCustomer, getCustomerEnquiry } from '../components/services';
import EnquiryDetail from './clients/EnquiryDetail';
import TutorInfo from './employees/TutorInfo';
import AddTutorModal from './tutors/AddTutorModal';
import UpdateTutorModal from './tutors/UpdateTutorModal';
export default function TutorSecondary() {

  const [tutors, setTutors] = useState([]);
  const [inputValue, setValue] = useState('');
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [addClientModal,setAddClientModal] = useState(false)
  const [updateClientModal,setUpdateClientModal] = useState(false)
  const [enquiryDetailModal,setEnquiryDetailModal] = useState(false)
 
  const handleInputChange = value => {
    setValue(value);
  };
 
  const handleChange = async(value) => {
    console.log('val',value)
    let res = await authAxios.get(`/employee/tutor${value._id ? `?id=${value._id}` : ''}`)
    setSelectedTutor(res.data);
  }
 
  const fetchData = async() => {
    if(inputValue.length >= 4)
    {
      let res = await authAxios.get(`/employee/tutor/search?customer=${inputValue}`)
      setTutors(res.data)
      return res.data
    }
  }

  const columns =[
    {field:'createdAt',headerName:'Date',flex:1,valueGetter:(params)=>
    {
      let d = new Date(params.row?.createdAt)
      return `${d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`}-${d.getMonth()+1}-${d.getFullYear('').toString().substr(-2)}`
    }},
    {field:'studentCategory',headerName:'Category',flex:1,valueGetter: (params) => params.row?.studentCategory?.name},
    {field:'studentCategory',headerName:'Category',flex:1,renderCell:(params)=>
    {
      return <div>
          <p className='xs-text r-weight f-op caps'>{params.row?.standard ? `class-${params.row?.standard}` : params.row?.studentCategory?.name}</p>
      </div>
    }},
    {field:'studentSyllabus',headerName:'Syllabus',flex:1,valueGetter: (params) => params.row?.studentSyllabus?.name},
    {field:'currentProcess',headerName:'Status',flex:1,renderCell:(params)=>
    {
      return <div><div><p className="xs-text r-weight">{params.row.currentProcess === 0 ? 'Pending' : params.row.currentProcess === 1 ? 'Approved' : params.row.currentProcess === 2 ? 'Demo' : 'Tuition'}</p></div></div>
    }},
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="View"
          onClick={()=>
            {
              setEnquiryDetailModal(params.row?._id)
              // let item = selectedTutor.enquiries?.filter((i,index)=>{
              //   console.log(i)
              //   return i._id === params.id
              // })
              // console.log('item',item)
          }}
        />
      ],
    },
  ]

  return (
    <>
    {addClientModal ? <AddTutorModal secondaryForm={true} setModal={setAddClientModal} setSelectedTutor={setSelectedTutor}/> : null}
    {updateClientModal ? <UpdateTutorModal setModal={setUpdateClientModal} updateData={selectedTutor} setSelectedTutor={setSelectedTutor} secondaryForm={true} _updateTutors={setSelectedTutor}/> : null}
    {enquiryDetailModal ? <EnquiryDetail setModal={setEnquiryDetailModal} id={enquiryDetailModal}/> : null}
    <div className="client-container secondary-tutor-container">
      <div className="client-search-container">
        <Async
            name="storageTypeId" placeholder="Search Name/Mobile"
            value={selectedTutor?.firstName}
            getOptionLabel={e => e.firstName + ' ' + e.lastName}
            getOptionValue={e => e._id}
            loadOptions={fetchData}
            onInputChange={handleInputChange}
            onChange={handleChange} className="client-search-box"
        />
        <button className="xxs-btn" onClick={()=>setAddClientModal(true)}>Add</button>
      </div>
      { selectedTutor ? <TutorInfo data={selectedTutor} setShowEdit={setUpdateClientModal} /> : null}
    </div>
    </>
  )
}
