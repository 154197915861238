import React,{useState} from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { TOAST_CONFIG } from '../../../config/constants'

export default function UpdateNote({setModal,onClick,item}) {

    const [note,setNote] = useState(item.note)
    const [loading,setLoading] = useState(false)

    const _onClick = async(e)=>
    {
        e.preventDefault()
        try {
            setLoading(true)
            await onClick({id:item._id,note:note})
            setLoading(false)
            setModal(false)
        } catch (error) {
            console.log(error)
            toast.error('Not updation failed',TOAST_CONFIG)
            setLoading(false)
            return false
        }
    }
  return (
    <Modal title="Update Note" size="md">
        <div className="modal-inner-content">
            <label>Note</label>
            <textarea rows={3} draggable={false} value={note} className="text-input" style={{'resize':'none'}} onChange={(e)=>setNote(e.target.value)}/>
        </div>
        <div className="modal-footer">
            <button className="xs-neutral-btn" onClick={()=>setModal(false)}>Cancel</button>
            <button className="xs-delete-btn" disabled={loading} onClick={_onClick}>Update</button>
        </div>
    </Modal>
  )
}
