import React,{useState} from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { TOAST_CONFIG } from '../../../config/constants'
import {initiateTution} from '../../../components/services'

export default function InitateTuition({setAssignTuition,onClick,item}) {

    const [loading,setLoading] = useState(false)
    const [data,setData] = useState({fee:item.enquiry.feeAmount,duration:item.enquiry.classDuration,days:item.enquiry.days,startDate:'',commissionToCompany:'',tutorPaymentConfirmed:false,clientPaymentConfirmed:false,courier:false,photo:false,addressProof:false,enquiry:item.enquiry._id,customer:item.customer._id,demoId:item._id,tutor:item.tutor._id,paymentInfo:false,feeType:'hourly'})
    

    const _onclick = async(e)=>
    {
      try 
      {
        setLoading(true)
        await onClick(e,data) 
        setLoading(false)
      } catch (error) {
        toast.error(error.response.message,TOAST_CONFIG)  
        setLoading(false)
        return false;
      }
    }

  return (
    <Modal title="Initiate Tuition" size="lg">
      <form onSubmit={_onclick}>
        <div className="modal-inner-content">
            <div className='input-group-divided-three'>
            <div>
                    <label>Fee Range (in INR)*</label>
                    <div className="fee-type-flex">
                    <select value={data.feeType} defaultChecked={data.feeType} required className="fee-type-select" onChange={(e)=>setData(prev=>({...prev,feeType:e.target.value}))}>
                        <option value='hourly'>Hourly</option>
                        <option value='monthly'>Monthly</option>
                        <option value='onetime'>One Time</option>
                    </select>
                    <input type="number" className="text-input fee-input" required value={data.fee} onChange={(e)=>setData(prev=>({...prev,fee:e.target.value}))}/>
                    </div>
                </div>
              <div>
                    <label>Duration (in Hrs)*</label>
                    <select value={data.duration} defaultChecked={data.duration} required onChange={(e)=>setData(prev=>({...prev,duration:e.target.value}))}>
                        <option value='1'>1</option>
                        <option value='1.5'>1.5</option>
                        <option value='2'>2</option>
                        <option value='2.5'>2.5</option>
                        <option value='3'>3</option>
                        <option value='3+'>3+</option>
                    </select>
                </div>
              <div>
              

                <label>Days (In A Week)</label>
                <input className='text-input' type="number" defaultValue={data.days} max={7} min={1} onChange={(e)=>setData(prev=>({...prev,days:e.target.value}))}/>
              </div>
              <div>
                <label>Starting Date*</label>
                <input className='text-input' type={'date'} required onChange={(e)=>setData(prev=>({...prev,startDate:e.target.value}))}/>
              </div>

              <div>
                <label>Commission to company</label>
                <input className='text-input' type={'text'} onChange={(e)=>setData(prev=>({...prev,commissionToCompany:e.target.value}))} value={data.commissionToCompany}/>
              </div>
              
              <div>
                <label>Customer</label>
                <input className='text-input caps' type={'text'} disabled value={`${item.customer.firstName} ${item.customer.lastName}`}/>
              </div>
              <div className='checkbox-input-group'>
                <input id="tutorPayment" type={'checkbox'} onChange={(e)=>setData(prev=>({...prev,tutorPaymentConfirmed:e.target.checked}))}/>
                <label >Tutor Payment Confirmed</label>
              </div>
              <div className='checkbox-input-group'>
                <input id="clientPayment" type={'checkbox'} onChange={(e)=>setData(prev=>({...prev,clientPaymentConfirmed:e.target.checked}))}/>
                <label htmlFor='clientPayment'>Client Payment Confirmed</label>
              </div>
              <div className='checkbox-input-group'>
                <input id='courier' type={'checkbox'} onChange={(e)=>setData(prev=>({...prev,courier:e.target.checked}))}/>
                <label htmlFor='courier'>Courier Sent</label>
              </div>
              <div className='checkbox-input-group'>
                <input id='photo' type={'checkbox'} onChange={(e)=>setData(prev=>({...prev,photo:e.target.checked}))}/>
                <label htmlFor='photo'>Photo Received</label>
              </div>
              <div className='checkbox-input-group'>
                <input id='addressProof' type={'checkbox'} onChange={(e)=>setData(prev=>({...prev,addressProof:e.target.checked}))}/>
                <label htmlFor='addressProof'>Address Proof</label>
              </div>
              <div className='checkbox-input-group'>
                <input id='addressProof' type={'checkbox'} onChange={(e)=>setData(prev=>({...prev,paymentInfo:e.target.checked}))}/>
                <label htmlFor='paymentInfo'>Payment Details</label>
              </div>
            </div>
        </div>
        <div className="modal-footer">
            <input type={'button'} className="xs-neutral-btn" onClick={()=>setAssignTuition(false)} value="Cancel"/>
            <input type={'submit'} className="xs-delete-btn" disabled={loading} value="Initate Now"/>
        </div>
      </form>
    </Modal>
  )
}
