import React, { useState, useEffect } from "react";
import AddTutorModal from "./tutors/AddTutorModal";
import { getCategory, getTutor } from "../components/services";
import { DataGrid } from "@mui/x-data-grid";
import TutorInfo from "./tutors/TutorInfo";
import UpdateTutorModal from "./tutors/UpdateTutorModal";
import { useSelector } from "react-redux";

function Tutors() {
  const authData = useSelector(state=>state.auth)

  const [unfilteredTutors, setUnfilteredTutors] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [addTutorModal,setAddTutorModal] = useState(false)
  const [showEditModal,setShowEditModal] = useState(false)
  const [searchValue,setSearchValue] = useState('')
  const [selectedTutor,setSelectedTutor] = useState(false)
  const [selectedCity,setSelectedCity] = useState('all')


  const [loading,setLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  const [countData,setCountData] = useState(0)

  const [rowCountState, setRowCountState] = useState(countData || 0,);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      countData !== undefined
        ? countData
        : prevRowCountState,
    );
  }, [countData, setRowCountState]);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setSelectedTutor(false)
      const res = await getTutor(searchValue,'',true,selectedCity,page,pageSize);
      setCountData(res.count)
      setUnfilteredTutors(res.docs);
      setTutors(res.docs);
      setLoading(false)
    };
    fetchData();
  }, [page]);

  useEffect(() => {
    const fetchData = async () => {
      setPage(0)
      setLoading(true)
      setSelectedTutor(false)
      const res = await getTutor(searchValue,'',true,selectedCity,page,pageSize);
      setCountData(res.count)
      setUnfilteredTutors(res.docs);
      setTutors(res.docs);
      setLoading(false)
    };
    fetchData();
  }, [selectedCity]);
  


  // useEffect(()=>
  // {
  //   if(selectedTutor)
  //   {
  //     let dataIndex = tutors.findIndex(item=>item._id === selectedTutor._id)
  //     setSelectedTutor(tutors[dataIndex]._id)
  //   }
  // },[tutors])


  const _updateTutors = (data)=>
  {
    
    setSearchValue('')
    setTutors(prev=>
      {
        let newData = [...prev]
        let i = newData.findIndex(i=>i._id === data._id)
        newData[i] = data
        setSelectedTutor(data._id)
        console.log('new',newData)
        return newData
      })
  }


  const columns = [
    {field:'firstName',headerName:'Name/Mobile',flex:1,renderCell:(params)=>{
      return <div className="tutor-list-item">
          <p className="xxs-text m-weight f-op caps">{params.row.firstName} {params.row.lastName}</p>
          <p className="xxxs-text r-weight q-op">{params.row.mobile}</p>
      </div>
    }},
    {field:'locality',headerName:'Locality/City',flex:1,renderCell:(params)=>{
      return <div className="tutor-list-item">
          <p className="xs-text r-weight f-op">{params.row.locality}</p>
          {/* <p className="xxs-text r-weight q-op">{params.row.city.cityName}</p> */}
      </div>
    }},
  ]

  // function escapeRegExp(value) {
  //   return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  // }

  // const requestSearch = (searchValue) => {
  //   setSearchValue(searchValue);
  //   const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
  //   const filteredRows = unfilteredTutors.filter((row) => {
  //     return Object.keys(row).some((field) => {
  //       if(field === 'mobile' || field === 'firstName' || field === 'lastName' || field === 'alternateMobile')
  //       {
  //         return searchRegex.test(row['mobile']?.toString()) || searchRegex.test(row['firstName']?.toString()) || searchRegex.test(row['lastName']?.toString()) || searchRegex.test(row['alternateMobile']?.toString()) ;
  //       }
  //     });
  //   });
  //   console.log('filtered',filteredRows)
  //   setTutors(filteredRows);
  // };


  const requestSearch = (searchValue) => {
    setSearchValue(searchValue);
    if(searchValue.length > 3 || searchValue === '')
    {
      const fetchData = async()=>
      {
        try 
        {
          setPage(0)
          console.log('paginfo',page,pageSize)
          setLoading(true)
          setSelectedTutor(false)
          let res = await getTutor(searchValue,'',true,selectedCity,page,pageSize)
          setCountData(res.count)
          setTutors(res.docs)
          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
          return false;
        }
      }
      fetchData()
    }
    };

  return (
    <>
    {addTutorModal ? <AddTutorModal setModal={setAddTutorModal} setTutors={setTutors}/> : null}
    {showEditModal ? <UpdateTutorModal setModal={setShowEditModal} updateData={showEditModal} _updateTutors={_updateTutors}/> : null}
    <div className="split-main-container"> 
      <div className="tutor-list-container">
        <div className="tutor-header-container">
          {/* <div><input type="text" className="text-input" value={searchValue} onChange={(e)=>requestSearch(e.target.value)} placeholder="Search Tutor"/></div> */}
          <div className='role-based-search-container'>
                  <select className='role-city-select' defaultChecked={selectedCity} onChange={(e)=>setSelectedCity(e.target.value)}>
                    <option value={'all'}>All</option>
                    {
                      authData.user.cities.map((item,index)=>
                      {
                        return <option key={index} value={item._id}>{item.cityName}</option>
                      })
                    }
                  </select>
                  <input placeholder="Search Enquiry" value={searchValue}  className="search-input role-city-search" onChange={(e)=>requestSearch(e.target.value)}/>
                </div>
          <div>
            <button className="xxs-btn" onClick={()=>setAddTutorModal(true)}>Add Tutor</button>
          </div>
        </div>
        <div style={{height:'100%'}}><DataGrid columns={columns} loading={loading} isRowSelectable={false} hideFooterSelectedRowCount={true}  rows={tutors} getRowId={row=>row._id} onRowClick={(item)=>setSelectedTutor(item.row._id)} rowCount={rowCountState} rowsPerPageOptions={[30]} pagination page={page} pageSize={pageSize} paginationMode="server" onPageChange={(newPage) => setPage(newPage)} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}/></div>
      </div>
      {selectedTutor ? <TutorInfo id={selectedTutor} setShowEdit={setShowEditModal} setTutors={setTutors}/> : null}
    </div>
    </>
  );
}
export default Tutors;
