import { useEffect,useState } from "react"
import InfoModal from "../../../components/InfoModal"
import Tag from "../../../components/Tag"
import UpdateNote from "./UpdateNote"
import {languages} from "../../../config/languages"
import EditIcon from "../../../assets/images/edit.png"
import { getTutor } from "../../../components/services"
import { toast } from "react-toastify"
import { TOAST_CONFIG } from "../../../config/constants"
import Loader from "../../../components/Loader"

const TutorInfoPopup = ({info,setModal,updateNote,setUpdateTutor})=>
{

  const [editNote,setEditNote] = useState(null)
  const [data,setData] = useState(null)
  const [loading,setLoading] = useState(true)
  useEffect(()=>
  {
    console.log('info',info)
    async function _getData(){
      try 
      {
        setLoading(true)
        const data = await getTutor('',info._id,true,null,'','');
        setData(data)
        setLoading(false)  
      } catch (error) {
        console.log(error)
        toast.error('Error Occured',TOAST_CONFIG)
        return false
      }
    }
    _getData()
  },[info])
const CategoryItem = ({data,submitData})=>
{
  console.log('cat item',data)
  return (
    <div className='tutor-category-container'>
      <p className='xs-text b-weight f-op'>{data.category.name}</p>
      {
        data.data.map((el,i)=>{
          return <SyllabusItem key={el.syllabus._id} item={el} categoryId={data._id} submitData={submitData}/>
        })
      }
  </div>
)}  

const SyllabusItem = ({item,categoryId,submitData})=>
{
  return (
    <div className='tutor-syllabus-container'>
      <p className='xxs-text m-weight q-op'>{item.syllabus.name}</p>
      {
        item.subjects.map((el,i)=>
        {
          return <Tag title={el.name} key={el._id} id={el._id} syllabusId={item.syllabus._id} categoryId={categoryId} data={data} submitData={submitData}/>
        })
      } 
    </div>
  )
}

let languagesArr =  data?.languages ? data.languages.map(function (e) {
  let item = languages.find(item=>item.code === e)
  if(item) return item.name;
}).join(', ') : '';

  return <InfoModal setModal={setModal}>
    <>
    {editNote ? <UpdateNote setModal={setEditNote} item={data} onClick={updateNote}/> : null}
    { !loading ? <><div className='info-popup-header'>
      <div>
          <p className='r-text m-weight f-op caps'>{data.firstName} {data.lastName}</p>
          <p className='xxs-text r-weight h-op'>{data.mobile}</p>
      </div>
      <div>
        <button className="compact-xs-btn" onClick={()=>setUpdateTutor(data)}>Edit</button>
      </div>
  </div>
  <div className='info-popup-grid'>
          <div>
              <label>Class Mode</label>
              <p className='xxs-text f-op m-weight'>{data.classMode}</p>
          </div>
          <div>
              <label>Mobile {data.primaryMobile === 'primary' ? <span className='primary-badge'>Primary</span> : null}</label>
              <p className='xxs-text f-op m-weight caps'>{data.mobile}</p>
          </div>
          <div>
              <label>Alternative Mobile {data.primaryMobile === 'alternate' ? <span className='primary-badge'>Primary</span> : null}</label>
              <p className='xxs-text f-op m-weight caps'>{data.alternateMobile}</p>
          </div>
          <div>
              <label>Email</label>
              <p className='xxs-text f-op m-weight caps'>{data.email}</p>
          </div>
          <div>
              <label>City</label>
              <p className='xxs-text f-op m-weight'>{Array.isArray(data.city) ? data.city[0].cityName : data.city?.cityName}</p>
          </div>
          <div>
              <label>Locality</label>
              <p className='xxs-text f-op m-weight'>{data.locality}</p>
              <a className='xxxs-text' target="_blank" href={`https://maps.google.com/?q=${data.geo?.lat},${data.geo?.lng}`}>View In Map</a>
          </div>
          <div>
              <label>Address</label>
              <p className='xxs-text f-op m-weight'>{data.address ? data.address : '-'}</p>
          </div>
          <div>
              <label>Gender</label>
              <p className='xxs-text f-op m-weight caps'>{data.gender}</p>
          </div>
          <div>
              <label>Note<span className='tooltip-trigger' onClick={()=>setEditNote(data)}><img src={EditIcon} className="tooltip-trigger-img"/></span></label>
              <p className='xxs-text f-op m-weight caps'>{data.note}</p>
          </div>
          <div>
              <label>Qualification</label>
              <p className='xxs-text f-op m-weight caps'>{data.qualification}</p>
          </div>
          <div>
              <label>Profession</label>
              <p className='xxs-text f-op m-weight'>{data.profession?.name}</p>
          </div>
          <div>
              <label>Experience</label>
              <p className='xxs-text f-op m-weight'>{data.experience}</p>
          </div>
          <div>
              <label>Is Active</label>
              <p className='xxs-text f-op m-weight'>{data.isActive ? 'Active' : 'Inactive'}</p>
          </div>
          <div>
              <label>Is Verified</label>
              <p className='xxs-text f-op m-weight'>{data.isVerified ? 'Verified' : 'Not Verified'}</p>
          </div>
          <div>
              <label>Communication Skills</label>
              <p className='xxs-text f-op m-weight'>{data.communicationSkill ? data.communicationSkill : 'Not Provided'}</p>
          </div>
          <div>
              <label>Languages</label>
              <p className='xxs-text f-op m-weight'>{languagesArr ? languagesArr : 'Not Provided'}</p>
          </div>
         
       </div>
       <div className='tutor-info-subject-container'>
          <div>
          <div className='tutor-info-right-container tutor-popup-expertise-container'>
              {
                  data.expertise.map((i,index)=>
                  {
                  return <CategoryItem data={i}/>
                  })
              }
      </div>
       </div>
  </div></> : <Loader/>}
</>
</InfoModal> 
}

export default TutorInfoPopup