import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createCategory } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'
import { authAxios } from '../../../helpers/axios'

export default function AddCategorySubjectModal({setModal,onSubmit,categoryItem,syllabusItem}) {
    const [subjects,setSubjects] = useState([])
    const [subjectId,setSubjectId] = useState('')
    const [loading,setLoading] = useState(true)

    useEffect(()=>
    {
        async function getSubjects()
        {
            let res = await authAxios.get('/settings/subject')
            let existingSubjects = []
            let allowedSubjects = []
            categoryItem.data.map(i=>
                {
                    if(i.syllabus._id === syllabusItem._id)
                    {
                        i.subjects.map((it)=>
                        {
                            existingSubjects.push(it._id)  
                        })

                    }
                })
                console.log('existing',existingSubjects)
                console.log('data',res.data)
            res.data.map(i=>
            {
                if(existingSubjects.indexOf(i._id) === -1) allowedSubjects.push(i)
            })
            console.log('allowed',allowedSubjects)
            setSubjects(allowedSubjects)
            setLoading(false)
        }
        getSubjects()
    },[])
  return (
    <Modal title="Add Subject" size="md">
        <form onSubmit={(e)=>onSubmit(e,categoryItem._id,syllabusItem._id,subjectId)}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Selected Category</label>
                <div><p className='r-text r-weight f-op'>{categoryItem.name}</p></div>
            </div>
            
            <div className="input-group">
                <label>Selected Syllabus</label>
                <p className='r-text r-weight f-op'>{syllabusItem.name}</p>
            </div>

            <div className="input-group">
                <label>Select Subject</label>
                <select required defaultChecked="" onChange={(e)=>setSubjectId(e.target.value)}>
                    <option value={''} defaultChecked></option>
                    {
                        subjects.map((item,index)=>{
                            return <option value={item._id}>{item.name}</option>
                        })
                    }
                </select>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setModal(false)}/>
            <input type="submit" className="xs-delete-btn" value="Add Subject"/>
        </div>
        </form>
    </Modal>
  )
}
