import React,{useEffect, useState} from 'react'
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import PlacesAutocomplete, {geocodeByAddress,getLatLng} from "react-places-autocomplete";
import { geocodeByPlaceId } from 'react-places-autocomplete';

export default function AutocompleteInput({setData,addressText='',type='locality',restriction}) {
    // const [address,setAddress] = useState({name:'',geo:{lat:'',lan:''}})
    // const [addressValue,setAddressValue] = useState('')
    // const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    // useGoogle({
    //   apiKey: "AIzaSyApwmz9qlKKyxQXyAIavojKrjwLNCkJ-wM",
    // });

    const [searchOptions,setSearchOptions] = useState()

    // useEffect(()=>
    // {
    //   if(restriction) setSearchOptions(prev=>({...prev,}))
    // },[restriction])

      const handleChangeAddress = (address) => {
        setData(prev=>({...prev,locality:address}));
    // geocodeByAddress(address)
    //   .then((results) => {
    //     setData(prev=>({...prev,locality:address}));
    //   });
  };


      // const handleAddressSelect = (address) => {
      //   let geo ={}

      //   geocodeByAddress(address)
      //   .then((results) => getLatLng(results[0]))
      //   .then((latLng) => {
      //       geo = latLng;
      //       setData(prev=>({...prev,locality:address,geo}));
      //   })
      //   .catch((error) => console.error("Error", error));
      //   };

        const handleAddressSelect = (address,placeId,suggestion) => {
          geocodeByPlaceId(placeId)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            console.log({locality:suggestion.formattedSuggestion.mainText,latLng})
              setData(prev=>({...prev,locality:suggestion.formattedSuggestion.mainText,geo:latLng}));
          })
          .catch((error) => console.error("Error", error));
          };
        
  return <>
        <PlacesAutocomplete
        value={addressText}
        shouldFetchSuggestions={addressText.length > 3}
        searchOptions={{
          types:['(regions)'],
          // radius:2000,
          componentRestrictions:{country:'in'},
          // ...(restriction && {location:{lat:restriction.lat, lng:restriction.lng}})
        }}
        onChange={handleChangeAddress}
        onSelect={handleAddressSelect}
        highlightFirstSuggestion={true}
        >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }) => (
          <div className="autocomplete-input">
            <input type="text" value={addressText} required autoComplete="off"
              {...getInputProps({
                placeholder: "",
                className: "location-search-input",
              })}
            />
            <div
              className="autocomplete-dropdown-container"
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item-active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        </PlacesAutocomplete>
    </>
}
