import React, { useState, useEffect } from "react";
import Addemployee from "./employees/AddEmployee";
import EmployeeModal from "./employees/EmployeeModal";
import Updateemployee from "./employees/UpdateEmployee";
import { getEmployees } from "../components/services";
import { DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

export default function Employees() {
  const [employeeData, setemployeeData] = useState([]);
  const [currentEmployee, setcurrentEmployee] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateEmployeemodal, setupdateEmployeemodal] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      const employeeData = await getEmployees();
      console.log("employee data ", employeeData);
      setemployeeData(employeeData);
    };
    fetchData();
  }, []);

  const columns =[
    {field:'firstName',headerName:'First Name',flex:1},
    {field:'lastName',headerName:'Last Name',flex:1},
    {field:'email',headerName:'Email',flex:1},
    {field:'mobile',headerName:'Mobile',flex:1},
    {field:'role',headerName:'Role',flex:1},
    {field:'active',headerName:'Status',flex:1,renderCell:(params)=>
    {
      return <div><div className={params.row.active ? 'status-badge-active' :'status-badge'}><p className="xs-text m-weight">{params.row.active ? 'Active' : 'Inactive'}</p></div></div>
    }},
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={()=>
            {
              let item = employeeData.filter((i,index)=>{
                console.log(i)
                return i._id === params.id
              })
              console.log('item',item)
              setupdateEmployeemodal(item[0])
          }}
        />
      ],
    },
  ]

  return (
    <>
    {isModalVisible ? <EmployeeModal setemployeeData={setemployeeData} setModalVisible={setIsModalVisible} title="Add Employee"/> : null}
    {updateEmployeemodal ? <EmployeeModal setemployeeData={setemployeeData} setModalVisible={setupdateEmployeemodal} title="Update Employee" updateData={updateEmployeemodal ? updateEmployeemodal : null}/> : null}
    <div className="list-content">
      <div className="list-content-header">
        <div>
          <h2 className="lg-text b-weight">Employees</h2>
        </div>
        <div className="d-flex">
          <button type="button" className="xs-compact-inverted-btn" onClick={()=>setIsModalVisible(true)}>Add Employees</button>
        </div>
      </div>
      <div style={{height:'100%',width:'100%'}}>
        <DataGrid sx={{'border':'0'}} getRowId={row=>row._id} disableColumnSelector={true} columns={columns} rows={employeeData} disableSelectionOnClick={true}/>
      </div>
    </div>
    </>
  );
}
