import React,{useState} from 'react'
import Modal from '../../../components/Modal'

export default function ApproveModal({setApproveItem,onClick,item}) {

  const [submitting,setSubmitting] = useState(false)
  const [approveBroadcast,setApproveBroadcast] = useState(true)
  return (
    <Modal title="Approve Enquiry" size="md">
        <div className="modal-inner-content">
            <p className="r-text m-weight h-op mb-md">Are your sure you want to approve this enquiry?</p>
            <p className="xs-text m-weight mb-md">The Enquiry made by {item.addedBy?.firstName} {item.addedBy?.lastName}</p>
            <div className='approve-broadcast-group input-group-divided'>
              <div>
                <label>Send Broadcast Message?</label>
                <select onChange={(e)=>setApproveBroadcast(e.target.value)} defaultChecked={approveBroadcast} value={approveBroadcast}>
                  <option value={true}>Send</option>
                  <option value={false}>Don't Send</option>
                </select>
              </div>
            </div>
        </div>
        <div className="modal-footer">
            <button className="xs-neutral-btn" onClick={()=>setApproveItem(false)}>Cancel</button>
            <button className="xs-approve-btn" onClick={()=>onClick({_id:item._id,sendBroadcast:approveBroadcast},setSubmitting)} disabled={submitting}>Approve</button>
        </div>
    </Modal>
  )
}
