const getDate = (dateString,fulldate = true)=>
{
    let d = new Date(dateString)
    let datePart = d.getDate() <10 ? `0${d.getDate()}` : d.getDate()
    let monthPart = (d.getDate()+1) <10 ? `0${d.getMonth()+1}` : d.getMonth()+1
    let yearPart = d.getFullYear().toString().substring(2)
    let dayPart = d.getDay()
    let dayText;
    if(dayPart === 0) dayText = 'Sun'
    else if(dayPart === 1) dayText = 'Mon'
    else if(dayPart === 2) dayText = 'Tue'
    else if(dayPart === 3) dayText = 'Wed'
    else if(dayPart === 4) dayText = 'Thu'
    else if(dayPart === 5) dayText = 'Fri'
    else if(dayPart === 6) dayText = 'Sat'
    if(fulldate) return `${datePart}/${monthPart}/${yearPart} - ${dayText}` 
    else return `${datePart}/${monthPart}-${dayText}`
}

const getTime = (timeString)=>
{
    let d = new Date(timeString)
    let hourPart = d.getHours() <10 ? `0${d.getHours()}` : d.getHours()
    let minutePart = (d.getDate+1) <10 ? `0${d.getMonth()+1}` : d.getMonth+1()
    return `${hourPart}-${minutePart}-${d.getHours() < 13 ? 'Am' : 'Pm'}` 

}

const getTimeFromDigit = (string)=>
{
    // let [hour,minute] = string.split("":")
    // let hourPart =  hour <10 ? `0${d.getHours()}` : d.getHours()
    // let minutePart = (d.getDate+1) <10 ? `0${d.getMonth()+1}` : d.getMonth+1()
    // return `${hourPart}-${minutePart}-${d.getHours() < 13 ? 'Am' : 'Pm'}` 
    let time = string.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [string];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' Am' : ' Pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join('');

}

export {getDate,getTimeFromDigit,getTime}