import React from 'react'

export default function Tag({editable=false,title="",onClick}) {
  return (
    <div className="tag">
        <div className="tag-content">
            <div><p className="xs-text m-weight">{title}</p></div>
            {editable ? <div className='tag-close-btn' onClick={onClick}><p>x</p></div> : null}
        </div>
    </div>
  )
}


