import React from 'react'
import { getInitials } from '../helpers/utils'

export default function AssignedIndicator({firstName,lastName,left=false}) {
  return (
    firstName && lastName ? <>
        <span title={`${firstName} ${lastName}`} className={`assigned-to-indicator ${left ? 'assigned-to-left':''}`}><p>{getInitials( firstName,lastName)}</p></span>
    </> : ''
  )
}
