import React, { useEffect, useState } from 'react'

export default function SelectableTag({title="",_onSelect,categoryId,syllabusId,id,submitData}) {
  
  const [selected,setSelected] = useState(false)
  useEffect(()=>
  {
    let categoryIndex = submitData.expertise?.findIndex(o => o.category === categoryId);
    if(categoryIndex > -1)
    {
      // console.log('selected',categoryId,syllabusId,id,categoryIndex)
      // console.log('sd',submitData.expertise)
      let syllabusIndex = submitData.expertise[categoryIndex]?.data?.findIndex(o => o.syllabus === syllabusId);
      // console.log('category exist',categoryId,categoryIndex)
      if(syllabusIndex > -1)
      {
        let subjectExist = submitData.expertise[categoryIndex].data[syllabusIndex]?.subjects.findIndex(o=>o === id)
        if(subjectExist > -1) setSelected(true)
        else setSelected(false)
      }
      else setSelected(false)
    } else setSelected(false)
  },[submitData])
  return (
    <div className={`tag-selectable ${selected ? 'tag-selectable-active' : ''} `} onClick={()=>_onSelect(id,syllabusId,categoryId)}>
        <div className="tag-selectable-content">
            <div><p className="xs-text m-weight">{title}</p></div>
        </div>
    </div>
  )
}


