import React,{useEffect, useState} from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { TOAST_CONFIG, TUITION_STATUS } from '../../../config/constants'
import {initiateTution} from '../../../components/services'
import FileContainer from '../../../components/FileContainer'

export default function UpdateTuition({setModal,onClick,item}) {


    const [data,setData] = useState({fee:item.fee,duration:item.duration,days:item.days,startDate:item.startDate,tutorPaymentConfirmed:item.tutorPaymentConfirmed,clientPaymentConfirmed:item.clientPaymentConfirmed,courier:item.courier,photo:item.photo,addressProof:item.addressProof,enquiry:item.enquiry._id,customer:item.customer._id,demoId:item._id,tutor:item.tutor._id,_id:item._id,commissionToCompany:item.commissionToCompany,status:item.status,feeType:item.feeType})

    const [loading,setLoading] = useState(false)


    const _formattedDate = ()=>
    {
       let date = new Date(data.startDate)
       return `${date.getFullYear()}-${date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1 }-${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}` 
    }



  return (
    <Modal title="Update Tuition Info" size="lg">
      <form onSubmit={(e)=>onClick(e,data,setLoading)}>
        <div className="modal-inner-content">
            <div className='input-group-divided-three'>
            <div>
                    <label>Fee Range (in INR)*</label>
                    <div className="fee-type-flex">
                    <select value={data.feeType} defaultChecked={data.feeType} required className="fee-type-select" onChange={(e)=>setData(prev=>({...prev,feeType:e.target.value}))}>
                        <option value='hourly'>Hourly</option>
                        <option value='monthly'>Monthly</option>
                        <option value='onetime'>One Time</option>
                    </select>
                    <input type="number" className="text-input fee-input" required value={data.fee} onChange={(e)=>setData(prev=>({...prev,fee:e.target.value}))}/>
                    </div>
                </div>
              <div>
                    <label>Duration (in Hrs)*</label>
                    <select value={data.duration} defaultChecked={data.duration} required onChange={(e)=>setData(prev=>({...prev,duration:e.target.value}))}>
                        <option value='1'>1</option>
                        <option value='1.5'>1.5</option>
                        <option value='2'>2</option>
                        <option value='2.5'>2.5</option>
                        <option value='3'>3</option>
                        <option value='3+'>3+</option>
                    </select>
                </div>
              <div>
                <label>Days (In A Week)</label>
                <input className='text-input' type="text" value={data.days} onChange={(e)=>setData(prev=>({...prev,days:e.target.value}))}/>
              </div>
              <div>
                <label>Starting Date</label>
                <input className='text-input' type={'date'} value={_formattedDate(data.startDate)} onChange={(e)=>setData(prev=>({...prev,startDate:e.target.value}))}/>
              </div>
              <div>
                <label>Commission to company</label>
                <input className='text-input' type={'text'} onChange={(e)=>setData(prev=>({...prev,commissionToCompany:e.target.value}))} value={data.commissionToCompany}/>
              </div>
              <div>
                <label>Customer</label>
                <input className='text-input' type={'text'} disabled value={`${item.customer.firstName} ${item.customer.lastName}`}/>
              </div>
              <div>
                    <label>Status*</label>
                    <select value={data.status} defaultChecked={data.status} required onChange={(e)=>setData(prev=>({...prev,status:e.target.value}))}>
                        {/* <option value={1}>Ongoing</option> */}
                        {
                          TUITION_STATUS.map((item,index)=>
                          {
                            return <option key={index} value={item.status}>{item.statusName}</option>
                          })
                        }
                        {/* <option value={2}>Requesting Payment Link</option>
                        <option value={3}>Created Payment Link</option>
                        <option value={4}>Payment Link Sent</option>
                        <option value={5}>Paid</option>
                        <option value={10}>Dispute</option> */}
                    </select>
                </div>
                <div></div>
                <div></div>
              <div className='checkbox-input-group'>
                <input id="tutorPayment" type={'checkbox'} defaultChecked={data.tutorPaymentConfirmed} onChange={(e)=>setData(prev=>({...prev,tutorPaymentConfirmed:e.target.checked}))}/>
                <label >Tutor Payment Confirmed</label>
              </div>
              <div className='checkbox-input-group'>
                <input id="clientPayment" type={'checkbox'} defaultChecked={data.clientPaymentConfirmed} onChange={(e)=>setData(prev=>({...prev,clientPaymentConfirmed:e.target.checked}))}/>
                <label htmlFor='clientPayment'>Client Payment Confirmed</label>
              </div>
              <div className='checkbox-input-group'>
                <input id='courier' type={'checkbox'} defaultChecked={data.courier} onChange={(e)=>setData(prev=>({...prev,courier:e.target.checked}))}/>
                <label htmlFor='courier'>Courier Sent</label>
              </div>
              <div className='checkbox-input-group'>
                <input id='photo' type={'checkbox'} defaultChecked={data.photo}  onChange={(e)=>setData(prev=>({...prev,photo:e.target.checked}))}/>
                <label htmlFor='photo'>Photo Received</label>
              </div>
              <div className='checkbox-input-group'>
                <input id='addressProof' type={'checkbox'} defaultChecked={data.addressProof}  onChange={(e)=>setData(prev=>({...prev,addressProof:e.target.checked}))}/>
                <label htmlFor='addressProof'>Address Proof</label>
              </div>
            </div>
        </div>
        <div className="modal-footer">
            <input type={'button'} className="xs-neutral-btn" onClick={()=>setModal(false)} value="Cancel"/>
            <input type={'submit'} className="xs-delete-btn" disabled={loading} value="Update Tuition Now"/>
        </div>
      </form>
    </Modal>
  )

}