import React, { useState } from 'react'
import FileContainer from '../../../components/FileContainer'
import Modal from '../../../components/Modal'
import { authAxios } from '../../../helpers/axios'

export default function ViewPaymentModal({setModal,data}) {
    // const [paymentInfo,setPaymentInfo] = useState({
    //     periodCount:data && data.periodCount ? data.periodCount : '',
    //     periodType:data && data.periodType ? data.periodType : 'class',
    //     totalFee:data && data.totalFee ? data.totalFee : '',
    //     feeToCompany:data && data.feeToCompany ? data.feeToCompany : '',
    //     feeToTutor:data && data.feeToTutor ? data.feeToTutor : '',
    //     paymentLink:data && data.paymentLink ? data.paymentLink : '',
    //     paymentProof:data && data.paymentProof ? data.paymentProof : '',
    //     classProof:data && data.classProof ? data.classProof : '',
    // }) 

    // const uploadFile = async(file,fieldType)=>
    // {
    //   let formData = new FormData();
    //   formData.append("file", file);
    //   formData.append("id", data._id);
    //   try 
    //   {
    //     let res = await authAxios({
    //       method: "post",
    //       url: `/upload/${fieldType}?id=${data._id}`,
    //       data: formData,
    //     })
    //     if(fieldType === 'paymentPaymentProof') setPaymentInfo(prev=>({...prev,paymentProof:res.data.filename}))
    //     else setPaymentInfo(prev=>({...prev,classProof:res.data.filename}))
    //     // setData(prev=>
    //     //   {
    //     //     let prevData = [...prev]
    //     //     let index = prevData.findIndex(i=>i._id === data._id)
    //     //     prevData[index].paymentProof = res.data.filename
    //     //     console.log(prevData)
    //     //     return prevData
    //     //   })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  return (
    <Modal title={'Payment Info'} size="md">
        {/* <form onSubmit={data ? (e)=>onSubmit(e,data._id,paymentInfo) : (e)=>onSubmit(e,paymentInfo)}> */}
        <div className='modal-inner-content'>
        <div className='input-group-divided'>
      <div>
              <label>Class / Hours Taken</label>
              <div className="fee-type-flex">
                <p className='xs-text m-weight caps f-op'>{data.periodType} - {data.periodCount}</p>
              </div>
        </div>
        <div>
          <label>Total Fee</label>
          <p className='xs-text m-weight caps f-op'>{data.totalFee}</p>
        </div>
        <div className='payment-link-span'>
          <label>Payment Link</label>
          <p className='xs-text m-weight f-op'>{data.paymentLink ? data.paymentLink : 'Not Available'}<button className='copy-btn' onClick={() => {navigator.clipboard.writeText(data.paymentLink)}}>Copy</button></p>
        </div>
        <div>
          <label>Fees to company</label>
          <p className='xs-text m-weight caps f-op'>{data.feeToCompany ? data.feeToCompany : 'Not Available'}</p>
        </div>
        <div>
          <label>Fees to tutor</label>
          <p className='xs-text m-weight caps f-op'>{data.feeToTutor ? data.feeToTutor : 'Not Available'}</p>
        </div>
        
        <div>
          <label>Class Proof</label>
          <FileContainer  type={'paymentClassProof'} prevFile={data.classProof} readOnly={true}/>
        </div>
        <div>
          <label>Payment Proof</label>
          <FileContainer type={'paymentPaymentProof'} prevFile={data.paymentProof} readOnly={true}/>
        </div>
{/* 
        { data ? <div className='checkbox-input-group payment-checkbox-container'>
          <input type={'checkbox'} onChange={(e)=>setPaymentInfo(prev=>({...prev,paymentClosed:e.target.checked}))}/>
          <p className='xs-text f-op'>Complete Payment <span className='xxxs-text q-op brand-text'>(Can't undo the process)</span></p>
        </div> : null} */}
    </div> 
    </div>
    <div className={`modal-footer`}>
          {/* {data ? <input type="button" className="tertiary-btn" onClick={()=>setModal(false)} value="Complete Payment"/> : null}
      <div className='flex'> */}
          <input type="button" className="xs-neutral-btn" onClick={()=>setModal(false)} value="Close"/>
      {/* </div> */}
    </div>
    {/* </form> */}
    </Modal>
  )
}
