import React, { useState ,useEffect} from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { getLeadDetail } from '../../components/services';
import { TOAST_CONFIG } from '../../config/constants';
import { getDate } from '../../helpers/dateHelper';
// import AddEnquiryModal from './AddEnquiryModal';

export default function LeadInfo({viewItem,setTerminateItem,setApproveItem,setEnquiryData,setViewItem}) {

    const [data,setData] = useState({})
    const [loading,setLoading] = useState(true)
    const [updateData,setUpdateData] = useState(false)
    const authInfo = useSelector(state=>state.auth)
    
    const getSubjects = (subject)=>
    {
        return subject.map(function (e) {
            return e.name;
          }).join(', ');
    }

      useEffect(() => {
        const getInfo = async()=>
        {
            try {
                setLoading(true)
                let res = await getLeadDetail(viewItem._id)
                setData(res)
                setLoading(false)
                return true;
            } catch (error) {
                console.log(error)
                toast.error('error',TOAST_CONFIG)
                setLoading(false)
                return false;
            }
        }
        getInfo()
      }, [viewItem])
      
  return (<div className='loader-parent'>
      { !loading ? <>
      {/* {updateData ? <AddEnquiryModal setModal={setUpdateData} setViewItem={setViewItem} updateId={updateData._id} setEnquiryData={setEnquiryData}/> : null} */}
    <div className="pending-info-container">
        <div className="pending-info-content">
            <div className="pending-info-header">
                <div>
                    <p className="r-text m-weight f-op caps">{data.firstname} {data.lastname}</p>
                    {/* <p className="xxs-text m-weight h-op uppercase">{data.enquiryId ? data.enquiryId : '-'}</p> */}
                </div>
                {/* <div className="pending-info-btn-container">
                    <button className="tertiary-btn" onClick={()=>setUpdateData(data)}>Update</button>
                    {
                        authInfo.user.role === 'manager' ? <><button className="xs-delete-btn" onClick={()=>setTerminateItem(data)}>Terminate</button>
                        <button className="xs-approve-btn" onClick={()=>setApproveItem(data)}>Approve</button></> : null   
                    }
                </div> */}
            </div>
            <div className="pending-inner-content">
                <div className="pending-info-grid">

                    <div>
                        <p className="xxs-text h-op m-weight">Mobile {data.mobileVerified === true ? <span className='primary-badge'>Verified</span> : null}</p>
                        <p className="xs-text f-op m-weight">{data.mobile}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Email</p>
                        <p className="xs-text f-op m-weight">{data.email}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Locality</p>
                        <p className="xs-text f-op m-weight">{data.locality} {data.city?.cityName ? data.city.cityName : '-'}</p>
                        {/* <a className='xxs-text' target="_blank" href={`https://maps.google.com/?q=${data.geo.lat},${data.geo.lng}`}>View In Map</a> */}
                    </div>

                    
                    <div>
                        <p className="xxs-text h-op m-weight">Standard</p>
                        <p className="xs-text f-op m-weight caps">{data.standard}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Subjects</p>
                        <p className="xs-text f-op m-weight caps">{data.subjects}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Note</p>
                        <p className="xs-text f-op m-weight caps">{data?.note}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Timing</p>
                        <p className="xs-text f-op m-weight caps">{data.timing}</p>
                    </div>

                    <div>
                        <p className="xxs-text h-op m-weight">Gender</p>
                        <p className="xs-text f-op m-weight caps">{data.gender}</p>
                    </div>
                    <div>
                        <p className="xxs-text h-op m-weight">Importance</p>
                        <p className="xs-text f-op m-weight caps">{data.importance}</p>
                    </div>
                    <div>
                        <p className="xxs-text h-op m-weight">Status</p>
                        <p className="xs-text f-op m-weight caps">{data.status === 0 ? 'Open' : data.status === 1 ? 'Converted' : 'Cancelled'}</p>
                    </div>
                </div>
            </div>
        </div>
    </div></> : <Loader/> } </div>
  )
}
