import React,{useState} from 'react'
import Modal from '../../../components/Modal'

export default function CancelDemoModal({setCancelDemo,onClick,item}) {

    const [data,setData] = useState({demoId:item._id,cancellationReason:'',tutorCancelled:false})
  return (
    <Modal title="Cancel Demo" size="md">
        <form>
        <div className="modal-inner-content">
            <div className='input-group'>
              <label>Reason for Cancellation</label>
              <textarea rows={3} draggable={false} value={data.cancellationReason} className="text-input" style={{'resize':'none'}} onChange={(e)=>setData(prev=>({...prev,cancellationReason:e.target.value}))}/>
            </div>
            <div className='checkbox-input-group'>
                <input id="clientPayment" type={'checkbox'} onChange={(e)=>setData(prev=>({...prev,tutorCancelled:e.target.checked}))}/>
                <label htmlFor='clientPayment'>Tutor Mistake</label>
              </div>
        </div>
        <div className="modal-footer">
            <input type="button" className="xs-neutral-btn" onClick={()=>setCancelDemo(false)} value="Cancel"/>
            <input type="submit" className="xs-delete-btn" onClick={(e)=>onClick(e,data)} value="Cancel Demo"/>
        </div>
        </form>
    </Modal>
  )
}
