import React from "react";
import { NavLink,Outlet } from "react-router-dom";


function Enqueries() {
  
  return (
    <>
    <div className="sub-nav">
        <div className="sub-nav-container">
            <NavLink to="/dashboard/enqueries/pending" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Pending</NavLink>
            <NavLink to="/dashboard/enqueries/mapping" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Mapping</NavLink>
            <NavLink to="/dashboard/enqueries/demo" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Demo Class</NavLink>
            <NavLink to="/dashboard/enqueries/tuition" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>Tuition Class</NavLink>
            <NavLink to="/dashboard/enqueries/all" className={({isActive})=>`sub-nav-item ${isActive ? 'sub-nav-item-active' : ''}`}>All Enqueries</NavLink>
        </div>
    </div>
    <Outlet/>
    </>
  );
}
export default Enqueries;
