import React,{useState} from 'react'
import Modal from '../../../components/Modal'

export default function TerminateModal({setTerminateItem,onClick,item}) {

    const [terminationReason,setTerminationReason] = useState('')
    const [loading,setLoading] = useState(false) 
  return (
    <Modal title="Terminate Enquiry" size="md">
        <div className="modal-inner-content">
            <label>Reason for Termination</label>
            <textarea rows={3} draggable={false} value={terminationReason} className="text-input" style={{'resize':'none'}} onChange={(e)=>setTerminationReason(e.target.value)}/>
        </div>
        <div className="modal-footer">
            <input type={'button'} className="xs-neutral-btn" onClick={()=>setTerminateItem(false)} value="Don't Terminate"/>
            <input type={'submit'} className="xs-delete-btn" disabled={loading} onClick={(e)=>onClick(e,{_id:item._id,terminationReason:terminationReason},setLoading)} value="Terminate"/>
        </div>
    </Modal>
  )
}
