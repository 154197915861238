import TimePicker from 'rc-time-picker'
import React,{useState} from 'react'
import Modal from '../../../components/Modal'

export default function RescheduleDemo({setRescheduleDemo,onClick,demoItem}) {

    const [data,setData] = useState({date:'',time:'',rescheduledReason:'',demoId:demoItem._id,tutorRescheduled:false})
  return (
    <Modal title="Reschedule Demo" size="md">
        <form>
            <div className="modal-inner-content">
                <div className="input-group-divided">
                    <div>
                    <label>Demo Time*</label>
                    <input type="date" value={data.date} className="text-input" onChange={(e)=>setData(prev=>({...prev,date:e.target.value}))}/>
                    </div>
                    <div>
                        <label>Demo Time*</label>
                        <TimePicker showSecond={false} defaultValue={data.time} allowEmpty={false} className="time-input" popupClassName="time-select-popup" onChange={(value)=>setData(prev=>({...prev,time:value && value.format('h:mm a')}))} minuteStep={15} format={'h:mm a'} use12Hours inputReadOnly/>
                    </div>
                </div>

                <div className="input-group">
                    <label>Reason</label>
                    <textarea rows={3} draggable={false} value={data.rescheduledReason} className="text-input" style={{'resize':'none'}} onChange={(e)=>setData(prev=>({...prev,rescheduledReason:e.target.value}))}/>
                </div>
                <div className='checkbox-input-group'>
                <input id="clientPayment" type={'checkbox'} onChange={(e)=>setData(prev=>({...prev,tutorRescheduled:e.target.checked}))}/>
                <label htmlFor='clientPayment'>Tutor Rescheduled</label>
              </div>
            </div>

            <div className="modal-footer">
                <input type="button" className="xs-neutral-btn" onClick={()=>setRescheduleDemo(false)} value="Cancel"/>
                <input type="submit" className="xs-delete-btn" disabled={!data.time || !data.date} onClick={(e)=>onClick(e,data)} value="Reschedule"/>
            </div>
        </form>
    </Modal>
  )
}
