import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import React, { useEffect,useState,useCallback } from 'react'
import { getCity,getProfessions,getState } from '../../components/services';
import {useNavigate} from 'react-router-dom'
import ShowCategoryInfo from './components/ShowCategoryInfo';
import AddCategoryModal from './components/AddCategoryModal';
import AddSyllabusModal from './components/AddSyllabusModal';
import AddSubjectModal from './components/AddSubjectModal';
import AddCityModal from './components/AddCityModal';
import AddStateModal from './components/AddStateModal';
import EditCityModal from './components/EditCityModal';
import EditStateModal from './components/EditStateModal';
import AddProfessionModal from './components/AddProfessionModal';
import EditProfessionModal from './components/EditProfessionModal';

export default function Profession() {

    const [professions,setProfessions] = useState([])
    const [addProfessionModal,setAddProfessionModal] = useState(false)
    const [editProfessionModal,setEditProfessionModal] = useState(false)
    const getData = useCallback(async ()=>{
        const professionData = await getProfessions();
        setProfessions(professionData)
    },[])
    useEffect(()=>
    {
        getData()
    },[getData])

    const columns = [
        {field:"name",headerName:"Name",flex:1},
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
        <GridActionsCellItem
              label="Edit"
              showInMenu={true}
              onClick={()=>
                {
                  let item = professions.filter((i,index)=>{
                    console.log(i._id === params.id,i,params.id)
                    return i._id === params.id
                  })
                  console.log('selected',item)
                  setEditProfessionModal(item[0])
              }}
            />
    ]}
    ]
  return (
    <>
    { addProfessionModal ? <AddProfessionModal setModal={setAddProfessionModal} setProfessions={setProfessions}/> : null}
    { editProfessionModal ? <EditProfessionModal setModal={setEditProfessionModal} setProfessions={setProfessions} data={editProfessionModal}/> : null}
    <div className="split-contained-container">
      <div className="split-left-content">
        <div class="list-content-header">
            <div>
            <h2 className="lg-text b-weight">Professions</h2>
            </div>
            <div class="d-flex">
            <button type="button" class="xs-compact-inverted-btn" onClick={()=>setAddProfessionModal(true)}>Add Profession</button>
            </div>
        </div>
        <div style={{height:'500px',width:'100%'}}>
        <DataGrid sx={{border:0}} disableColumnSelector={true} columns={columns} rows={professions} getRowId={item=>item._id} disableSelectionOnClick={true}/>
        </div>
      </div>
    </div>
    </>
  )
}
