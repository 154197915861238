import { useState } from "react";
import InfoModal from "../../../components/InfoModal"
import { getDate } from "../../../helpers/dateHelper";
import EditIcon from "../../../assets/images/edit.png"
import UpdateNote from "./UpdateNote";

const SecondEnquiryInfoPopup = ({data,setModal,updateNote})=>
{
    const [editNote,setEditNote] = useState(null)
    let subjects = data.enquiry.studentSubjects.map(function (e) {
        return e.name;
      }).join(', ');

  return  <InfoModal setModal={setModal}>
  {editNote ? <UpdateNote setModal={setEditNote} item={editNote} onClick={updateNote}/> : null}
  <div className='info-popup-header'>
      <div>
          <p className='r-text m-weight f-op caps'>{data.enquiry.customer.firstName} {data.enquiry.customer.lastName}</p>
          <p className='xxs-text m-weight q-op uppercase'>{data.enquiry.enquiryId ? data.enquiry.enquiryId : 'Not Available'} {data.enquiry.isPremium === true ? <span className='premium-badge'>Premium</span> : null}</p>
      </div>
  </div>
  <div className='info-popup-grid'>
    <div>
        <label>Mobile {data.enquiry.primaryMobile === 'primary' ? <span className='primary-badge'>Primary</span> : null}</label>
        <p className="xxs-text f-op m-weight">{data.enquiry.mobile}</p>
    </div>

    <div>
        <label>Alternative Mobile {data.enquiry.primaryMobile === 'alternate' ? <span className='primary-badge'>Primary</span> : null}</label>
        <p className="xxs-text f-op m-weight">{data.enquiry.alternateMobile}</p>
    </div>

    <div>
        <label>Email</label>
        <p className="xxs-text f-op m-weight">{data.enquiry.customer.email}</p>
    </div>

    <div>
        <label>City</label>
        <p className="xxs-text f-op m-weight">{data.enquiry.city.cityName}</p>
    </div>

    <div>
        <label>Locality</label>
        <p className="xxs-text f-op m-weight">{data.enquiry.locality}</p>
        <a className='xxxs-text' target="_blank" href={`https://maps.google.com/?q=${data.enquiry.geo.lat},${data.enquiry.geo.lng}`}>View In Map</a>
    </div>

    
    <div>
        <label>Landmark</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.landmark}</p>
    </div>

    <div>
        <label>Address</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.address}</p>
    </div>

    <div>
        <label>Enquiry Type</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.enquiryType}</p>
    </div>

    <div>
        <label>Category/Standard</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.studentCategory.name} {data.enquiry.standard ? `/ ${data.enquiry.standard}` : ` / -`}</p>
    </div>

    <div>
        <label>Syllabus</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.studentSyllabus.name}</p>
    </div>
    <div>
        <label>Subjects</label>
        <p className="xxs-text f-op m-weight caps">{subjects}</p>
    </div>
    <div>
        <label>No. of Days(Per Week)</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.days}</p>
    </div>

    <div>
        <label>Class Duration / Timing Preference</label>
        <p className="xxs-text f-op m-weight">{data.enquiry.classDuration} Hour {data.enquiry.timingPreference ? ` / ${data.enquiry.timingPreference}` : ' / -'}</p>
    </div>

    <div>
        <label>Tutor Gender</label>
        <p className="xxs-text f-op m-weight">{data.enquiry.tutorGender}</p>
    </div>

    <div>
        <label>Fee Type/Amount</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.feeType} / {data.enquiry.feeAmount}</p>
    </div>

    <div>
        <label>Note<span className='tooltip-trigger' onClick={()=>setEditNote(data.enquiry)}><img src={EditIcon} className="tooltip-trigger-img"/></span></label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.note}</p>
    </div>
    
    <div>
        <label>Source</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.source?.name}</p>
    </div>

    <div>
        <label>Created At</label>
        <p className="xxs-text f-op m-weight caps">{getDate(data.enquiry.createdAt,false)}</p>
    </div>

    <div>
        <label>Created By</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.addedBy ? `${data.enquiry.addedBy.firstName} ${data.enquiry.addedBy.lastName} (${data.enquiry.addedBy.role})` : 'Not Available'}</p> 
    </div>

    <div>
        <label>Assigned To</label>
        <p className="xxs-text f-op m-weight caps">{data.enquiry.assignedTo ? `${data.enquiry.assignedTo.firstName} ${data.enquiry.assignedTo.lastName} (${data.enquiry.assignedTo.role})` : 'Not Available'}</p> 
    </div>

    {data.enquiry.isPremium ? <div>
        <label>Premium Payment Link</label>
        <p className="xxs-text f-op m-weight">{data.enquiry.premiumPayment} <button className='copy-btn' onClick={() => {navigator.clipboard.writeText(data.enquiry.premiumPayment)}}>Copy</button></p> 
    </div> : null}

       </div>
</InfoModal>
}

export default SecondEnquiryInfoPopup