import React, { useRef } from 'react'
import { BASE_URL, STATIC_URL } from '../config/constants';
import { authAxios } from '../helpers/axios';

export default function FileContainer({type,id,prevFile,onChange,readOnly=false}) {

    const fileRef = useRef(null)
    let _change = (e)=>
    {
        if(e.target.files.length === 1) onChange(e.target.files[0],type)
        else return false
    }
  return (
    <div className='file-container'>
        <div className='file-container-content'>
            {prevFile ? <div>
                <a className='xxs-btn' href={`${process.env.REACT_APP_STATIC_URL}static/${type}/${prevFile}`} download={prevFile} target="_blank">
                    Download
                </a>
                <a className='xxs-btn view-btn' href={`${process.env.REACT_APP_STATIC_URL}static/${type}/${prevFile}`} target={'_blank'}>
                    View
                </a>
                </div> : <p className='xs-text m-weight f-op'>No file Uploaded</p>}
        </div>
        { !readOnly ? <><input type={'file'} ref={fileRef} className="file-container-input" onChange={_change}/>
        <input type={'button'} className='compact-xs-btn' onClick={()=>fileRef.current.click()} value="Change"/></> : null}
    </div>
  )
}
