import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { updateState } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'

export default function EditStateModal({setModal,setStates,data}) {
    const [name,setName] = useState(data.name)
    const _editState = async(e)=>
    {
        e.preventDefault();
        try 
        {
            let res = await updateState(name,data._id)
            setStates(prev=>{
                let newItem = [...prev];
                console.log(data._id)
                let updatedData= [];
                newItem.map(i=>{
                    console.log('check',i._id === data._id,i._id,data._id)
                    if(i._id === data._id) 
                    {
                        console.log('returning',{name:res.name,_id:res._id})
                        updatedData.push({name:res.name,_id:res._id})
                    }
                    else updatedData.push(i)
                })
                return updatedData
            })
            setModal(false)

        } catch (error) {
            toast.error(error,TOAST_CONFIG)
        }
    }
  return (
    <Modal title="Edit State" size="md">
        <form onSubmit={_editState}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Enter State Name</label>
                <input type="text" className="text-input" required value={name} onChange={(e)=>setName(e.target.value)}/>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setModal(false)}/>
            <input type="submit" className="xs-delete-btn" disabled={data.name === name} value="Save State"/>
        </div>
        </form>
    </Modal>
  )
}
