import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Tag from '../../../components/Tag'
import { TOAST_CONFIG } from '../../../config/constants'
import { authAxios } from '../../../helpers/axios'
import ConfirmRemoveModal from './ConfirmRemoveModal'
import ConfirmRemoveSyllabusModal from './ConfirmRemoveSyllabusModal'

export default function SubCategory({category,setShowAddSubject,categoryId,setCategories}) {

    const [selectedSubject,setSelectedSubject] = useState(false)
    const [showRemoveSyllabusModal,setShowRemoveSyllabusModal] = useState(false)
    const _removeTag = async(subjectId)=>
    {
        try 
        {
            let res = await authAxios.put('/settings/category/syllabus/subject',{subjectId,categoryId:categoryId,syllabusId:category.syllabus._id})
            let categoryData = res.data
            if (categoryData.length > 0) {
                categoryData.map((i) => {
                  let subdata = i.data;
                  let subjectCount = 0;
                  subdata.map((j) => {
                    subjectCount += j.subjects.length;
                  });
                  i["subjectCount"] = subjectCount;
                  i["syllabusCount"] = i.data.length;
                });
              }
              setCategories(categoryData);
        } catch (error) {
            console.log(error)
            toast.error(error,TOAST_CONFIG)   
        }
    }

    const _removeSyllabus = async(subjectId)=>
    {
        try 
        {
            let res = await authAxios.put('/settings/category/syllabus',{categoryId:categoryId,syllabusId:category.syllabus._id})
            let categoryData = res.data
            if (categoryData.length > 0) {
                categoryData.map((i) => {
                  let subdata = i.data;
                  let subjectCount = 0;
                  subdata.map((j) => {
                    subjectCount += j.subjects.length;
                  });
                  i["subjectCount"] = subjectCount;
                  i["syllabusCount"] = i.data.length;
                });
              }
              setCategories(categoryData);
        } catch (error) {
            console.log(error)
            toast.error(error,TOAST_CONFIG)   
        }
    }
  return (
    <>
    {selectedSubject ? <ConfirmRemoveModal onClick={()=>_removeTag(selectedSubject._id)} onCancel={()=>setSelectedSubject(false)}/> : null}
    {showRemoveSyllabusModal ? <ConfirmRemoveSyllabusModal onClick={()=>_removeSyllabus(category.syllabus._id)} onCancel={()=>setShowRemoveSyllabusModal(false)}/> : null}
    <div className="sub-category-container">
        <div className="sub-category-header">
            <h5 className="sm-text m-weight f-op">{category.syllabus.name}</h5>
            <button className="xxs-btn" onClick={()=>setShowAddSubject(category.syllabus)}>Add Subject</button>
            {category.subjects.length <= 0 ? <button className="tertiary-btn" onClick={()=>setShowRemoveSyllabusModal(category.syllabus)}>Remove Subject</button> : null}
        </div>
        <div className="sub-category-content">
            {
                category.subjects.map((item,index)=>{
                    return  <Tag editable={true} title={item.name} key={index} onClick={()=>setSelectedSubject(item)}/>
                })
            }
        </div>
    </div>
    </>
  )
}
