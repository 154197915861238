import React, { useEffect } from 'react'
import { Navigate, useNavigate} from 'react-router-dom'
import {connect, useSelector} from 'react-redux'
const Unprotected = (props)=>
{
    const navigate = useNavigate()
    const authInfo = useSelector(state=>state.auth)
    useEffect(()=>
    {
        if(authInfo.isAuthenticated)
        {
            if(authInfo.user.role !== 'support') navigate('/dashboard/enqueries/pending')
            else navigate('/dashboard/tutors')
        }

    },[])
    return props.children
}

export default Unprotected 