import React, { useState ,useEffect} from "react";
import "firebase/auth";
import Logo from '../assets/images/logo.png'
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { TOAST_CONFIG } from "../config/constants";
import { toast } from "react-toastify";
import {LOGIN} from '../redux/constants/constants'
import { useDispatch } from "react-redux";
import { publicAxios } from "../helpers/axios";
import { Link, useNavigate } from "react-router-dom";
function Login() {
    const [authInfo,setAuthInfo] = useState({mobile:'',otp:''})
    const [otpSent,setOtpSent] = useState(false)
    const dispatch = useDispatch()
    const auth = getAuth();
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()

  useEffect(()=>
  {
    try {
      if(authInfo.user) 
      {
        if(authInfo.user.role !== 'support') navigate('/dashboard/enqueries/pending')
        else navigate('/dashboard/tutors')
      }
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {'size':'invisible'}, auth);
      
    } catch (error) {
      console.log(error)
    }

  },[])
  const sendOTP = async(e) => {
    try {
      await publicAxios.post("/public/check", {mobile: authInfo.mobile});
      const appVerifier = window.recaptchaVerifier;
        e.preventDefault()
  
      signInWithPhoneNumber(auth, `+91${authInfo.mobile}`, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          toast.info('OTP Sent',TOAST_CONFIG)
        })
        .catch((error) => {
          console.log(error)
          toast.error("Incorrect OTP",TOAST_CONFIG);
        });
        setOtpSent(true)
      } catch (error) {
        toast.error("Error Sending OTP",TOAST_CONFIG);
        
    }
  };

  const submitOTP = (e)=>
  {
    setLoading(true)
    e.preventDefault()
    window.confirmationResult.confirm(authInfo.otp).then((result) => {
      const user = result.user;
        dispatch({type:LOGIN ,payload:{user:user,token:user.accessToken}})
      }).catch((error) => {
        setLoading(false)
      toast.error('Wrong OTP',TOAST_CONFIG)
    });
  }

  return (
    <div className="auth-parent">
      <div className="auth-content">
        <div className="auth-logo-container">
            <img src={Logo} className="auth-logo"/>
            <p>Protutor</p>
          </div>
        <div>
          <br />
          <div id="recaptcha-container"></div>
          <form onSubmit={submitOTP}>
            <div className="input-group">
                <label>Mobile</label>
                <input name="mobile" placeholder="Mobile Number" className="text-input cta-input" onChange={(e) =>setAuthInfo(prev=>({...prev,mobile:e.target.value}))} value={authInfo.mobile}/>
                <input type={'button'} className="send-otp-btn" disabled={otpSent || authInfo.mobile.length != 10} onClick={sendOTP} value={'Send OTP'}/>
            </div>

            <div className="input-group">
                <label>OTP</label>
                <input name="otp" placeholder="OTP" className="text-input cta-input" onChange={(e) =>setAuthInfo(prev=>({...prev,otp:e.target.value}))} value={authInfo.otp}/>
            </div>
            <div className="submit-input-group">
              <button type="submit" className="btn" disabled={!authInfo.otp || authInfo.otp.length != 6 || loading}>Verify OTP</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Login;
