import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import {connect, useSelector} from 'react-redux'
const Protected = ({children})=>
{
    let navigate = useNavigate();
    const authInfo = useSelector(state=>state.auth)
    if(authInfo && !authInfo.user) navigate('/login')
    useEffect(()=>
    {
    },[''])
    // const [loading,setLoading] = useState(true)
    // console.log('authinfi proepted',authInfo.user)
    // if(!authInfo.user) return <Navigate to="/login" replace/>
    return children
}


export default Protected