import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createCategory } from '../../../components/services'
import { TOAST_CONFIG } from '../../../config/constants'

export default function AddCategoryModal({setAddModal,setCategories}) {
    const [name,setName] = useState('')
    const _createCategory = async(e)=>
    {
        e.preventDefault();
        try 
        {
            let res = await createCategory(name)
            console.log('received data',res)
            let categoryData = res
                if (categoryData.length > 0) {
                    categoryData.map((i) => {
                      let subdata = i.data;
                      let subjectCount = 0;
                      subdata.map((j) => {
                        subjectCount += j.subjects.length;
                      });
                      i["subjectCount"] = subjectCount;
                      i["syllabusCount"] = i.data.length;
                    });
                  }
            setCategories(categoryData)
            setAddModal(false)

        } catch (error) {
            console.log(error)
            toast.error(error,TOAST_CONFIG)
        }
    }
  return (
    <Modal title="Add Category" size="md">
        <form onSubmit={_createCategory}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Add Category</label>
                <input type="text" className="text-input" value={name} onChange={(e)=>setName(e.target.value)}/>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setAddModal(false)}/>
            <input type="submit" className="xs-delete-btn" value="Create Category"/>
        </div>
        </form>
    </Modal>
  )
}
