import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../components/Modal'
import { createCity } from '../../../components/services'
import AutocompleteInput from '../../../components/AutocompleteInput'
import { TOAST_CONFIG } from '../../../config/constants'
import CityAutocomplete from '../../../components/CityAutocomplete'

export default function AddCityModal({setModal,setCities,states}) {
    const [data,setData] = useState({cityName:'',cityState:'',cityCode:'',geo:{lat:null,lng:null}})
    const _createCity = async(e)=>
    {
        e.preventDefault();
        try 
        {
            if(data.geo.lat && data.geo.lng)
            {   
                let res = await createCity(data.cityName,data.cityState,data.geo)
                setCities(prev=>([...prev,res]))
                toast.info('City Added',TOAST_CONFIG)
                setModal(false)
            }
            else 
            {
                toast.error('Select a valid city',TOAST_CONFIG)
            }

        } catch (error) {
            console.log(error.response)
            if(error.response)
            {
                toast.error(error.response.data.msg,TOAST_CONFIG)
            }
            else toast.error('Please verify if already exist ',TOAST_CONFIG)
        }
    }
  return (
    <Modal title="Add City" size="md">
        <form onSubmit={_createCity}>
        <div className="modal-inner-content">
            <div className="input-group">
                <label>Select State</label>
                <select className="text-input" value={data.cityState} required onChange={(e)=>setData(prev=>({...prev,cityState:e.target.value}))}>
                    <option></option>
                    {
                        states.map((item,index)=>
                        {
                            return <option key={index} value={item._id}>{item.name}</option>
                        })
                    }
                </select>
            </div>

            <div className="input-group">
                <label>Select City</label>
                <CityAutocomplete setData={setData} cityName={data.cityName}/>
            </div>

            <div className="input-group">
                <label>Enter City Code (Must be 3 Chars)</label>
                <input type={'text'} value={data.cityCode} className="text-input" maxLength={3} onChange={(e)=>setData(prev=>({...prev,cityCode:e.target.value}))}/>
            </div>
        </div>
        <div className="modal-footer">
            <input type="button" value="Cancel" className="xs-neutral-btn" onClick={()=>setModal(false)}/>
            <input type="submit" disabled={!data.geo.lat || !data.geo.lng} className="xs-delete-btn" value="Create City"/>
        </div>
        </form>
    </Modal>
  )
}
