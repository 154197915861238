import React, { useState } from 'react'
import FileContainer from '../../../components/FileContainer'
import Modal from '../../../components/Modal'
import { authAxios } from '../../../helpers/axios'

export default function AddPaymentModal({setModal,onSubmit,data,setData,id,initialFee,commissionToCompany,remainingCommission,days,feeType,duration}) {
    const [paymentInfo,setPaymentInfo] = useState({
        periodCount:data && data.periodCount ? data.periodCount : '',
        periodType:data && data.periodType ? data.periodType : 'class',
        totalFee:data && data.totalFee ? data.totalFee : '',
        feeToCompany:data && data.feeToCompany ? data.feeToCompany : '',
        feeToTutor:data && data.feeToTutor ? data.feeToTutor : '',
        paymentLink:data && data.paymentLink ? data.paymentLink : '',
        paymentProof:data && data.paymentProof ? data.paymentProof : '',
        classProof:data && data.classProof ? data.classProof : '',
    }) 

    const uploadFile = async(file,fieldType)=>
    {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      try 
      {
        let res = await authAxios({
          method: "post",
          url: `/upload/${fieldType}?id=${id}`,
          data: formData,
        })
        if(fieldType === 'paymentPaymentProof') setPaymentInfo(prev=>({...prev,paymentProof:res.data.filename}))
        else setPaymentInfo(prev=>({...prev,classProof:res.data.filename}))
      } catch (error) {
        console.log(error)
      }
    }
  return (
    <Modal title={data ? 'Update Payment' : 'Add Payment'} size="md">
      <div className='payment-modal-top-summary'>
            <div>
              <label>Fee</label>
              {initialFee ? <p className="xxs-text m-weight f-op">{initialFee} <span className="caps xxxs-text q-op">{feeType}</span></p> :
              <p className="xxxs-text m-weight f-op">Not Available</p>}
            </div>
            <div>
              <label>Hours</label>
              {duration ? <p className="xxs-text m-weight f-op">{duration} <span className="caps xxxs-text q-op">/ Day</span></p> :
              <p className="xxxs-text m-weight f-op">Not Available</p>}
            </div>
            <div>
              <label>Days</label>
              {/* <p className="xs-text m-weight f-op">{days ? `${days} / Week` : 'Not Available'}</p> */}
              {days ? <p className="xxs-text m-weight f-op">{days} <span className="caps xxxs-text q-op">/ Week</span></p> :
              <p className="xxxs-text m-weight f-op">Not Available</p>}
            </div>
            <div>
              <label>Comm. to Company</label>
              <p className="xxs-text m-weight f-op">{commissionToCompany ? commissionToCompany  : 'Not Available'}({remainingCommission ? remainingCommission: '-'})</p>
            </div>
      </div>
        <form onSubmit={data ? (e)=>onSubmit(e,data._id,paymentInfo) : (e)=>onSubmit(e,paymentInfo)}>
        <div className='modal-inner-content'>
        <div className='input-group-divided'>
      <div>
              <label>Class / Hours Taken</label>
              <div className="fee-type-flex">
              <select value={paymentInfo.periodType} required className="fee-type-select" onChange={(e)=>setPaymentInfo(prev=>({...prev,periodType:e.target.value}))}>
                  <option value='class'>Class</option>
                  <option value='hour'>Hour</option>
              </select>
              <input type="number" className="text-input fee-input" required value={paymentInfo.periodCount} onChange={(e)=>setPaymentInfo(prev=>({...prev,periodCount:e.target.value}))}/>
              </div>
        </div>
        <div>
          <label>Total Fee</label>
          <input className='text-input' type={'text'} value={paymentInfo.totalFee} onChange={(e)=>setPaymentInfo(prev=>({...prev,totalFee:e.target.value}))}/>
        </div>
        <div className='payment-link-span'>
          <label>Payment Link</label>
          <input className='text-input' type={'text'} value={paymentInfo.paymentLink} onChange={(e)=>setPaymentInfo(prev=>({...prev,paymentLink:e.target.value}))}/>
        </div>
        <div>
          <label>Fees to company</label>
          <input className='text-input' type={'text'} value={paymentInfo.feeToCompany} onChange={(e)=>setPaymentInfo(prev=>({...prev,feeToCompany:e.target.value}))}/>
        </div>
        <div>
          <label>Fees to tutor</label>
          <input className='text-input' type={'text'} value={paymentInfo.feeToTutor} onChange={(e)=>setPaymentInfo(prev=>({...prev,feeToTutor:e.target.value}))}/>
        </div>
        
        <div>
          <label>Class Proof</label>
          <FileContainer  type={'paymentClassProof'} prevFile={paymentInfo.classProof} onChange={uploadFile}/>
        </div>
        <div>
          <label>Payment Proof</label>
          <FileContainer type={'paymentPaymentProof'} prevFile={paymentInfo.paymentProof} onChange={uploadFile}/>
        </div>

        { data ? <div className='checkbox-input-group payment-checkbox-container'>
          <input type={'checkbox'} onChange={(e)=>setPaymentInfo(prev=>({...prev,paymentClosed:e.target.checked}))}/>
          <p className='xs-text f-op'>Complete Payment <span className='xxxs-text q-op brand-text'>(Can't undo the process)</span></p>
        </div> : null}
    </div> 
    </div>
    <div className={`modal-footer`}>
          {/* {data ? <input type="button" className="tertiary-btn" onClick={()=>setModal(false)} value="Complete Payment"/> : null}
      <div className='flex'> */}
          <input type="button" className="xs-neutral-btn" onClick={()=>setModal(false)} value="Cancel"/>
          <input type="submit" className="xs-delete-btn" value={data ? 'Update Payment' : 'Add Payment Now'}/>
      {/* </div> */}
    </div>
    </form>
    </Modal>
  )
}
