import { useEffect, useState } from 'react';
import Modal from '../../../components/Modal'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../../config/constants';

const AssignDemoModal = ({onClick,setAssignDemo,enquiry,tutor}) => {
    const [data,setData] = useState({date:'',time:null})
    const [loading,setLoading] = useState(false)

    const _onClick = async(e)=>
    {
        try {
            setLoading(true)
            await onClick(e,{demoDate:data.date,demoTime:data.time,enquiryId:enquiry._id,tutorId:tutor._id})
            setLoading(false)          
        } catch (error) {
            toast.error(error.response.data.message ? error.response.data.message  : error,TOAST_CONFIG)
            setLoading(false)
            return false
        }
    }
    return (
        <Modal title="Assign Demo" size="md">
            <form>
                <div className="modal-inner-content">
                    <div className="input-group-divided">
                        <div>    
                            <label>Demo Data</label>
                            <input type="date" className="text-input" required value={data.date} onChange={(e)=>setData(prev=>({...prev,date:e.target.value}))}/>
                        </div>

                    <div>
                        <label>Demo Time</label>
                        <TimePicker showSecond={false} defaultValue={data.time} allowEmpty={false} className="time-input" popupClassName="time-select-popup" onChange={(value)=>setData(prev=>({...prev,time:value && value.format('h:mm a')}))} minuteStep={15} format={'h:mm a'} use12Hours inputReadOnly/>
                    </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <input type="button" className="xs-neutral-btn" onClick={()=>setAssignDemo(false)} value="Cancel"/>
                    <input type="submit" className="xs-approve-btn" disabled={ loading || !data.date || !data.time || data.time === ''} onClick={_onClick} value="Assign Now"/>
                </div>
            </form>
      </Modal>
    );
  };

  export default AssignDemoModal