export const firebaseConfig = {
  apiKey: "AIzaSyDK4WqZK6G6yVrSzzbP6Ds067lkxJmLOn8",
  authDomain: "protutor-dev.firebaseapp.com",
  projectId: "protutor-dev",
  storageBucket: "protutor-dev.appspot.com",
  messagingSenderId: "930952949536",
  appId: "1:930952949536:web:ac4bf8fb5e162b1a9db145",
};
export const firebaseProdConfig = {
  apiKey: "AIzaSyC3ZaNKLkUovGYKVgvH7xMV7AqpfPSGxMA",
  authDomain: "protutor-11d15.firebaseapp.com",
  projectId: "protutor-11d15",
  storageBucket: "protutor-11d15.appspot.com",
  messagingSenderId: "1044483230034",
  appId: "1:1044483230034:web:3e896e3ceeab203c56928b",
  measurementId: "G-19T7KRMSJV"
};
