import React, { useState,useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { approveEnquiry, getAllEnquiry, getPendingEnquiry, terminateEnquiry } from '../../components/services';
import PendingInfo from '../Enquiries/components/PendingInfo';
import AddEnquiryModal from '../Enquiries/components/AddEnquiryModal';
import { useSelector } from 'react-redux';
// import EnquiryInfoPopup from './components/EnquiryInfoPopup'
import InfoIcon from '../../assets/images/info.png'
import EnquiryInfoPopup from '../Enquiries/components/EnquiryInfoPopup';

export default function EnquiryAnalytics() {
  const authData = useSelector(state=>state.auth)

  const [addModal,setAddModal] = useState(false)
  const [enquiryData,setEnquiryData] = useState([])
  const [loading,setLoading] = useState(false)
  const [viewItem,setViewItem] = useState(false)
  const [terminateItem,setTerminateItem] = useState(false)
  const [approveItem,setApproveItem] = useState(false)
  const [filteredRows,setFilteredRows] = useState([])
  const [searchText,setSearchText] = useState('')
  const [selectedCity,setSelectedCity] = useState('all')
  const [fetching,setFetching] = useState(false)

  const [detailedEnquiry,setDetailedEnquiry] = useState()


  useEffect(()=>
  {
    if(viewItem)
    {

      setViewItem(prev=>
        {
          let index = enquiryData.findIndex(item=>item._id === prev._id)
          return enquiryData[index]
        })
      }
  },[enquiryData])
  useEffect(()=>
  {
    const fetchData = async()=>
    {
      try 
    {
      setFetching(true)
      let res = await getAllEnquiry(selectedCity)
      setEnquiryData(res)  
      setFilteredRows(res)
      setFetching(false)
    } catch (error) {
      console.log(error)
      setFetching(false)
      return false;
    }
    }
    fetchData()
  },[selectedCity])

  const _onClick = (id)=>
  {
    try 
    {
      let res = enquiryData.filter(item=>item._id === id) 
      console.log(res)
      setViewItem(res[0])
    } catch (error) {
      console.log(error)
      return false
    }
  }


  const _terminateEnquiry = async(e,data)=>
  {
    try 
    {
      e.preventDefault()
      await terminateEnquiry(data)
      setEnquiryData(enquiryData.filter(item=>item._id !== data._id))
      setFilteredRows(enquiryData.filter(item=>item._id !== data._id))
      setViewItem(false) 
      setTerminateItem(false)
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const _approveEnquiry = async(data)=>
  {
    try 
    {
      await approveEnquiry(data)
      setEnquiryData(enquiryData.filter(item=>item._id !== data._id))
      setFilteredRows(enquiryData.filter(item=>item._id !== data._id))
      setViewItem(false) 
      setApproveItem(false)
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const columns = [
    {field:'firstName',headerName:'Name/Mobile',flex:1,renderCell:(params)=>
    {
      return <div>
          <p className='xs-text m-weight f-op caps'>{params.row.customer.firstName}<span className='tooltip-trigger' onClick={()=>setDetailedEnquiry(params.row)}><img src={InfoIcon} className="tooltip-trigger-img"/></span></p>
          <p className='xxs-text r-weight h-op'>{params.row.mobile}</p>
      </div>
    }},
    {field:'locality',headerName:'Locality/Type',flex:1,renderCell:(params)=>
  {
    return <div>
        <p className='xs-text m-weight f-op'>{params.row.city.cityName}</p>
        <p className='xxs-text r-weight h-op'>{params.row.enquiryType}</p>
    </div>
  }}
  ]

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = enquiryData.filter((row) => {
      return Object.keys(row).some((field) => {
        if(field === 'mobile' || field === 'customer.firstName' || field === 'customer.lastName' || field === 'alternateMobile')
        {
          return searchRegex.test(row['mobile']?.toString()) || searchRegex.test(row['customer']['firstName']?.toString()) || searchRegex.test(row['customer']['lastName']?.toString()) || searchRegex.test(row['alternateMobile']?.toString()) ;
        }
      });
    });
    console.log(filteredRows)
    setFilteredRows(filteredRows);
  };


  return (
      <div className="split-main-container">
        {addModal ? <AddEnquiryModal setModal={setAddModal} setEnquiryData={setEnquiryData}/> : null}
        {detailedEnquiry ? <EnquiryInfoPopup data={detailedEnquiry} setModal={setDetailedEnquiry}/> : null}
            <div>
              <div className="enquiry-table-parent">
              <div className="enquiry-table-header">
              <div className='role-based-search-container'>
                  <select className='role-city-select' defaultChecked={selectedCity} onChange={(e)=>setSelectedCity(e.target.value)}>
                    <option value={'all'}>All</option>
                    {
                      authData.user.cities.map((item,index)=>
                      {
                        return <option key={index} value={item._id}>{item.cityName}</option>
                      })
                    }
                  </select>
                  <input placeholder="Search Enquiry" value={searchText}  className="search-input role-city-search" onChange={(e)=>requestSearch(e.target.value)}/>
                </div>
                {/* <div>
                  <input placeholder="Search Enquiry" className="search-input" onChange={(e)=>requestSearch(e.target.value)}/>
                </div> */}
                <div>
                  <button type="button" className="xs-compact-inverted-btn" onClick={setAddModal}>Add Enquiry</button>
                </div>
              </div>
                <div style={{'height':'100%','width':'100%'}}>
                  <DataGrid sx={{'border':'0'}} loading={fetching} columns={columns} rows={filteredRows} disableSelectionOnClick={true} disableColumnSelector={true} onRowClick={(item)=>_onClick(item.row._id)} getRowId={item=>item._id}/>
              </div>
              </div>
            </div>

            {viewItem ?  <PendingInfo data={viewItem} setEnquiryData={setEnquiryData} setTerminateItem={setTerminateItem} setApproveItem={setApproveItem} setViewItem={setViewItem}/> : null}
      </div>
  )
  }
