import React, { useState,useEffect } from 'react'
import { getDemos,cancelDemo as closeDemo,terminateEnquiry as closeEnquiry, rescheduleDemos, initiateTution, updateEnquery, updateTutor } from '../../components/services';
import { DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import RescheduleDemoModal from '../Enquiries/components/RescheduleDemo';
import TerminateModal from '../Enquiries/components/TerminateModal';
import CancelDemoModal from '../Enquiries/components/CancelDemoModal';
import InitateTuition from '../Enquiries/components/InitiateTuition';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../config/constants';
import { getDate, getTime,getTimeFromDigit } from '../../helpers/dateHelper';
import { useSelector } from 'react-redux';
import InfoIcon from '../../assets/images/info.png'
import TutorInfoPopup from '../Enquiries/components/TutorInfoPopup';
import SecondEnquiryInfoPopup from '../Enquiries/components/SecondEnquiryInfoPopup';

export default function DemoAnalytics() {
  const authData = useSelector(state=>state.auth)
  const [demoData,setDemoData] = useState([])
  const [loading,setLoading] = useState(false)
  const [assignTuition,setAssignTuition] = useState(false)
  const [cancelDemo,setCancelDemo] = useState(false)
  const [rescheduleDemo,setRescheduleDemo] = useState(false)
  const [terminateEnquiry,setTerminateEnquiry] = useState(false)

  const [filteredRows,setFilteredRows] = useState([])
  const [searchText,setSearchText] = useState('')

  const [selectedCity,setSelectedCity] = useState('all')
  const [fetching,setFetching] = useState(false)

  const [detailedTutor,setDetailedTutor] = useState(false)
  const [detailedEnquiry,setDetailedEnquiry] = useState(false)

  useEffect(async()=>
  {
    try 
    {
      setFetching(true)
      let res = await getDemos(selectedCity,true,true)
      console.log(res)
      setDemoData(res)  
      setFilteredRows(res)
      setFetching(false)
    } catch (error) {
      console.log(error)
      setFetching(false)
      return false;
    }
  },[selectedCity])



  const _rescheduleDemo = async(e,data)=>
  {
    try 
    {
      e.preventDefault()
      let res = await rescheduleDemos(data)
      setFilteredRows(prev=>{
        let newData = [...prev]
        let index = newData.findIndex(i=>i._id === data.demoId)
        newData[index] = res
        return newData
      })
      setDemoData(prev=>{
        let newData = [...prev]
        let index = newData.findIndex(i=>i._id === data.demoId)
        newData[index] = res
        return newData
      })
      setRescheduleDemo(false)
    } catch (error) {
      console.log(error)
      toast.error(error.response.message,TOAST_CONFIG)
      return false
    }
  }

  const _initateTuition = async(e,data)=>
  {
      e.preventDefault()
      try 
      {
        let res = await initiateTution(data) 
        toast.info('Tuition Initiated')
        setAssignTuition(false)
        setFilteredRows(prev=>prev.filter(i=>i._id != res._id))
        setDemoData(prev=>prev.filter(i=>i._id != res._id))
        setAssignTuition(false)
      } catch (error) {
        toast.error(error.response.message,TOAST_CONFIG)  
        return false;
      }
  }

  const _terminateEnquiry = async(e,data,setLoading)=>
  {
    try 
    {
      e.preventDefault()
      setLoading(true)
      await closeEnquiry(data)
      setFilteredRows(prev=>prev.filter(i=>i.enquiry._id != data._id))
        setDemoData(prev=>prev.filter(i=>i.enquiry._id != data._id))
        setTerminateEnquiry(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      toast.error(error.response.message,TOAST_CONFIG)
      return false
    }
  }

  const _cancelDemo = async(e,data)=>
  {
    try 
    {
      e.preventDefault()
      console.log(data)
      await closeDemo(data)
      // setDemoData(prev=>prev.filter(i=>i._id != data._id))
      setFilteredRows(prev=>prev.filter(i=>i._id != data.demoId))
        setDemoData(prev=>prev.filter(i=>i._id != data.demoId))
      setCancelDemo(false)
    } catch (error) {
      console.log(error)
      toast.error(error.response.message,TOAST_CONFIG)
      return false
    }
  }

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = demoData.filter((row) => {
      return Object.keys(row).some((field) => {
        console.log('fields',field)
        if(field === 'enquiry' || field === 'customer' || field === 'customer' || field === 'alternateMobile')
        {
          console.log('running',field)
          return searchRegex.test(row['enquiry']['mobile']?.toString()) || searchRegex.test(row['customer']['firstName']?.toString()) || searchRegex.test(row['customer']['lastName']?.toString()) || searchRegex.test(row['enquiry']['alternateMobile']?.toString()) ;
        }
      });
    });
    console.log(filteredRows)
    setFilteredRows(filteredRows);
  };


  const _getActions = (params) => {
    let arr = [
    <GridActionsCellItem
      label="Initiate Tuition"
      showInMenu={true}
      onClick={()=>
        {
          let item = demoData.filter((i,index)=>{
            console.log(i)
            return i._id === params.id
          })
          console.log('item',item)
          setAssignTuition(item[0])
      }}
    />,
    <GridActionsCellItem
      label="Reschedule"
      showInMenu={true}
      onClick={()=>
        {
          let item = demoData.filter((i,index)=>{
            console.log(i)
            return i._id === params.id
          })
          console.log('item',item)
          setRescheduleDemo(item[0])
      }}
    />,
    <GridActionsCellItem
      label="Cancel Demo"
      showInMenu={true}
      onClick={()=>
        {
          let item = demoData.filter((i,index)=>{
            console.log(i)
            return i._id === params.id
          })
          console.log('item',item)
          setCancelDemo(item[0])
      }}
    />
  ]
  if(authData.user.role === 'manager') {
    arr.push(    <GridActionsCellItem
      label="Terminate Enquiry"
      showInMenu={true}
      onClick={()=>
        {
          let item = demoData.filter((i,index)=>{
            console.log(i)
            return i._id === params.id
          })
          console.log('item',item)
          setTerminateEnquiry(item[0])
      }}
    />)
  }
  return arr;
}

  const columns =[
    {field:'customer',headerName:'Customer',flex:1,renderCell:(params)=>
  {
    return <div>
      <p className='xxs-text f-op m-weight caps'>{params.row?.customer.firstName} {params.row?.customer.lastName}<span className='tooltip-trigger' onClick={()=>setDetailedEnquiry(params.row.enquiry)}><img src={InfoIcon} className="tooltip-trigger-img"/></span></p>
      <p className='xxxs-text q-op r-weight'>{params.row?.enquiry.primaryMobile === 'primary' ? params.row?.enquiry.mobile : params.row?.enquiry.alternateMobile}</p>
    </div>
  }},
    {field:'studentCategory',headerName:'Category/Syllabus',flex:2,renderCell:(params)=>
    {
      let subjects = params.row?.enquiry.studentSubjects.map(function (e) {
        return e.name;
      }).join(', ');
      return <div>
        <p className='xxs-text f-op m-weight caps'>{params.row?.enquiry.standard ? `Class-${params.row?.enquiry.standard}` : params.row?.enquiry.studentCategory.name} ({params.row?.enquiry.studentSyllabus.name})</p>
        <p className='xxxs-text q-op r-weight'>{subjects}</p>
      </div>
    }},
    {field:'currentDemoDate',headerName:'Demo Date',flex:1,renderCell:(params)=>
      {
        return <div>
          <p className="xxs-text f-op m-weight caps">{`${getDate(params.row?.currentDemoDate)}`}</p>
          <p className="xxxs-text h-op m-weight caps">{getTimeFromDigit(params.row?.currentDemoTime)}</p>
          </div>
      }},
      {field:'tutor',headerName:'Tutor',flex:1,renderCell:(params)=>
  {
      // <p className='xxs-text f-op m-weight caps'>{params.row?.firstName} {params.row?.lastName}<span className='tooltip-trigger' onClick={()=>setDetailedTutor(params.row)}></p>
    return <div>
      <p className='xxs-text f-op m-weight caps'>{params.row?.tutor.firstName} {params.row?.tutor.lastName}<span className='tooltip-trigger' onClick={()=>setDetailedTutor(params.row.tutor)}><img src={InfoIcon} className="tooltip-trigger-img"/></span></p>
      <p className='xxxs-text q-op r-weight'>{params.row?.tutor.primaryMobile === 'primary' ? params.row?.tutor.mobile : params.row?.tutor.alternateMobile}</p>
    </div>
  }},
  {field:'note',headerName:'Note',flex:1,renderCell:(params)=>
  {
    return <div>
        <p className='xxxs-text f-op r-weight caps'>{params.row?.enquiry.note}</p>
      </div>
    }},
    {field:'rescheduleCount',headerName:'Rescheduled'},
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params)=>_getActions(params)
    },
  ]

  const _updateNote = async(data)=>
  {
      let res = await updateEnquery({_id:data.id,note:data.note})
      let filteredIndex = filteredRows.findIndex(item=>item.enquiry._id === data.id)
      let unfilteredIndex = demoData.findIndex(item=>item.enquiry._id === data.id)
       setFilteredRows((prev)=>
       {
         let newData = [...prev]
         newData[filteredIndex].enquiry = res
         return newData
       })
       setDemoData((prev)=>
       {
         let newData = [...prev]
         newData[unfilteredIndex].enquiry = res
         return newData
       })
  }

  const _updateTutorNote = async(data)=>
  {
      let res = await updateTutor({_id:data.id,note:data.note})
      let filteredIndex = filteredRows.findIndex(item=>item.tutor._id === data.id)
      let unfilteredIndex = demoData.findIndex(item=>item.tutor._id === data.id)
       setFilteredRows((prev)=>
       {
         let newData = [...prev]
         newData[filteredIndex].tutor = res
         return newData
       })
       setDemoData((prev)=>
       {
         let newData = [...prev]
         newData[unfilteredIndex].tutor = res
         return newData
       })
       setDetailedTutor(res)
  }

  return (
      <div className="demo-main-container">
        {cancelDemo ? <CancelDemoModal onClick={_cancelDemo} item={cancelDemo} setCancelDemo={setCancelDemo}/> : null}

        {assignTuition ? <InitateTuition onClick={_initateTuition} item={assignTuition} setAssignTuition={setAssignTuition}/> : null}

        {rescheduleDemo ? <RescheduleDemoModal onClick={_rescheduleDemo} demoItem={rescheduleDemo}  setRescheduleDemo={setRescheduleDemo}/> : null}

        {terminateEnquiry ? <TerminateModal onClick={_terminateEnquiry} item={terminateEnquiry.enquiry}  setTerminateItem={setTerminateEnquiry}/> : null}

        {detailedTutor ? <TutorInfoPopup info={detailedTutor} setModal={setDetailedTutor} updateNote={_updateTutorNote}/> : null}
        {detailedEnquiry ? <SecondEnquiryInfoPopup data={demoData.find(item=>item.enquiry._id === detailedEnquiry._id)} setModal={setDetailedEnquiry} updateNote={_updateNote}/> : null}
            <div>
              <div className="demo-list-content">
              <div className="demo-list-header">
                <div className='role-based-search-container'>
                  <select className='role-city-select' defaultChecked={selectedCity} onChange={(e)=>setSelectedCity(e.target.value)}>
                    <option value={'all'}>All</option>
                    {
                      authData.user.cities.map((item,index)=>
                      {
                        return <option key={index} value={item._id}>{item.cityName}</option>
                      })
                    }
                  </select>
                  <input placeholder="Search Enquiry" value={searchText}  className="search-input role-city-search" onChange={(e)=>requestSearch(e.target.value)}/>
                </div>
              </div>
              <div className="demo-table-container" style={{height:'500px',width:'100%'}}>
              <DataGrid sx={{'border':'0'}} loading={fetching} disableColumnSelector={true}  columns={columns} rows={filteredRows} getRowId={item=>item._id} disableSelectionOnClick={true} style={{border:0}}/>
              </div>
              </div>
            </div>
      </div>
  )
  }
