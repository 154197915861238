import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect,useState,useCallback } from 'react'
import { getCategory } from '../../components/services';
import {useNavigate} from 'react-router-dom'
import ShowCategoryInfo from './components/ShowCategoryInfo';
import AddCategoryModal from './components/AddCategoryModal';
import { authAxios } from '../../helpers/axios';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../../config/constants';
import AddCategorySyllabusModal from './components/AddCategorySyllabusModal';
import AddCategorySubjectModal from './components/AddCategorySubjectModal';
import EditCategoryModal from './components/EditCategoryModal';

export default function Categories() {

    const [categories,setCategories] = useState([])
    const [addModal,setAddModal] = useState(false)
    const [selectedItem,setSelectedItem] = useState(false)
    const [showAddSyllabus,setShowSyllabus] = useState(false)
    const [showAddSubject,setShowAddSubject] = useState(false)
    const getData = useCallback(async ()=>{
        const categoryData = await getCategory();
    
        if (categoryData.length > 0) {
          categoryData.map((i) => {
            let subdata = i.data;
            let subjectCount = 0;
            subdata.map((j) => {
              subjectCount += j.subjects.length;
            });
            i["subjectCount"] = subjectCount;
            i["syllabusCount"] = i.data.length;
          });
        }
        setCategories(categoryData);
    },[])

    const _getData = async()=>
    {
      try 
      {
        const categoryData = await getCategory();
        setCategories(categoryData); 
      } catch (error) {
        toast.error('Error getting Data',TOAST_CONFIG)
      }
    }
    useEffect(()=>
    {
        getData()
    },[getData])


    const _addSyllabus = async(e,syllabusId,name)=>
    {
      e.preventDefault()
      try 
      {
        let res = await authAxios.post('/settings/category/syllabus',{syllabus:syllabusId,name:name,id:selectedItem._id})  
        let categoryData = res.data
                if (categoryData.length > 0) {
                    categoryData.map((i) => {
                      let subdata = i.data;
                      let subjectCount = 0;
                      subdata.map((j) => {
                        subjectCount += j.subjects.length;
                      });
                      i["subjectCount"] = subjectCount;
                      i["syllabusCount"] = i.data.length;
                    });
                  }
                  console.log('cat data',categoryData)
                  setCategories(categoryData)
        setShowSyllabus(false)
        
      } catch (error) {
        toast.error(error.message,TOAST_CONFIG)
        return false
      }
    }

    const _addSubject = async(e,categoryId,syllabusId,subjectId)=>
    {
      e.preventDefault()
      try 
      {
        let res = await authAxios.post('/settings/category/syllabus/subject',{syllabus:syllabusId,category:categoryId,subject:subjectId}) 
        setShowAddSubject(false)
        let categoryData = res.data
                if (categoryData.length > 0) {
                    categoryData.map((i) => {
                      let subdata = i.data;
                      let subjectCount = 0;
                      subdata.map((j) => {
                        subjectCount += j.subjects.length;
                      });
                      i["subjectCount"] = subjectCount;
                      i["syllabusCount"] = i.data.length;
                    });
                  }
                  console.log('cat data',categoryData)
                  setCategories(categoryData)
      } catch (error) {
        toast.error(error.message,TOAST_CONFIG)
        return false
      }
    }



    const columns = [
        {field:'name',headerName:'Syllabus Name',flex:1},
        {field:'syllabusCount',headerName:'Total Syllabus',flex:1},
        {field:'subjectCount',headerName:'Total Subjects',flex:1},
    ]

    const onClick = (id)=>
    {
        setSelectedItem(categories.find(item=>item._id === id))
        console.log(selectedItem)
    }

    const _getSelectedData = ()=>
    {
      let i = categories.findIndex(i=>i._id === selectedItem._id)
      return categories[i]
    }
  return (
    <>
    { addModal ? <AddCategoryModal setAddModal={setAddModal} setCategories={setCategories}/> : null}
    { showAddSyllabus ? <AddCategorySyllabusModal setModal={setShowSyllabus} onSubmit={_addSyllabus} item={_getSelectedData()}/> : null}
    { showAddSubject ? <AddCategorySubjectModal setModal={setShowAddSubject} onSubmit={_addSubject} categoryItem={_getSelectedData()} syllabusItem={showAddSubject}/> : null}
    <div className="split-main-container">
        <div className="split-left-content">
        <div class="list-content-header">
            <div>
            <h2 className="lg-text b-weight">Categories</h2>
            </div>
            <div class="d-flex">
            <button type="button" class="xs-compact-inverted-btn" onClick={()=>setAddModal(true)}>Add Category</button>
            </div>
        </div>
        <div style={{height:'500px',width:'100%'}}>
        <DataGrid sx={{border:0}} disableColumnSelector={true} columns={columns} rows={categories} getRowId={item=>item._id} disableSelectionOnClick={true} onRowClick={(params)=>onClick(params.row._id)}/>
        </div>
      </div>
    {selectedItem ? <div className="split-right-container"><ShowCategoryInfo  setShowAddSubject={setShowAddSubject} setShowSyllabus={setShowSyllabus} data={_getSelectedData()} setCategories={setCategories}/></div> : null}
    </div>
    </>
  )
}
