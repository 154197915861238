import React,{useState} from 'react'
import Modal from '../../../components/Modal'

export default function ResetModal({setModal,onClick}) {

  return (
    <Modal title="Reset Broadcast" size="md">
        <div className="modal-inner-content">
            <p className="r-text m-weight f-op">Are you sure you want to reset broadcast for this enquiry?</p>
        </div>
        <div className="modal-footer">
            <button className="xs-neutral-btn" onClick={()=>setModal(false)}>Cancel</button>
            <button className="xs-delete-btn" onClick={()=>onClick()}>Reset Broadcast now</button>
        </div>
    </Modal>
  )
}
