import { useState,useEffect } from "react"
import FileContainer from "../../../components/FileContainer"
import Modal from "../../../components/Modal"
import SideModal from "../../../components/SideModal"
import { authAxios } from "../../../helpers/axios"
import AddPaymentModal from "./AddPaymentModal"
import ViewPaymentModal from "./ViewPaymentModal"


const PaymentModal = ({item,setModal})=>
{
  
  const [addPaymentModal,setAddPaymentModal] = useState(null)
  const [editPaymentModal,setEditPaymentModal] = useState(null)
  const [viewPaymentModal,setViewPaymentModal] = useState(null)
  const [totalPaidCommission,setTotalPaidCommission] = useState(0)
  const [payments,setPayments] = useState([])

  const [char,setChar] = useState('')

  useEffect(() => {
    console.log('payment item',item)
    async function getPayments(){
      let resp = await authAxios.get(`/payment/tuition?tuitionId=${item._id}`)
      setPayments(resp.data)
      setChar(resp.data)
      }
      getPayments()
    }, [])
    
    
    useEffect(()=>
    {
      let res = 0
      payments.map(item=>
        {
          if(item.feeToCompany) res = res + parseInt(item.feeToCompany)
          return true;
        })
      setTotalPaidCommission(res)
    },[char])

  const createPayment = async(e,data)=>
  {
    e.preventDefault()
    try 
    {
      let res = await authAxios.post('/payment',{tuitionId:item._id,...data})  
      setPayments(prev=>([...prev,res.data]))
      setAddPaymentModal(false)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const updatePayment = async(e,id,data)=>
  {
    e.preventDefault()
    try 
    {
      let res = await authAxios.put('/payment',{id:id,...data})  
      setPayments(prev=>{
        let newData = [...prev]
        let index = newData.findIndex(item=>item._id === id)
        newData[index] = res.data
        return newData
      })
      setEditPaymentModal(false)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }


        const PaymentItem = ({item})=>
        {
          return <div className='payment-item'>
              <div className='payment-item-header-flex'>
                <div>
                  <p className='xxxs-text r-weight q-op caps'>{item?.periodCount} {item.periodType}</p>
                  <p className='xs-text b-weight f-op'>{item.totalFee ? `Rs. ${item?.totalFee}` : 'Fee Not Provided'}</p>
                </div>
                <div>
                    <button className="xxs-btn" onClick={()=>setViewPaymentModal(item)}>View</button>
                    {item.paymentClosed ? null : <button className="xxs-btn ml-r" onClick={()=>setEditPaymentModal(item)}>Edit</button>}
                </div>
              </div>
          </div>
        }

      return <>
      {addPaymentModal ? <AddPaymentModal initialFee={item.fee} remainingCommission={item.commissionToCompany-totalPaidCommission} commissionToCompany={item.commissionToCompany} days={item.days} feeType={item.feeType} duration={item.duration} setModal={setAddPaymentModal} onSubmit={createPayment} id={item._id}/> : null}
      {editPaymentModal ? <AddPaymentModal initialFee={item.fee} remainingCommission={item.commissionToCompany-totalPaidCommission} commissionToCompany={item.commissionToCompany} days={item.days} feeType={item.feeType} duration={item.duration} data={editPaymentModal} setData={setPayments} setModal={setEditPaymentModal} onSubmit={updatePayment}/> : null}
      {viewPaymentModal ? <ViewPaymentModal data={viewPaymentModal}  setModal={setViewPaymentModal}/> : null}
      {/* onClick={(e)=>e.stopPropagation()} */}
      <div className="side-modal">
        <div className="side-modal-overlay"  onClick={()=>setModal(false)}></div>
      <div className={`side-modal-parent`} >
          <div className="side-modal-header">
              <h3 className="r-text b-weight">Payments</h3>
              <div>
                <button className="xxs-btn" onClick={()=>setAddPaymentModal(true)}>Add Payment</button>
              </div>
              {/* <p className="xxs-text f-op">{item.}</p> */}
          </div>
          <div className="payment-modal-top-tgrid-summary">
            <div>
              <label>Fee</label>
              <p className="xxs-text m-weight f-op">{item.fee ? item.fee : 'Not Available'}</p>
            </div>
            <div>
              <label>Days</label>
              <p className="xxs-text m-weight f-op">{item.days ? `${item.days}/Week` : 'Not Available'}</p>
            </div>
            <div>
              <label>Commission to Company</label>
              <p className="xxs-text m-weight f-op">{item.commissionToCompany ? item.commissionToCompany  : 'Not Available'} ({item.commissionToCompany ? parseInt(item.commissionToCompany) - totalPaidCommission : 'Not Available'})</p>
            </div>
          </div>
          <div className="side-modal-inner-content"> 
          {
            payments.map((i,index)=>
              {
                return <PaymentItem item={i} key={index}/>
              })
          }
          </div>
      </div>
  </div>
      </>
  }


  export default PaymentModal