import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import React, { useEffect,useState,useCallback } from 'react'
import { getSources } from '../../components/services';
import SourceModal from './components/SourceModal';

export default function Source() {

    const [sources,setSources] = useState([])
    const [addModal,setAddModal] = useState(false)
    const [editModal,setEditModal] = useState(false)
    const getData = useCallback(async ()=>{
        const sourceData = await getSources();
        setSources(sourceData)
    },[])
    useEffect(()=>
    {
        getData()
    },[getData])

    const columns = [
        {field:"name",headerName:"Name",flex:1},
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
        <GridActionsCellItem
              label="Edit"
              showInMenu={true}
              onClick={()=>
                {
                  let item = sources.filter((i,index)=>{
                    console.log(i._id === params.id,i,params.id)
                    return i._id === params.id
                  })
                  console.log('selected',item)
                  setEditModal(item[0])
              }}
            />
    ]}
    ]
  return (
    <>
    { addModal ? <SourceModal setModal={setAddModal} setData={setSources}/> : null}
    { editModal ? <SourceModal setModal={setEditModal} setData={setSources} updateData={editModal}/> : null}
    <div className="split-contained-container">
      <div className="split-left-content">
        <div class="list-content-header">
            <div>
            <h2 className="lg-text b-weight">Sources</h2>
            </div>
            <div class="d-flex">
            <button type="button" class="xs-compact-inverted-btn" onClick={()=>setAddModal(true)}>Add Source</button>
            </div>
        </div>
        <div style={{height:'500px',width:'100%'}}>
        <DataGrid sx={{border:0}} disableColumnSelector={true} columns={columns} rows={sources} getRowId={item=>item._id} disableSelectionOnClick={true}/>
        </div>
      </div>
    </div>
    </>
  )
}
