import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import React, { useState,useEffect } from 'react'
import FileContainer from '../../components/FileContainer'
import Tag from '../../components/Tag'
import { authAxios } from '../../helpers/axios'
import { getDate,getTimeFromDigit } from '../../helpers/dateHelper'

export default function TutorInfo({data,setShowEdit,setUnfilteredTutors,setTutors}) {

    const [toggle,setToggle] = useState('info')
    const [enquiries,setEnquiries] = useState()
    const [demos,setDemos] = useState()

    const [loading,setLoading] = useState(true)
    useEffect(() => {
      async function getData(){
        setLoading(true)
        let res = await authAxios.get(`/tutor/enquiry?id=${data._id}`)
        let demoRes = await authAxios.get(`/tutor/demo?id=${data._id}`)
        setDemos(demoRes.data)
        setEnquiries(res.data)
        setLoading(false)
      }
      getData()
    }, [data])

    const CategoryItem = ({data,submitData})=>
    {
      console.log('cat item',data)
      return (
        <div className='tutor-category-container'>
          <p className='xs-text b-weight f-op'>{data.category.name}</p>
          {
            data.data.map((el,i)=>{
              return <SyllabusItem key={el.syllabus._id} item={el} categoryId={data._id} submitData={submitData}/>
            })
          }
      </div>
    )}  
  
    const SyllabusItem = ({item,categoryId,submitData})=>
    {
      return (
        <div className='tutor-syllabus-container'>
          <p className='xxs-text m-weight q-op'>{item.syllabus.name}</p>
          {
            item.subjects.map((el,i)=>
            {
              return <Tag title={el.name} key={el._id} id={el._id} syllabusId={item.syllabus._id} categoryId={categoryId} data={data} submitData={submitData}/>
            })
          } 
        </div>
      )
    }

    const columns =[
      {field:'createdAt',headerName:'Date',flex:1,valueGetter:(params)=>
      {
        let d = new Date(params.row?.startDate)
        return `${d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`}-${d.getMonth()+1}-${d.getFullYear('').toString().substr(-2)}`
      }},
      {field:'studentCategory',headerName:'Category',flex:1,valueGetter: (params) => params.row?.studentCategory[0].name},
      {field:'studentSyllabus',headerName:'Syllabus',flex:1,renderCell:(params)=>
      {
        return <div>
            <p className='xs-text r-weight f-op caps'>{params.row?.standard ? `class-${params.row?.standard}` : params.row?.syllabus[0].name}</p>
        </div>
      }},
      // {field:'studentSyllabus',headerName:'Syllabus',flex:1,valueGetter: (params) => params.row?.syllabus[0].name},
      {field:'currentProcess',headerName:'Status',flex:1,renderCell:(params)=>
      {
        return <div><div><p className="xs-text r-weight">{params.row.currentProcess === 0 ? 'Pending' : params.row.currentProcess === 1 ? 'Approved' : params.row.currentProcess === 2 ? 'Demo' : 'Tuition'}</p></div></div>
      }},
      // {
      //   field: 'actions',
      //   type: 'actions',
      //   width: 80,
      //   getActions: (params) => [
      //     <GridActionsCellItem
      //       icon={<EditIcon />}
      //       label="View"
      //       onClick={()=>
      //         {
      //           setEnquiryDetailModal(params.row?._id)
      //           // let item = selectedClient.enquiries?.filter((i,index)=>{
      //           //   console.log(i)
      //           //   return i._id === params.id
      //           // })
      //           // console.log('item',item)
      //       }}
      //     />
      //   ],
      // },
    ]


    const demoColumns =[
      // {field:'createdAt',headerName:'Date',flex:1,valueGetter:(params)=>
      // {
      //   let d = new Date(params.row?.currentDemoDate)
      //   return `${d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`}-${d.getMonth()+1}-${d.getFullYear('').toString().substr(-2)}`
      // }},
      {field:'currentDemoDate',headerName:'Date',flex:1,renderCell:(params)=>
      {
        let d = new Date(params.row?.currentDemoDate)
      //  
        return <div>
          <p className='xxs-text m-weight f-op caps'>{ `${d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`}-${d.getMonth()+1}-${d.getFullYear('').toString().substr(-2)}`}</p>
          <p className='xxxs-text r-weight q-op caps'>{getTimeFromDigit(params.row?.currentDemoTime)}</p>
        </div>
      }},
      // {field:'studentCategory',headerName:'Category',flex:1,valueGetter: (params) => params.row?.studentCategory[0].name},
      {field:'studentSyllabus',headerName:'Syllabus',flex:1,renderCell:(params)=>
      {
        return <div>
            <p className='xxs-text m-weight f-op caps'>{params.row?.standard ? `class-${params.row?.standard}` : params.row?.studentCategory[0].name}</p>
            <p className='xxxs-text r-weight q-op caps'>{params.row?.standard ? `class-${params.row?.standard}` : params.row?.syllabus[0].name}</p>
        </div>
      }},
      // {field:'studentSyllabus',headerName:'Syllabus',flex:1,valueGetter: (params) => params.row?.syllabus[0].name},
      {field:'currentProcess',headerName:'Status',flex:1,renderCell:(params)=>
      {
        return <div><div><p className="xs-text r-weight">{params.row.isCancelled ? 'Cancelled' : params.row.isFinished ? 'Finished' : 'In Demo'}</p></div></div>
      }},
      // {
      //   field: 'actions',
      //   type: 'actions',
      //   width: 80,
      //   getActions: (params) => [
      //     <GridActionsCellItem
      //       icon={<EditIcon />}
      //       label="View"
      //       onClick={()=>
      //         {
      //           setEnquiryDetailModal(params.row?._id)
      //           // let item = selectedClient.enquiries?.filter((i,index)=>{
      //           //   console.log(i)
      //           //   return i._id === params.id
      //           // })
      //           // console.log('item',item)
      //       }}
      //     />
      //   ],
      // },
    ]

    const uploadFile = async(file,fieldType)=>
    {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", data._id);
      try 
      {
        let res = await authAxios({
          method: "post",
          url: `/upload/${fieldType}?id=${data._id}`,
          data: formData,
        })
        setUnfilteredTutors(prev=>
          {
            let prevData = [...prev]
            let index = prevData.findIndex(i=>i._id === data._id)
            prevData[index] = res.data
            return prevData
          })
        setTutors(prev=>
          {
            let prevData = [...prev]
            let index = prevData.findIndex(i=>i._id === data._id)
            prevData[index] = res.data
            return prevData
          })
      } catch (error) {
        console.log(error)
      }
    }

  return (
    !loading ? <div className='tutor-info-container'>
      <div className="toggle-container">
                <div className="toggle-content">
                    <div className={`toggle-item ${toggle === 'info' ? 'toggle-item-active' : ''}`} onClick={()=>setToggle('info')}><p>Informations</p></div>
                    <div className={`toggle-item ${toggle === 'docs' ? 'toggle-item-active' : ''}`} onClick={()=>setToggle('docs')}><p>Docs</p></div>
                    <div className={`toggle-item ${toggle === 'enquiries' ? 'toggle-item-active' : ''}`} onClick={()=>setToggle('enquiries')}><p>Tuition</p></div>
                    <div className={`toggle-item ${toggle === 'demos' ? 'toggle-item-active' : ''}`} onClick={()=>setToggle('demos')}><p>Demo</p></div>
                </div>
            </div>
        { toggle === 'info' ? <div className='tutor-info-content'>
        <div className='tutor-info-header'>
            <div>
                <p className='r-text m-weight f-op caps'>{data.firstName} {data.lastName}</p>
                <p className='xxs-text r-weight h-op'>{data.mobile}</p>
            </div>
            <div>
                <button className='tertiary-btn' onClick={()=>setShowEdit(data)}>Edit</button>
            </div>
        </div>
        <div className='tutor-info-grid'>
             <div className='tutor-info-content-grid'>
                
                <div>
                    <label>Class Mode</label>
                    <p className='xs-text f-op m-weight caps'>{data.classMode}</p>
                </div>
                <div>
                    <label>Mobile {data.primaryMobile === 'primary' ? <span className='primary-badge'>Primary</span> : null}</label>
                    <p className='xs-text f-op m-weight caps'>{data.mobile}</p>
                </div>
                <div>
                    <label>Alternative Mobile {data.primaryMobile === 'alternate' ? <span className='primary-badge'>Enqueries</span> : null}</label>
                    <p className='xs-text f-op m-weight caps'>{data.alternateMobile}</p>
                </div>
                <div>
                    <label>Email</label>
                    <p className='xs-text f-op m-weight caps'>{data.email}</p>
                </div>
                <div>
                    <label>City</label>
                    <p className='xs-text f-op m-weight'>{data.city.cityName}</p>
                </div>
                <div>
                    <label>Locality</label>
                    <p className='xs-text f-op m-weight'>{data.locality}</p>
                    <a className='xxs-text' target="_blank" href={`https://maps.google.com/?q=${data.geo.lat},${data.geo.lng}`}>View In Map</a>
                </div>
                <div>
                    <label>Address</label>
                    <p className='xs-text f-op m-weight'>{data.address}</p>
                </div>
                <div>
                    <label>Gender</label>
                    <p className='xs-text f-op m-weight caps'>{data.gender}</p>
                </div>
                <div>
                    <label>Note</label>
                    <p className='xs-text f-op m-weight caps'>{data.note}</p>
                </div>
                <div>
                    <label>Qualification</label>
                    <p className='xs-text f-op m-weight caps'>{data.qualification}</p>
                </div>
                <div>
                    <label>Profession</label>
                    <p className='xs-text f-op m-weight'>{data.profession?.name}</p>
                </div>
                <div>
                    <label>Experience</label>
                    <p className='xs-text f-op m-weight'>{data.experience}</p>
                </div>
                <div>
                    <label>Is Active</label>
                    <p className='xs-text f-op m-weight'>{data.isActive ? 'Active' : 'Inactive'}</p>
                </div>
                <div>
                    <label>Is Verified</label>
                    <p className='xs-text f-op m-weight'>{data.isVerified ? 'Verified' : 'Not Verified'}</p>
                </div>
                <div>
                    <label>Account Number</label>
                    <p className='xs-text f-op m-weight'>{data.accountNumber}</p>
                </div>
                <div>
                    <label>Account IFSC</label>
                    <p className='xs-text f-op m-weight'>{data.accountIfsc}</p>
                </div>
                <div>
                    <label>Account Name</label>
                    <p className='xs-text f-op m-weight'>{data.accountName}</p>
                </div>
                <div>
                        <p className="xxs-text h-op m-weight">Joined on</p>
                        <p className="xs-text f-op m-weight caps">{data.createdAt ? getDate(data.createdAt,false) : 'Not Available'}</p>
                    </div>
               
             </div>
             <div className='tutor-info-subject-container'>
                <div>
                <div className='tutor-info-right-container'>
                    {
                        data.expertise.map((item,index)=>
                        {
                        return <CategoryItem data={item}/>
                        })
                    }
            </div>
                </div>
             </div>
        </div>
        </div> : toggle === 'docs' ? 
        <div className='tutor-info-content'>
            <div className='tutor-doc-grid'>
              <div>
              <label>Profile Pic</label>
              <FileContainer type={'profilePic'} prevFile={data.profilePic} onChange={uploadFile}/>
            </div>
              <div>
              <label>Resume</label>
              <FileContainer type={'resume'} prevFile={data.resume} onChange={uploadFile}/>
            </div>
              <div>
              <label>Address Proof</label>
              <FileContainer type={'addressProof'} prevFile={data.addressProof} onChange={uploadFile}/>
            </div>
              <div>
              <label>Account Proof</label>
              <FileContainer type={'paymentProof'} prevFile={data.paymentProof} onChange={uploadFile}/>
            </div>
            </div>
        </div> 
        : toggle === 'enquiries' ? <div style={{height:'100%',width:'100%'}} className="tutor-info-content">
        <DataGrid sx={{'border':'0'}} getRowId={row=>row._id} disableColumnSelector={true} columns={columns} rows={enquiries ? enquiries : []} disableSelectionOnClick={true}/>
      </div> : 
      <div style={{height:'100%',width:'100%'}} className="tutor-info-content">
      <DataGrid sx={{'border':'0'}} getRowId={row=>row._id} disableColumnSelector={true} columns={demoColumns} rows={demos ? demos : []} disableSelectionOnClick={true}/>
    </div>
      }
    </div> : 'Loading'
  )
}



// const TutorEnquiry = ()=>
// {
//   const [enquiries,setEnquiries] = useState()
//   useEffect(() => {
//     async function getData(){
//       let res = await authAxios.get('/tutor/enquiries')
//       setEnquiries(res.data)
//     }
//     getData()
//   }, [])
  
//   return <div style={{height:'100%',width:'100%'}}>
//   <DataGrid sx={{'border':'0'}} getRowId={row=>row._id} disableColumnSelector={true} columns={columns} rows={enquiries ? enquiries : []} disableSelectionOnClick={true}/>
// </div>
// }