import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { toast } from "react-toastify";
import Modal from '../../components/Modal'
import { createEmployees, getCity, updateEmployees } from "../../components/services";
import { TOAST_CONFIG } from "../../config/constants";

function EmployeeModal({updateData,title,setModalVisible,setemployeeData}) {

  const [isValid,setIsValid] = useState(true)
  const [data, setData] = useState({
    firstName: updateData && updateData.firstName ? updateData.firstName : '' ,
    lastName: updateData && updateData.lastName ? updateData.lastName : '',
    email: updateData && updateData.email ? updateData.email : '',
    mobile: updateData && updateData.mobile ? updateData.mobile : '',
    active: updateData && updateData.active ? updateData.active : true,
    role: updateData && updateData.role ? updateData.role : 'manager',
    cities: updateData && updateData.cities ? updateData.cities : [],
  });
  const [citiesData, setcitiesData] = useState([]);
  let optionData = [];

  const [submitting,setSubmitting] = useState(false)


  useEffect(async () => {
    const newCityData = await getCity();
    console.log('updat data',newCityData)
    newCityData.map((item,index)=>{
        optionData.push({_id:item._id,cityName:item.cityName})
        return true;
      })
      console.log('option data',optionData)
    setcitiesData(optionData);
  }, []);

  useEffect(()=>
  {
    if(data.cities.length) setIsValid(true)
    else setIsValid(false)
  },[data.cities])
  

  const _onSubmit = async(e)=>
  {
      try 
      {
          let res;
          e.preventDefault()
          setSubmitting(true)
          if(updateData)
          {
            res = await updateEmployees({...data,_id:updateData._id})
            setemployeeData(prev=>{
              let newData = [...prev]
              let index = newData.findIndex(x=> x._id === updateData._id);
              newData[index] = {...res}
              console.log('new dta',newData[index])
              return newData
            })
            setModalVisible(false)
          }
          else
          {
            res = await createEmployees({...data,status:data.active})
            setemployeeData(prev=>{
              let newData = [...prev]
              newData.push(res)
              return newData
            })
            setModalVisible(false)
          }
        return true
      } catch (error) {
        setSubmitting(false)
        toast.error(error.response.data.message ? error.response.data.message  : error,TOAST_CONFIG)
          return false
      }
  }

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ?
        '#ddd' : isValid ?
        '#ddd' : '#ddd',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : isValid ?
          '#ddd' : '#ddd'
      }
    })
  }

  return (
    <Modal title={title}>
    <div>
      <div>
        <form onSubmit={_onSubmit}>
          <div className="modal-inner-content">
                <div className="input-group-divided">
                    <div>
                        <label>First Name</label>
                        <input name="firstName" className="text-input" required value={data.firstName} onChange={(e)=>setData(prev=>({...prev,firstName:e.target.value}))}/>
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input name="lastName"className="text-input" value={data.lastName} onChange={(e)=>setData(prev=>({...prev,lastName:e.target.value}))}/>
                    </div>
                </div>

                <div className="input-group-divided">
                    <div>
                        <label>Email</label>
                        <input name="email" className="text-input" required value={data.email} onChange={(e)=>setData(prev=>({...prev,email:e.target.value}))}/>
                    </div>
                    <div>
                        <label>Mobile</label>
                        <input name="text" maxLength={10} className="text-input" required value={data.mobile} onChange={(e)=>setData(prev=>({...prev,mobile:e.target.value}))}/>
                    </div>
                </div>

                <div className="input-group-divided">
                    <div>
                        <label>Status</label>
                        <select name="status" className="text-input" required value={data.active === true ? 'active' : 'inactive'}  onChange={(e)=>setData(prev=>({...prev,active:e.target.value === "active" ? true : false }))}>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                    <div>
                        <label>Role</label>
                        <select name="status" className="text-input" required value={data.role} onChange={(e)=>setData(prev=>({...prev,role:e.target.value}))}>
                        <option value="manager">Manager</option>
                        <option value="coordinator">Coordinator</option>
                        <option value="support">Support</option>
                        </select>
                    </div>
                </div>

                <div className="input-group">
                    <label>Cities Allotted</label>
                    <Select isMulti={true} options={citiesData} className="multi-select" menuPortalTarget={document.body} 
    styles={customStyles} value={data.cities} onChange={(selected)=>setData(prev=>({...prev,cities:selected}))}  getOptionLabel={item=>{
      if(!updateData) return item.cityName
      else return item.cityName
  }} getOptionValue={item=>{
      if(!updateData) return item._id
      else return item._id
  }}/>
                </div>
          </div>
          <div className="modal-footer">
              <div><input type="button" className="xs-neutral-btn" value="Cancel" onClick={()=>setModalVisible(false)}/></div>
              <div><input type="submit" disabled={!isValid || submitting} className="xs-delete-btn" value={updateData ? 'Update Employee' : 'Add Employee'}/></div>
          </div>
        </form>
      </div>
    </div>
    </Modal>
  );
}

export default EmployeeModal;
